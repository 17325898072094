import React, { useState } from 'react'
import { authenticatedFetch } from '../services/authentication'
import { flexRender } from '@tanstack/react-table'
import { style } from '../themes/styles'

import ConfirmDialog from './core/ConfirmDialog'
import LoadingSpinner from './core/LoadingSpinner'

const StyledInput = style.StyledComponent.input`
  border: 1px solid ${style.color.championBlue};
  width: 140px;
  display: inline;
  padding: 0;
  margin-bottom: 0;
  div {
    display: inline;
  }
  input {
    width: 120px;
    height: auto;
  }
`

const StyledRow = style.StyledComponent.tr`
  padding: 5px;
  cursor: pointer;

  transition: background-color 0.2s;
  &:hover{
    background-color: ${style.color.championLightGrey};
  }
`

const StyledLink = style.StyledComponent.span`
  color: ${style.color.championBlue};
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: #6BB7E2;
  }
`
const CarryTD = style.StyledComponent.td`
  padding: 5px;
`

const EditableCell = style.StyledComponent(CarryTD)`
  width: 250px;
`

const CarryTableRow = ({ row, isEditing, onEditingChange, propagateChanges, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const { carry } = row.original
  const [carriesValue, setCarriesValue] = useState(carry)

  const handleChange = e => {
    e.preventDefault()

    if (!isNaN(e.target.value) && Number(e.target.value) <= 100 && Number(e.target.value) >= 0) {
      setCarriesValue(e.target.value)
    }
  }

  const handleEditToggle = val => {
    onEditingChange(val)
  }

  const handleSave = () => {
    const data = row.original
    const updatedData = {
      ...data,
      carry: carriesValue
    }

    setIsLoading(true)

    return authenticatedFetch(`carries/${updatedData.id}`, {
      method: 'PUT',
      body: JSON.stringify(updatedData)
    })
      .then(resp => {
        propagateChanges(resp)
        onEditingChange(false)
      })
      .catch(err => {
        setError(err.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const initDelete = () => {
    setShowConfirmDelete(true)
  }
  const handleDelete = () => {
    const data = row.original

    setIsLoading(true)

    return authenticatedFetch(`carries/${data.id}`, {
      method: 'DELETE'
    })
      .then(resp => {
        onDelete(data.id)
      })
      .catch(err => {
        setError(err.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const ActionWrapper = style.StyledComponent.td`
    display: flex;
    justify-content: space-evenly;
  `

  const ActionCell = () => {
    if (isLoading) {
      return (
        <ActionWrapper>
          <LoadingSpinner />
        </ActionWrapper>
      )
    } else {
      if (isEditing) {
        return (
          <ActionWrapper>
            <StyledLink onClick={() => handleEditToggle(false)}>cancel</StyledLink>
            <StyledLink onClick={handleSave} data-row-index={row.id} className='save-button'>
              save
            </StyledLink>
          </ActionWrapper>
        )
      } else {
        return (
          <ActionWrapper>
            <StyledLink onClick={() => handleEditToggle(true)}>edit</StyledLink>
            <StyledLink onClick={initDelete}>delete</StyledLink>
          </ActionWrapper>
        )
      }
    }
  }

  return (
    <>
      <StyledRow>
        {row.getVisibleCells().map(cell => {
          if (cell.column.id === 'Actions') {
            return <ActionCell key={cell} />
          } else if (cell.column.id === 'carry') {
            return isEditing ? (
              <EditableCell>
                <StyledInput
                  minValue={0}
                  maxValue='100'
                  max={100}
                  type='number'
                  value={carriesValue}
                  onChange={handleChange}
                />
              </EditableCell>
            ) : (
              <EditableCell>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </EditableCell>
            )
          } else {
            return (
              <CarryTD key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </CarryTD>
            )
          }
        })}
      </StyledRow>

      <ConfirmDialog
        show={showConfirmDelete}
        close={() => setShowConfirmDelete(false)}
        message='Are you sure you want to remove this carry?'
        action={() => handleDelete()}
      />
    </>
  )
}

export default CarryTableRow
