import * as React from 'react'
import { cva } from 'class-variance-authority'
import { cn } from 'services/utils'

/**
 * A set of layered sections of content-known as tab panels-that are displayed one at a time.
 *
 * How to use: https://ui.shadcn.com/docs/components/tabs
 */
const textareaVariants = cva(
  'form-input flex min-h-1 px-4 p-3 text-md px-3 py-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 ring-offset-background placeholder:text-[#3A688D]',
  {
    variants: {
      variant: {
        default: '',
      },
      size: {
        default: 'h-12 rounded-lg',
        sm: 'h-10 rounded-md text-sm',
        lg: 'h-16 rounded-lg',
        xl: 'h-24 rounded-lg'
      }
    },
    defaultVariants: {
      size: 'default',
      variant: 'default'
    }
  }
)

type Variant = 'default'
type Size = 'default' | 'sm' | 'lg' | 'xl'

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  variant?: Variant
  size?: Size
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className = '', variant = 'default', size = 'default', ...props }, ref) => {
    return (
      <textarea
        className={cn(textareaVariants({ size, className }), className)}
        ref={ref}
        {...props}
      />
    )
  }
)
Textarea.displayName = 'Textarea'

export { Textarea, Variant, Size }
