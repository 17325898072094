import { type ComponentPropsWithoutRef } from 'react'
import { Button } from 'components/ui/Button'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/Popover'
import { Checkbox } from 'components/ui/Checkbox'
import { SearchInput } from 'components/ui/SearchInput'
import { useGetOpportunitiesByOpportunityTypes } from 'hooks/api/useQuery.hooks'
import { Opportunity } from 'services/DataStore'
import { cn } from 'services/utils'
import { FilterCountBadge } from './FilterCountBadge'

const STATUS_OPTIONS = [
  { label: 'Live', value: 'active' },
  { label: 'Draft', value: 'inactive' },
  { label: 'Archived', value: 'archived' }
]

interface Props extends ComponentPropsWithoutRef<'div'> {
  search: string
  status: string[]
  vintages: number[]
  setSearch: (search: string) => void
  setStatus: (status: string[]) => void
  setVintages: (status: number[]) => void
  resetTable: () => void
}

export function Filters(props: Props) {
  const { className, search, status, vintages, setSearch, setStatus, setVintages, resetTable } =
    props
  const { data } = useGetOpportunitiesByOpportunityTypes(Opportunity.TYPE_VINTAGE)

  const statusCount = status.length
  const vintagesCount = vintages.length

  return (
    <div className={cn('flex ml-auto space-x-4', className ?? '')}>
      <SearchInput
        value={search}
        className='h-10 border-[#DEDEE3]'
        onChange={e => {
          setSearch(e.target.value)
          resetTable()
        }}
      />

      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            size='sm'
            className='px-4 rounded-md border-[#DEDEE3] relative'
          >
            {vintagesCount > 0 && <FilterCountBadge>{vintagesCount}</FilterCountBadge>}
            Filter by fund
          </Button>
        </PopoverTrigger>
        <PopoverContent className='bg-white z-[60] w-[--radix-popover-trigger-width] md:w-full max-h-[--radix-popover-content-available-height]'>
          <ul className='space-y-2'>
            {data?.map(option => {
              return (
                <li key={`option-${option.id}`} className='flex items-center space-x-2'>
                  <Checkbox
                    id={option.name}
                    checked={vintages.includes(option.id)}
                    onCheckedChange={checked => {
                      if (checked) setVintages([...vintages, option.id])
                      else setVintages(vintages.filter(s => s !== option.id))
                      resetTable()
                    }}
                  />
                  <label htmlFor={option.name}>{option.name}</label>
                </li>
              )
            })}
          </ul>
        </PopoverContent>
      </Popover>

      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            size='sm'
            className='px-4 rounded-md border-[#DEDEE3] relative'
          >
            <FilterCountBadge>{statusCount}</FilterCountBadge>
            Filter by status
          </Button>
        </PopoverTrigger>
        <PopoverContent className='bg-white z-[60] w-[--radix-popover-trigger-width] md:w-full max-h-[--radix-popover-content-available-height]'>
          <ul className='space-y-2'>
            {STATUS_OPTIONS.map(option => {
              return (
                <li key={`option-${option.value}`} className='flex items-center space-x-2'>
                  <Checkbox
                    id={option.label}
                    checked={status.includes(option.value)}
                    onCheckedChange={checked => {
                      if (checked) setStatus([...status, option.value])
                      else setStatus(status.filter(s => s !== option.value))
                      resetTable()
                    }}
                  />
                  <label htmlFor={option.label}>{option.label}</label>
                </li>
              )
            })}
          </ul>
        </PopoverContent>
      </Popover>
    </div>
  )
}
