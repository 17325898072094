import React, { useState, useEffect, FC } from 'react'
import { Label } from '../ui/Label'
import LoadingSpinner from 'components/core/LoadingSpinner'
import {
  Select as UiSelect,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectContent,
  Variant,
  Size
} from 'components/ui/Select'

interface Option {
  value: string | number
  label: string
}

interface SelectProps {
  onChange: (option: Option) => void
  label?: string
  options?: Option[]
  className?: string
  value?: Option | { label?: string }
  small?: boolean
  statusIndicator?: string | null
  disabled?: boolean
  tooltip?: string | null
  placeholder?: string
  variant?: Variant
  size?: Size
  icon?: React.ReactNode
  loading?: boolean
}

const Select: FC<SelectProps> = ({
  onChange,
  label = '',
  options = [],
  className = '',
  value = {},
  small = false,
  statusIndicator = null,
  disabled = false,
  tooltip = null,
  placeholder = 'Select',
  variant = 'default',
  size = 'lg',
  icon,
  loading = false,
  ...other
}) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null)

  useEffect(() => {
    const op = options.find(option =>
      value ? option.value?.toString() === (value as Option).value?.toString() : false
    )
    if (op) setSelectedOption(op)
  }, [value, options])

  return (
    <div className='mb-2'>
      {label && <Label className='form-label'>{label}</Label>}
      <UiSelect
        defaultValue={value?.label || placeholder}
        onValueChange={newValue => {
          const op = options.find(option => option.value?.toString() === newValue)

          if (op) {
            setSelectedOption(op)
            onChange(op)
          }
        }}
        disabled={loading || disabled}
        {...other}
      >
        <SelectTrigger
          className={className}
          variant={variant}
          size={size}
          icon={loading ? <LoadingSpinner className='mt-1 opacity-50' /> : icon}
        >
          <div className={`flex items-center ${!tooltip && 'w-full'}`}>
            {statusIndicator && (
              <span
                className={`h-3 w-3 rounded-full mr-2`}
                style={{ background: statusIndicator }}
              />
            )}
            <SelectValue placeholder={placeholder}>
              {value?.label || selectedOption?.label || placeholder || 'Select'}
            </SelectValue>
          </div>
          {tooltip && <span className='ml-auto text-gray-400'>{tooltip}</span>}
        </SelectTrigger>
        <SelectContent className='bg-white' style={{ zIndex: 99999 }}>
          {options.length === 0 ? (
            <SelectItem value=' ' disabled>
              No options
            </SelectItem>
          ) : (
            options.map((option, i) => (
              <SelectItem key={`option-${i}$`} value={option?.value?.toString()}>
                {option.label}
              </SelectItem>
            ))
          )}
        </SelectContent>
      </UiSelect>
    </div>
  )
}

export default Select
