import { createContext, ReactNode, useContext } from 'react'
import { UserInfo } from 'types/api-types'

interface ContextState {
  state: {
    user: UserInfo | undefined
  }
  handlers: {
    onCloseManageDetailsDialog: () => void
    onUpdateMember: (user: UserInfo) => Promise<void>
  }
}

interface ProviderProps extends ContextState {
  children: ReactNode
}

const ManageDetailsDialogContext = createContext<ContextState | null>(null)

/**
 * This provider is to pass down a function to close the parent modal
 */
export function ManageDetailsDialogProvider({ state, handlers, children }: ProviderProps) {
  return (
    <ManageDetailsDialogContext.Provider value={{ state, handlers }}>
      {children}
    </ManageDetailsDialogContext.Provider>
  )
}

export function useManageDetailsDialog() {
  const context = useContext(ManageDetailsDialogContext)
  if (!context)
    throw new Error('useManageDetailsDialog must be used within ManageDetailsDialogProvider')

  return context
}
