import { useMemo, useState } from 'react'
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  TableOptions,
  useReactTable
} from '@tanstack/react-table'
import { Skeleton } from 'components/ui/Skeleton'

interface PaginationProps extends PaginationState {}
export function useTablePagination(props?: PaginationProps) {
  const { pageIndex = 0, pageSize = 50 } = props || {}

  const [pagination, setPagination] = useState<PaginationState>({ pageIndex, pageSize })

  return {
    pagination,
    onPaginationChange: setPagination
  }
}

interface SortProps extends SortingState {}
export function useTableSort(sortOption?: SortProps) {
  const [sorting, setSorting] = useState<SortingState>(sortOption ?? [])

  const orderBy = sorting.length ? sorting[0].id : undefined
  const order = sorting.length ? (sorting[0].desc ? 'desc' : 'asc') : undefined

  return {
    sorting,
    onSortingChange: setSorting,
    orderBy,
    order
  }
}

export function useTableFilter() {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  return {
    columnFilters,
    onColumnFiltersChange: setColumnFilters
  }
}

interface TableProps<TData>
  extends Omit<TableOptions<TData>, 'getCoreRowModel' | 'getSortedRowModel'> {
  isLoading?: boolean
}
export function useTable<TData>(options: TableProps<TData>) {
  const tableData = useMemo(() => {
    if (options.isLoading) return Array(options.state?.pagination?.pageSize ?? 20).fill({})
    return options.data ?? []
  }, [options.isLoading, options.data, options.state?.pagination?.pageSize])

  const tableColumns = useMemo(() => {
    if (options.isLoading)
      return options.columns.map(column => ({
        ...column,
        cell: () => <Skeleton className='h-6' />
      }))
    return options.columns
  }, [options.isLoading, options.columns])

  const table = useReactTable<TData>({
    ...options,
    data: tableData,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  return table
}
