import { type ButtonHTMLAttributes, forwardRef } from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'services/utils'
import { LoaderCircle } from 'lucide-react'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap text-base font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 montserrat',
  {
    variants: {
      variant: {
        default: 'bg-[#10273A] text-white hover:opacity-90',
        destructive: 'bg-red-500 text-white hover:bg-destructive/90',
        outline: 'border border-[#10273A] bg-white hover:opacity-90',
        link: 'textbg-[#10273A] underline-offset-4 hover:underline'
      },
      size: {
        default: 'h-12 px-4 py-4 rounded-lg',
        sm: 'h-10 px-2 rounded-md text-sm',
        lg: 'h-16 px-6 rounded-xl',
        icon: 'h-10 w-10'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean
}

/**
 * Display a loading button with loading icon.
 * This component extends the shadcn button with loading state.
 *
 * How to use: https://ui.shadcn.com/docs/components/button
 */
const LoadingButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading = false,
      disabled = false,
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        disabled={disabled || loading}
        ref={ref}
        {...props}
      >
        <>
          {loading ? <LoaderCircle className='mr-4 animate-spin' /> : null}
          {children}
        </>
      </Comp>
    )
  }
)
LoadingButton.displayName = 'LoadingButton'

export { LoadingButton, buttonVariants }
