import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import z from 'zod'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import { Form, FormControl, FormField, FormItem, FormLabel } from 'components/ui/Form'
import type { OpportunityResponse } from 'types/api-types'
import { Button } from 'components/ui/Button'
import { LoadingButton } from 'components/ui/LoadingButton'
import { useUpdateKeyPointsMutate } from 'hooks/api/useMutation.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'
import { useToast } from 'hooks/userToast.hooks'
import { Textarea } from 'components/ui/Textarea'
import type { OpportunityKeyPoint } from '../KeyPoints'

interface Props {
  open: boolean
  toggleOpen: (open: boolean) => void
  opportunity: OpportunityResponse
  keyPoint?: OpportunityKeyPoint
}

const keyPointSchema = z.object({
  text_html: z.string().min(1, { message: 'New key points is required.' })
})
type KeyPointSchema = z.infer<typeof keyPointSchema>

export function KeyPointDialog({ opportunity, keyPoint, open, toggleOpen }: Props) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const form = useForm<KeyPointSchema>({
    resolver: zodResolver(keyPointSchema),
    defaultValues: {
      text_html: keyPoint?.text_html ?? ''
    }
  })

  const { mutateAsync, isLoading } = useUpdateKeyPointsMutate()

  const getKeyPoints = (values: KeyPointSchema) => {
    // if editing an existing keypoint
    if (keyPoint) {
      const updatedKeyPoints = opportunity.key_points.map(kp => {
        if (kp.id === keyPoint.id) return { ...keyPoint, ...values }
        return kp
      })
      return updatedKeyPoints
    }

    // if adding a new keypoint
    const order = opportunity.key_points.length
    const addedKeyPoints = [...opportunity.key_points, { ...values, order }]
    return addedKeyPoints
  }

  const handleSubmit: SubmitHandler<KeyPointSchema> = async values => {
    const keyPoints = getKeyPoints(values)

    await mutateAsync(
      { id: opportunity.id, keyPoints },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(`${opportunity.id}`)
          })
          toast({
            variant: 'success',
            description: 'Key points updated'
          })
          toggleOpen(false)
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent
        className='max-w-[1035px] max-h-[85%] overflow-y-auto'
        onInteractOutside={e => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle className='text-2xl'>{keyPoint ? 'Edit' : 'New'} Key Point</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <FormField
              control={form.control}
              name='text_html'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Key Point</FormLabel>
                  <FormControl>
                    <Textarea {...field} placeholder='Key Point...' size='lg' />
                  </FormControl>
                </FormItem>
              )}
            />

            <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
              <Button
                type='button'
                size='sm'
                disabled={isLoading}
                className='flex-1 max-w-[262px]'
                onClick={() => toggleOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                loading={isLoading}
                size='sm'
                className='flex-1 max-w-[262px] bg-success'
              >
                Save
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
