import history from './constants/History'
import { Router, Switch } from 'react-router-dom'
import routes from './routes/Routes'
import AppRoute from './routes/AppRoute'
import { GlobalStyle } from 'themes'
import ReactGA from 'react-ga4'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Toaster } from 'contexts/Toaster.provider'

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  })
  ReactGA.initialize('G-ZCXWRSD1MY')

  return (
    <QueryClientProvider client={queryClient}>
      <div className='flex items-center justify-center overflow-hidden w-screen h-screen bg-white'>
        <GlobalStyle />
        <Router history={history}>
          <Switch>
            {routes.map(route => (
              <AppRoute
                exact
                key={route.path}
                path={route.path}
                component={route.component}
                isProtected={route.isProtected}
                history={history}
                platform={route.platform}
              />
            ))}
          </Switch>

          <Toaster />
        </Router>
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
