import { PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { DndContext, DragEndEvent, UniqueIdentifier } from '@dnd-kit/core'
import { useSortable, arrayMove, SortableContext } from '@dnd-kit/sortable'
import { Calendar, FileText, GripVertical, Image, Pencil, SquarePlay, Trash2 } from 'lucide-react'
import { Button } from 'components/ui/Button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/Card'
import { dataTypes } from 'services/utils'
import type { OpportunityResponse } from 'types/api-types'
import { Form, FormControl, FormField, FormItem, FormLabel } from 'components/ui/Form'
import { Checkbox } from 'components/ui/Checkbox'
import { useUpdateDueDiligenceMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { useGetOpportunity } from 'hooks/api/useQuery.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'
import { DealLoader } from './layouts/Loader'
import { CompanyUpdatesDialog } from './dialogs/CompanyUpdatesDialog'
import { DeleteCompanyUpdatesDialog } from './dialogs/DeleteCompanyUpdatesDialog'
import { FollowOnDeals } from './FollowOnDeals'

interface Props {
  id: string
}
interface InnerProps {
  opportunity: OpportunityResponse
}

type LinkItem = OpportunityResponse['links'][number]

export function CompanyUpdates({ id }: Props) {
  const { data: opportunity } = useGetOpportunity(id)

  if (!opportunity) return <DealLoader.Card />

  return <CompanyUpdatesChild opportunity={opportunity} />
}

function CompanyUpdatesChild({ opportunity }: InnerProps) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const items = useMemo(() => {
    return opportunity.links
      .sort((a, b) => a.order - b.order)
      .filter(link => link.group === dataTypes.company_updates)
  }, [opportunity])

  const [openEditLinkDialog, setOpenEditLinkDialog] = useState<boolean>(false)
  const [openDeleteLinkDialog, setOpenDeleteLinkDialog] = useState<boolean>(false)
  const [selectedLink, setSelectedLink] = useState<LinkItem | null>(null)

  const { mutate } = useUpdateDueDiligenceMutate()

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (over && active.id !== over.id) {
      const activeIdx = opportunity.links.findIndex(({ id }) => id === active.id)
      const overIdx = opportunity.links.findIndex(({ id }) => id === over.id)

      // Don't rearrange based on the filtered `items`. opportunity.links has other grouped itmems with orders.
      // The links data needs to be rearranged based on non-fiiltered list.
      const reArranged = arrayMove(opportunity.links, activeIdx, overIdx).map((result, idx) => ({
        ...result,
        order: idx
      }))

      updateQueryState(reArranged)

      mutate(
        { id: opportunity.id, links: reArranged },
        {
          onError: error => {
            toast({
              variant: 'destructive',
              description: error.message ?? 'Something went wrong',
              duration: 2000
            })
            updateQueryState(items) // revert back to previous state
          }
        }
      )
    }
  }

  const updateQueryState = (data: OpportunityResponse['links']) => {
    queryClient.setQueryData<OpportunityResponse>(
      opportunityKeyFactory.opportunityByDeal(`${opportunity.id}`),
      oldData => {
        if (!oldData) return undefined
        return {
          ...oldData,
          links: data
        }
      }
    )
  }

  const isDisabled = opportunity.is_follow_on

  if (isDisabled) {
    return <FollowOnDeals title='Company Updates' />
  }

  return (
    <>
      <Card className='h-full'>
        <CardHeader className='flex items-center flex-row'>
          <CardTitle>Company Updates</CardTitle>
          <Button
            size='sm'
            variant='ghost'
            className='ml-auto text-success'
            onClick={() => setOpenEditLinkDialog(true)}
          >
            Add New
          </Button>
        </CardHeader>

        <CardContent>
          {!items.length ? (
            <p className='text-base text-slate-700 text-center'>
              This deal has no company updates at this time
            </p>
          ) : (
            <DndContext onDragEnd={onDragEnd}>
              <SortableContext items={items}>
                <ul className='space-y-4'>
                  {items.map(link => {
                    return (
                      <li key={link.id}>
                        <DiligenceItem
                          id={link.id}
                          opportunity={opportunity}
                          selectedLink={link}
                          onEdit={() => {
                            setOpenEditLinkDialog(true)
                            setSelectedLink(link)
                          }}
                          onDelete={() => {
                            setOpenDeleteLinkDialog(true)
                            setSelectedLink(link)
                          }}
                        >
                          {link.label_html}
                        </DiligenceItem>
                      </li>
                    )
                  })}
                </ul>
              </SortableContext>
            </DndContext>
          )}
        </CardContent>
      </Card>

      {openEditLinkDialog && (
        <CompanyUpdatesDialog
          selectedLink={selectedLink}
          opportunity={opportunity}
          open={openEditLinkDialog}
          onClose={() => {
            setOpenEditLinkDialog(false)
            setSelectedLink(null)
          }}
        />
      )}
      {openDeleteLinkDialog && (
        <DeleteCompanyUpdatesDialog
          selectedLink={selectedLink}
          opportunity={opportunity}
          open={openDeleteLinkDialog}
          onClose={() => {
            setOpenDeleteLinkDialog(false)
            setSelectedLink(null)
          }}
        />
      )}
    </>
  )
}

interface DiligenceItemProps extends PropsWithChildren {
  opportunity: OpportunityResponse
  selectedLink: LinkItem
  id: UniqueIdentifier
  onEdit: () => void
  onDelete: () => void
}

const diligenceItemSchema = z.object({
  visible_on_mvp: z.boolean().nullable(),
  visible_on_champion: z.boolean().nullable()
})
type DiligenceItemSchema = z.infer<typeof diligenceItemSchema>

function DiligenceItem({
  opportunity,
  selectedLink,
  id,
  onEdit,
  onDelete,
  children
}: DiligenceItemProps) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({ id })

  const { mutate } = useUpdateDueDiligenceMutate()

  const form = useForm<DiligenceItemSchema>({
    resolver: zodResolver(diligenceItemSchema),
    defaultValues: {
      visible_on_mvp: selectedLink.visible_on_mvp,
      visible_on_champion: selectedLink.visible_on_champion
    }
  })

  // watch for form changes and make API request.
  const { watch } = form
  useEffect(() => {
    const subscription = watch(value => {
      const { visible_on_champion, visible_on_mvp } = value

      const updatedLinks = opportunity.links.map(link => {
        if (link.id === selectedLink.id)
          return {
            ...link,
            visible_on_champion: visible_on_champion ?? null,
            visible_on_mvp: visible_on_mvp ?? null
          }
        return link
      })

      queryClient.setQueryData<OpportunityResponse>(
        opportunityKeyFactory.opportunityByDeal(`${opportunity.id}`),
        oldData => {
          if (!oldData) return undefined
          return {
            ...oldData,
            links: updatedLinks
          }
        }
      )

      mutate(
        { id: opportunity.id, links: updatedLinks },
        {
          onError: (error, data, context) => {
            toast({
              variant: 'destructive',
              description: error.message ?? 'Something went wrong',
              duration: 2000
            })
            queryClient.setQueryData(
              opportunityKeyFactory.opportunityByDeal(`${opportunity.id}`),
              context
            )
          }
        }
      )
    })
    return () => subscription.unsubscribe()
  }, [watch, mutate, opportunity])

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition
      }
    : undefined

  return (
    <div
      ref={setNodeRef}
      className='flex sm:items-center flex-col sm:flex-row hover:bg-slate-50/50 rounded-md'
      style={style}
    >
      <div className='flex items-center'>
        <Button ref={setActivatorNodeRef} {...listeners} {...attributes} variant='ghost' size='sm'>
          <GripVertical className='w-5 h-5 text-gray-400' />
        </Button>
        {DUE_DILIGENCE_ICONS[selectedLink.resource_type]}
        <p className='m-0 text-sm sm:text-base ml-4'>{children}</p>
      </div>

      <div className='flex items-center ml-auto pl-4 space-x-4 sm:space-x-8'>
        <Form {...form}>
          <form>
            <div className='flex items-center space-x-4 sm:space-x-10'>
              <FormField
                control={form.control}
                name='visible_on_mvp'
                render={({ field }) => {
                  return (
                    <FormItem className='flex items-center flex-col'>
                      <FormLabel>MVP</FormLabel>
                      <FormControl>
                        <Checkbox
                          checked={field.value ?? undefined}
                          className='w-4 sm:w-5 h-4 sm:h-5'
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='visible_on_champion'
                render={({ field }) => {
                  return (
                    <FormItem className='flex items-center flex-col'>
                      <FormLabel>Champion</FormLabel>
                      <FormControl>
                        <Checkbox
                          checked={field.value ?? undefined}
                          className='w-4 sm:w-5 h-4 sm:h-5'
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )
                }}
              />
            </div>
          </form>
        </Form>

        <div className='flex items-center sm:space-x-1'>
          <Button variant='ghost' size='sm'>
            <Pencil type='button' color='#4E9535' className='w-4 h-4' onClick={onEdit} />
          </Button>
          <Button type='button' size='sm' variant='ghost' onClick={onDelete}>
            <Trash2 className='w-4 h-4 text-archived' />
          </Button>
        </div>
      </div>
    </div>
  )
}

export const DUE_DILIGENCE_ICONS: Record<string, ReactNode> = {
  text: <FileText className='w-5 h-5 text-success' />,
  image: <Image className='w-5 h-5 text-success' />,
  calendar: <Calendar className='w-5 h-5 text-success' />,
  video: <SquarePlay className='w-5 h-5 text-success' />
}
