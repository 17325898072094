import { useState } from 'react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from 'components/ui/Select'
import { Skeleton } from 'components/ui/Skeleton'
import { useEditMemberStatusFromOrganizationMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { cn } from 'services/utils'
import { useManageDetailsDialog } from 'contexts/ManageDetailsDialog.context'

const userStatusOptions = [
  { value: 'pending_approval', label: 'Pending Approval' },
  { value: 'approved', label: 'Approved' },
  { value: 'not_approved', label: 'Blocked' },
  { value: 'disabled', label: 'Archived' }
]
const statusColors: Record<string, string> = {
  pending_approval: 'bg-warn border-warn',
  approved: 'bg-success border-success',
  not_approved: 'bg-[#B8421C] border-[#B8421C]',
  disabled: 'bg-[#B8421C] border-[#B8421C]'
}

export function EditStatus() {
  const { toast } = useToast()

  const { state } = useManageDetailsDialog()

  const [userStatus, setUserStatus] = useState<string>('')

  const { mutate, isLoading: isEditLoading } = useEditMemberStatusFromOrganizationMutate()

  const updateStatus = (val: string) => {
    if (!state.user) return
    mutate(
      { member: state.user, status: val },
      {
        onSuccess: () => {
          setUserStatus(val)

          toast({
            variant: 'success',
            description: 'Member status updated',
            duration: 2000
          })
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <div className='mt-4'>
      <Select onValueChange={updateStatus} defaultValue={userStatus || state.user?.status}>
        {isEditLoading ? (
          <Skeleton className='w-[200px] h-10 bg-gray-300' />
        ) : (
          <SelectTrigger
            className={cn(
              'max-w-[200px] text-white [&>svg]:text-white',
              statusColors[userStatus || (state.user?.status ?? 'pending_approval')]
            )}
          >
            <SelectValue placeholder='status' />
          </SelectTrigger>
        )}
        <SelectContent>
          <SelectGroup>
            {userStatusOptions.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  )
}
