import { useState } from 'react'
import { Pencil } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import z from 'zod'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { Input } from 'components/ui/Input'
import { LoadingButton } from 'components/ui/LoadingButton'
import { Button } from 'components/ui/Button'
import { useUpdateOrganizationNameMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { UserInfo } from 'types/api-types'

interface Props {
  currentUser: UserInfo
}

const formSchema = z.object({
  name: z.string().min(1, { message: 'Must be a valid name' })
})
type FormSchema = z.infer<typeof formSchema>

const disableEditField = (user: UserInfo): boolean => {
  const isVenturePartner = user.organization.name === 'Modern Venture Partners'
  return isVenturePartner
}

export function EditOrganizationName({ currentUser }: Props) {
  const { mutate, isLoading, error } = useUpdateOrganizationNameMutate()

  const { toast } = useToast()

  const [isEditing, setIsEditing] = useState<boolean>(false)

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: currentUser?.organization.name ?? ''
    }
  })

  const onSubmitHandler: SubmitHandler<FormSchema> = formValue => {
    const { name } = formValue
    if (!currentUser || disableEditField(currentUser)) return

    const data = { id: currentUser.organization.id, userId: currentUser.id, name }
    mutate(data, {
      onSuccess() {
        setIsEditing(false)
        toast({
          variant: 'success',
          description: 'Organization name has been updated'
        })
      }
    })
  }

  const onToggleEditing = () => {
    form.reset()
    setIsEditing(!isEditing)
  }

  return (
    <div className='mt-4 md:mt-6'>
      {isEditing ? (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmitHandler)} className='mt-2'>
            <FormField
              control={form.control}
              name='name'
              render={({ field }) => {
                return (
                  <FormItem className='w-full'>
                    <FormLabel>Organization Name</FormLabel>
                    <div className='flex items-center space-x-2 w-full'>
                      <FormControl>
                        <Input {...field} disabled={isLoading} />
                      </FormControl>
                      <LoadingButton type='submit' loading={isLoading}>
                        Save
                      </LoadingButton>
                      <Button type='button' variant='outline' onClick={onToggleEditing}>
                        Cancel
                      </Button>
                    </div>
                    <FormMessage />
                  </FormItem>
                )
              }}
            />
          </form>
        </Form>
      ) : (
        <p className='flex items-center space-x-1 m-0 text-lg md:text-xl font-medium montserrat'>
          <span>{form.getValues('name')}</span>

          {disableEditField(currentUser) ? null : (
            <Button type='button' variant='ghost' onClick={onToggleEditing}>
              <Pencil className='w-5 h-5' color='#4E9535' />
            </Button>
          )}
        </p>
      )}

      {error && <p className='m-0 text-warn'>{error.message ?? 'Something went wrong'}</p>}
    </div>
  )
}
