import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { ArrowRight } from 'lucide-react'
import { Step, useRegistration } from 'contexts/Register.context'
import { useRegisterUserQuery } from 'hooks/api/useQuery.hooks'
import LoadingSpinner from 'components/core/LoadingSpinner'
import { useLoginMutate } from 'hooks/api/useMutation.hooks'
import { handleLoginResponse } from 'services/authentication'
import { ButtonContainer, PrimaryButton, SecondaryButton } from '../registerShares/Button'

export function ScheduleCalendar({ history }: { history: History }) {
  const { registrationInfo, onSetStep } = useRegistration()
  const { isLoading, isFetching, error, data } = useRegisterUserQuery(registrationInfo)
  const { mutate, isLoading: isLoginLoading, error: loginError } = useLoginMutate()

  useCalendlyEventListener({ onEventScheduled: () => onSetStep(Step.REGISTRATION_COMPLETE) })

  const onClickHandler = () => {
    onSetStep(Step.REGISTRATION_COMPLETE)
  }

  useEffect(() => {
    if (data && data.approved) {
      const { password } = registrationInfo

      mutate(
        { email: data.username, password },
        { onSuccess: data => handleLoginResponse(data, history) }
      )
    }
  }, [data, mutate])

  if (isLoading || isFetching || isLoginLoading)
    return (
      <div>
        <h2 className='mb-8'>Creating your account</h2>
        <LoadingSpinner className='flex justify-center' />
      </div>
    )

  if (error || loginError) {
    const errorMessage = error?.message || loginError?.message
    const redirectEmail = registrationInfo.email ? `&email=${registrationInfo.email}` : ''

    return (
      <div>
        <p className='text-base sm:text-xl mb-0'>{errorMessage ?? ''}</p>

        <ButtonContainer className='sm:mt-10'>
          <SecondaryButton asChild>
            <Link to='/'>Cancel</Link>
          </SecondaryButton>
          <PrimaryButton asChild>
            <Link to={`/?login=true${redirectEmail}`}>Login</Link>
          </PrimaryButton>
        </ButtonContainer>
      </div>
    )
  }

  return (
    <div className='flex flex-col text-center w-full'>
      <h1 className='text-xl md:text-3xl mt-6 md:mt-0 max-w-[800px] monstserrat mx-auto'>
        Thanks for registering! Please schedule a time below to meet with the MVP Team.
      </h1>

      <InlineWidget
        styles={{
          height: '685px'
        }}
        prefill={{
          email: registrationInfo.email,
          firstName: registrationInfo.person_fname,
          lastName: registrationInfo.person_lname,
          name: `${registrationInfo.person_fname} ${registrationInfo.person_lname}`
        }}
        url={
          registrationInfo.platform === 'mvp'
            ? 'https://calendly.com/mvp-investor-relations/introduction-to-mvp-ventures'
            : 'https://calendly.com/mvp-investor-relations/champion'
        }
      />

      <ButtonContainer className='sm:mt-10'>
        <SecondaryButton onClick={onClickHandler}>
          I’ll do this later <ArrowRight className='ml-2 w-5 h-5' />
        </SecondaryButton>
      </ButtonContainer>
    </div>
  )
}
