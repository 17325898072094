import { Ellipsis } from 'lucide-react'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/Popover'

import { useManageDetailsDialog } from 'contexts/ManageDetailsDialog.context'
import { RemoveAdminMember } from './RemoveAsAdmin'
import { ResendApprovalEmail } from './ResendApprovalEmail'
import { EditAccount } from './EditAccount'
import { ResetClientPasswordDialog } from './ResetClientPassword'

interface Props {
  isClientDetail?: boolean
}

export function EditUserDetailsPopover({ isClientDetail }: Props) {
  const { state, handlers } = useManageDetailsDialog()

  const isApproved = state.user?.status === 'approved'

  return (
    <Popover>
      <PopoverTrigger>
        <Ellipsis color='#4A4A4A' className='w-8 h-8' />
      </PopoverTrigger>
      <PopoverContent
        side='bottom'
        align='end'
        className='w-fit border border-[#f2f2f2] rounded-sm shadow-sm p-4'
      >
        <ul className='flex flex-col space-y-3'>
          <li className='text-[#4A4A4A] montserrat'>
            <EditAccount user={state.user} updateMember={handlers.onUpdateMember} />
          </li>
          <span className='bg-[#EDEDED] h-[1px] w-full' />
          <li className='text-[#4A4A4A] montserrat'>
            <ResetClientPasswordDialog user={state.user} />
          </li>
          {!isClientDetail && (
            <>
              <span className='bg-[#EDEDED] h-[1px] w-full' />
              <li className='text-[#4A4A4A] montserrat'>
                <RemoveAdminMember />
              </li>
            </>
          )}
          {isApproved && (
            <>
              <span className='bg-[#EDEDED] h-[1px] w-full' />
              <li className='text-[#4A4A4A] montserrat'>
                <ResendApprovalEmail />
              </li>
            </>
          )}
        </ul>
      </PopoverContent>
    </Popover>
  )
}
