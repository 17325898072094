import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Area, AreaChart, XAxis, YAxis } from 'recharts'
import { Button } from 'components/ui/Button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/Card'
import {
  type ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent
} from 'components/ui/Chart'
import { dateFormat } from 'services/TimeUtils'
import { useGetCommitmentsByOpportunityId, useGetOpportunity } from 'hooks/api/useQuery.hooks'
import type { CommitmentsResponse, OpportunityResponse } from 'types/api-types'
import { DealLoader } from './layouts/Loader'

interface Props {
  id: string
}
interface InnerProps {
  opportunity: OpportunityResponse
  commitments: CommitmentsResponse[] | undefined
}

const chartConfig: ChartConfig = {
  amount: {
    label: 'Amount: ',
    color: '#07CE63'
  }
}

export function Commitments({ id }: Props) {
  const { data: opportunity } = useGetOpportunity(id)
  const { data: commitments } = useGetCommitmentsByOpportunityId(opportunity?.id)

  if (!opportunity || !commitments) return <DealLoader.Card />

  return <CommitmentsChild opportunity={opportunity} commitments={commitments} />
}

function CommitmentsChild({ opportunity, commitments }: InnerProps) {
  const { chartData, totalAmount } = useMemo(() => {
    if (!commitments?.length) return { chartData: [], totalAmount: 0 }

    const totalAmount = commitments.reduce(
      (acc, record) =>
        (acc += Number.parseFloat(record.final_amount_total || record.amount_requested)),
      0
    )

    const groupedData = commitments.reduce<{ data: Record<string, number>; dates: string[] }>(
      (acc, record) => {
        const formattedDate = dateFormat(record.created_at)
        const amount = Number.parseFloat(record.final_amount_total || record.amount_requested)

        acc.data[formattedDate] = (acc.data[formattedDate] || 0) + amount
        if (!acc.dates.includes(formattedDate)) {
          acc.dates.push(formattedDate)
        }

        return acc
      },
      { data: {}, dates: [] }
    )

    let cumulativeSum = 0
    const chartData = groupedData.dates
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
      .map(date => {
        cumulativeSum += groupedData.data[date]
        return {
          createdAt: date,
          amount: cumulativeSum
        }
      })

    return { chartData, totalAmount }
  }, [commitments])

  const formattedTotalAmount = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short'
  }).format(totalAmount)

  return (
    <Card className='h-full'>
      <CardHeader className='flex items-center flex-row'>
        <CardTitle>Commitments: {formattedTotalAmount}</CardTitle>
        <Button size='sm' variant='success' asChild className='ml-auto'>
          <Link to={`/admin/deals/${opportunity?.id}/commitments`}>View all</Link>
        </Button>
      </CardHeader>

      <CardContent>
        {!chartData.length ? (
          <p className='text-base text-slate-700 m-0'>No commitments</p>
        ) : (
          <ChartContainer config={chartConfig} className='w-full max-h-[300px]'>
            <AreaChart
              accessibilityLayer
              data={chartData}
              margin={{
                left: 16,
                right: 16,
                top: 16
              }}
            >
              <XAxis
                dataKey='createdAt'
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={tick => {
                  const date = new Date(tick)
                  return dateFormat(date)
                }}
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickCount={6}
                tickFormatter={tick => {
                  return new Intl.NumberFormat('en-US', {
                    notation: 'compact',
                    compactDisplay: 'short'
                  }).format(tick)
                }}
              />
              <ChartTooltip cursor={false} content={<ChartTooltipContent indicator='dot' />} />
              <Area
                dataKey='amount'
                type='linear'
                fill='#6DB557'
                fillOpacity={0.4}
                stroke='#07CE63'
                strokeWidth={2}
                stackId='a'
              />
            </AreaChart>
          </ChartContainer>
        )}
      </CardContent>
    </Card>
  )
}
