import { type FormEventHandler, useState } from 'react'
import { useQualificationSurveryQuery } from 'hooks/api/useQuery.hooks'
import { Label } from 'components/ui/Label'
import { RadioGroup, RadioGroupItem } from 'components/ui/RadioGroup'
import LoadingSpinner from 'components/core/LoadingSpinner'
import { useRegistration, Step } from 'contexts/Register.context'
import { ButtonContainer, PrimaryButton, SecondaryButton } from '../registerShares/Button'

export function QualificationStatus() {
  const { data, isLoading, isFetching } = useQualificationSurveryQuery()
  const { registrationInfo, onUpdateRegistrationInfo, onSetStep } = useRegistration()
  const [selectedQualification, setSelectedQualification] = useState<string>(
    registrationInfo?.qualified_purchaser_status_id ?? ''
  )
  const [error, setError] = useState<string>('')

  const onBackHandler = () => {
    onUpdateRegistrationInfo({ platform: '', qualified_purchaser_status_id: '' })
    onSetStep(Step.QUALIFICATION_PURCHASER_QUESTION)
  }

  const onSubmitHandler: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()

    if (!selectedQualification) return setError('Please choose a valid option')

    onUpdateRegistrationInfo({
      platform: 'mvp',
      qualified_purchaser_status_id: selectedQualification
    })
    onSetStep(Step.CONNECT_TO_LINKEDIN)
  }

  const onSelectHandler = (value: string) => {
    setSelectedQualification(value)
    setError('')
  }

  if (isLoading || isFetching) return <LoadingSpinner className='flex justify-center' />

  return (
    <div className='max-w-[800px] monstserrat'>
      <h1 className='text-xl md:text-3xl mt-6 md:mt-0'>{data ? data.name : ''}</h1>

      <form onSubmit={onSubmitHandler}>
        <RadioGroup
          className='space-y-3 mt-10 md:space-y-6 md:mt-16'
          onValueChange={onSelectHandler}
        >
          {data &&
            data.survey_answers.map(option => {
              return (
                <li
                  key={`${option.text_html}-${option.id}`}
                  className='flex items-start space-x-4 cursor-pointer'
                >
                  <RadioGroupItem
                    checked={option.id + '' === selectedQualification}
                    value={option.id + ''}
                    id={option.text_html}
                    className='h-6 mt-1 text-[#52B749]'
                  />
                  <Label className='text-base md:text-xl cursor-pointer' htmlFor={option.text_html}>
                    {option.text_html}
                  </Label>
                </li>
              )
            })}
        </RadioGroup>

        {error && <p className='m-0 text-center text-[#CAA535]'>{error}</p>}

        <ButtonContainer>
          <SecondaryButton type='button' onClick={onBackHandler}>
            Back
          </SecondaryButton>
          <PrimaryButton type='submit'>Next</PrimaryButton>
        </ButtonContainer>
      </form>
    </div>
  )
}
