import { type ComponentPropsWithoutRef } from 'react'
import { cn } from 'services/utils'

export function FilterCountBadge({ children, className }: ComponentPropsWithoutRef<'span'>) {
  return (
    <span
      className={cn(
        'flex justify-center items-center w-6 h-6 absolute -top-3 -right-2 text-white bg-[#2C5476] text-sm rounded-full',
        className ?? ''
      )}
    >
      {children}
    </span>
  )
}
