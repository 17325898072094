import { useState, type ComponentPropsWithoutRef } from 'react'
import { SlidersHorizontal } from 'lucide-react'
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from 'components/ui/Sheet'
import { Button } from 'components/ui/Button'
import { useMediaQuery } from 'hooks/useMediaQuery.hooks'
import { FilterCountBadge } from './FilterCountBadge'

interface Props extends ComponentPropsWithoutRef<'div'> {
  filterCount?: number
}

export function DealsMobileFilter({ children, className, filterCount }: Props) {
  const isDesktop = useMediaQuery('(min-width: 900px)')
  const [open, setOpen] = useState<boolean>(false)

  const onToggleSheet = (status: boolean) => {
    setOpen(status)
  }

  if (isDesktop) return null

  return (
    <div className={className}>
      <Sheet open={open} onOpenChange={onToggleSheet}>
        <SheetTrigger asChild>
          <Button type='button' variant='ghost' aria-expanded={open} className='relative'>
            {filterCount && (
              <FilterCountBadge className='w-4 h-4 top-0 right-0 text-xs'>
                {filterCount}
              </FilterCountBadge>
            )}

            <SlidersHorizontal className='w-5 h-5' />
          </Button>
        </SheetTrigger>

        <SheetContent side='bottom' className='bg-white py-8'>
          <SheetHeader className='mb-4'>
            <SheetTitle className='text-center'>Filter deals</SheetTitle>
          </SheetHeader>
          {children}
        </SheetContent>
      </Sheet>
    </div>
  )
}
