import { ChangeEventHandler, FormEventHandler, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLogoutMutate, useSignNdaAgreementMutate } from 'hooks/api/useMutation.hooks'
import { LoginLogoColored } from 'components/core/logos/login-logo-colored'
import { Checkbox } from 'components/ui/Checkbox'
import { Label } from 'components/ui/Label'
import { useUserQuery } from 'hooks/api/useQuery.hooks'
import { isAdmin, setUser as setLocalStorageUser } from 'services/authentication'
import { Input } from 'components/ui/Input'
import { DataStore } from 'services/DataStore'
import nda from 'assets/mvp-nda.pdf'
import { ButtonContainer, PrimaryButton, SecondaryButton } from '../registerShares/Button'
import { useQueryClient } from '@tanstack/react-query'
import { userKeyFactory } from 'helpers/api/factories/userKey'

export function NdaNeeded({ history }: any) {
  const anchorRef = useRef<HTMLAnchorElement | null>(null)

  const routerHistory = useHistory()

  const { mutate: logoutMutate } = useLogoutMutate()
  const { mutate: signNdaMutate } = useSignNdaAgreementMutate()

  const [hasDownloaded, setHasDownloaded] = useState<boolean>(false)
  const [hasNdaAgreed, setHasNdaAgreed] = useState<boolean>(false)
  const [isGetUserInfo, setIsGetUserInfo] = useState<boolean>(false)
  const [needToKnowName, setNeedToKnowName] = useState<boolean>(false)
  const [user, setUser] = useState<{ firstName: string; lastName: string }>({
    firstName: '',
    lastName: ''
  })
  const [error, setError] = useState<string>('')
  const queryClient = useQueryClient()

  const { data: userInfo } = useUserQuery(isGetUserInfo)

  const handleDownload = () => {
    if (anchorRef && anchorRef.current) anchorRef.current.click()
    setHasDownloaded(true)
  }

  const saveUserInfo: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()

    if (Object.values(user).every(field => !field))
      return setError('Please fill out the fields with your full name.')

    const userProps = {
      ...userInfo,
      person: { fname: user.firstName, lname: user.lastName },
      name: `${user.firstName} ${user.lastName}`,
      is_admin: isAdmin()
    }
    DataStore.update('users', userInfo?.id, userProps)
      .then(() => {
        setLocalStorageUser(JSON.stringify(userProps))
        // TODO: this should be handled outside this component, copied from EditAccountInfo.tsx
        ;(async () => {
          await queryClient.invalidateQueries({
            queryKey: userKeyFactory.user(),
            refetchType: 'all'
          })
        })();

        handleNavigateUser()
      })
      .catch(err => {
        setError(err.message || 'A problem was encountered while updating your name.')
      })
  }

  const handleLogout = () => {
    logoutMutate()
  }

  const ndaCheckboxHandler = (value: boolean) => {
    setHasNdaAgreed(value)
  }

  const agreementHandler = () => {
    signNdaMutate(undefined, {
      onSuccess: () => {
        setIsGetUserInfo(true)
      }
    })
  }

  const nameChangeHandler: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value })
  }

  const handleNavigateUser = () => {
    if (isAdmin()) routerHistory.push('/admin/clients')
    else routerHistory.push('/opportunities')
  }

  useEffect(() => {
    if (!userInfo) return

    if (userInfo.created_by_invite) setNeedToKnowName(true)
    else handleNavigateUser()
  }, [userInfo])

  return (
    <div className='w-full flex flex-col items-center justify-center my-auto max-w-[600px] montserrat overflow-y-auto px-4'>
      <LoginLogoColored />

      {!needToKnowName && (
        <>
          <h2 className='text-xl sm:text-3xl m-0 mt-10'>NDA Required</h2>
          {!hasDownloaded && (
            <p className='text-base sm:text-xl m-0 mt-4 sm:mt-8'>
              Please download and read our NDA.
            </p>
          )}

          {!hasDownloaded && (
            <ButtonContainer className='w-full flex justify-center items-center flex-wrap'>
              <PrimaryButton onClick={handleDownload}>Download NDA</PrimaryButton>
              <SecondaryButton onClick={handleLogout}>Log Out</SecondaryButton>
            </ButtonContainer>
          )}

          {hasDownloaded && (
            <div className='mt-6 flex flex-col items-center'>
              <div className='flex items-center'>
                <Checkbox
                  id='ndaCheckbox'
                  checked={hasNdaAgreed}
                  className='w-5 h-5'
                  onCheckedChange={ndaCheckboxHandler}
                />
                <Label htmlFor='ndaCheckbox' className='ml-2 text-base'>
                  I have read and I agree to the contents of the MVP NDA
                </Label>
              </div>

              {hasNdaAgreed && (
                <PrimaryButton className='mt-4' onClick={agreementHandler}>
                  I agree to the NDA
                </PrimaryButton>
              )}
            </div>
          )}

          <a ref={anchorRef} href={nda} download className='hidden' />
        </>
      )}

      {needToKnowName && (
        <div className='mt-10'>
          <h2 className='text-center'>What is your name?</h2>
          <p>Please enter your full name below.</p>

          <form onSubmit={saveUserInfo} className='space-y-4'>
            <Input
              name='firstName'
              placeholder='First name'
              inputSize='lg'
              value={user.firstName}
              onChange={nameChangeHandler}
            />
            <Input
              name='lastName'
              placeholder='Last name'
              inputSize='lg'
              value={user.lastName}
              onChange={nameChangeHandler}
            />

            {error && <p className='m-0 mt-8 text-center text-[#CAA535]'>{error}</p>}

            <ButtonContainer className='w-full flex justify-center items-center'>
              <PrimaryButton type='submit'>Save</PrimaryButton>
            </ButtonContainer>
          </form>
        </div>
      )}
    </div>
  )
}
