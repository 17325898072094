import React, { useState } from 'react'
import DateTimePicker from 'react-datetime-picker'
import { Label } from '../ui/Label'

interface DateTimePickerProps {
  onChange: (value: Date | null) => void;
  label?: string;
  type?: string;
  className?: string;
  value?: Date;
  disabled?: boolean;
}

const DateTimePickerComponent: React.FC<DateTimePickerProps> = ({
  onChange,
  label,
  type,
  className,
  value,
  disabled
}) => {
  const [showCal, setShowCal] = useState(false)

  return (
    <div className={className} onClick={() => setShowCal(true)}>
      {label && <Label className="form-label">{label}</Label>}
      <DateTimePicker
        onChange={onChange}
        value={value}
        disableClock
        clearIcon={null}
        disabled={disabled}
        type={type}
        isCalendarOpen={showCal}
        onCalendarClose={() => setShowCal(false)}
        className="form-input mvp-datetime-picker h-12 px-4"
      />
    </div>
  )
}

export default DateTimePickerComponent;
