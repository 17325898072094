import { useEffect, useState } from 'react'
import { Plus } from 'lucide-react'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import { useGetEntitesByUserQuery } from 'hooks/api/useQuery.hooks'
import { Button } from 'components/ui/Button'
import { Skeleton } from 'components/ui/Skeleton'
import { UserInfo } from 'types/api-types'
import { ManageEntityItem } from './components/ManageEntityItem'

interface Props {
  user: UserInfo | undefined
  open: boolean
  toggleDialog: (open: boolean) => void
}

export function ManageEntitiesDialog({ user, open = false, toggleDialog }: Props) {
  const { data, isLoading, isFetching } = useGetEntitesByUserQuery(user?.id)

  const [entityList, setEntityList] = useState<{ name: string; isNew: boolean }[]>([])

  useEffect(() => {
    if (!data) return
    setEntityList(data.map(entity => ({ name: entity.name, isNew: false })))
  }, [data])

  const onAddEntity = () => {
    setEntityList([...entityList, { name: '', isNew: true }])
  }

  return (
    <Dialog open={open} onOpenChange={toggleDialog}>
      <DialogContent className='rounded-md max-w-3xl'>
        <DialogHeader>
          <DialogTitle className='text-2xl montserrat'>Manage Entities</DialogTitle>
        </DialogHeader>

        {isLoading || isFetching || !data ? (
          <div className='flex flex-col space-y-2'>
            <Skeleton className='w-full h-10' />
            <Skeleton className='w-full h-10' />
            <Skeleton className='w-full h-10' />
            <Skeleton className='w-full h-10' />
          </div>
        ) : (
          <div className='flex flex-col space-y-4'>
            <ul className='flex flex-col space-y-2 max-h-[400px] overflow-y-auto'>
              {entityList.map((entity, idx) => {
                return (
                  <ManageEntityItem
                    user={user}
                    key={`entity-${entity.name}-${idx}`}
                    entity={entity}
                    entityData={data[idx]}
                    cancelAddingEntity={() => setEntityList(entityList.filter((_, i) => i !== idx))}
                  />
                )
              })}
            </ul>

            <Button type='button' size='sm' onClick={onAddEntity}>
              <Plus className='w-4 h-4 mr-2' /> Add Entity
            </Button>
          </div>
        )}

        <DialogFooter className='mt-4'>
          <Button variant='outline' onClick={() => toggleDialog(false)}>
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
