import {
  DealByOpportunityTypeParams,
  DealsSearchQueryParams,
  InvestorsSearchQueryParams
} from 'types/api-types'

export const userKeyFactory = {
  all: ['user-key'] as const,
  user: () => [...userKeyFactory.all, 'user'] as const,
  notification: () => [...userKeyFactory.all, 'notification'] as const,
  member: (memberId?: number) => [...userKeyFactory.all, { memberId }] as const,
  searchMember: (id: string, searchTerm: string) =>
    [...userKeyFactory.all, { searchMember: id }, { searchTerm }] as const,
  entities: (userId: number | undefined) =>
    [...userKeyFactory.all, 'entities', { userId }] as const,
  commitments: (userId: number | undefined) =>
    [...userKeyFactory.all, 'commitment', { userId }] as const
}

export const registrationKeyFactory = {
  all: ['registration'] as const,
  qualificationSurvey: () => [...registrationKeyFactory.all, 'qualificationSurvey'] as const,
  accreditedSurvey: () => [...registrationKeyFactory.all, 'accreditedSurvey'] as const,
  registerUser: () => [...registrationKeyFactory.all, 'registerUser'] as const
}

export const fundKeyFactory = {
  all: ['funds'] as const,
  funds: () => [...fundKeyFactory.all, 'funds'] as const
}

export const opportunityKeyFactory = {
  all: ['opportunity'] as const,
  opportunityByType: (type: string) => [...opportunityKeyFactory.all, { type }] as const,
  opportunityByDeal: (deal: string) => [...opportunityKeyFactory.all, { deal }] as const,
  opportunityDealType: (id: string) => [...opportunityKeyFactory.all, 'dealType', { id }] as const
}

export const dealKeyFactory = {
  all: ['deal'] as const,
  deals: (query: DealsSearchQueryParams) => [...dealKeyFactory.all, { query }] as const,
  opportunityType: (params: DealByOpportunityTypeParams) =>
    [...dealKeyFactory.all, { params }] as const
}

export const commitmentKeyFactory = {
  all: ['commitment'] as const,
  commitmentsByOpportunityId: (opportunityId: number | undefined) =>
    [...commitmentKeyFactory.all, { opportunityId }] as const
}

export const staticKeyFactory = {
  all: ['static'] as const,
  properties: () => [...staticKeyFactory.all, 'properties'],
  investors: (limit?: number) => [...staticKeyFactory.all, 'investors', { limit }]
}

export const investorKeyFactory = {
  all: ['investor'] as const,
  investors: (query: InvestorsSearchQueryParams) => [...investorKeyFactory.all, { query }] as const
}
