import { useState } from 'react'
import { Ellipsis } from 'lucide-react'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/Popover'
import { useManageDetailsDialog } from 'contexts/ManageDetailsDialog.context'
import { ManageOrganizationDialog } from 'components/modals/manage-organization/ManageOrganizationDialog'
import { ManageEntitiesDialog } from 'components/modals/manageEntities/ManageEntitiesDialog'

export function EditOrganizationPopover() {
  const { state } = useManageDetailsDialog()

  const [open, setOpen] = useState<boolean>(false)
  const [openManageEntities, setOpenManageEntities] = useState<boolean>(false)

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <Ellipsis color='#4A4A4A' className='w-8 h-8' />
        </PopoverTrigger>
        <PopoverContent
          side='bottom'
          align='end'
          className='w-fit border border-[#f2f2f2] rounded-sm shadow-sm p-4'
        >
          <ul className='flex flex-col space-y-3'>
            <li className='text-[#4A4A4A] montserrat'>
              <button type='button' onClick={() => setOpen(true)}>
                Manage Organization
              </button>
            </li>
            <span className='bg-[#EDEDED] h-[1px] w-full' />
            <li className='text-[#4A4A4A] montserrat'>
              <button type='button' onClick={() => setOpenManageEntities(true)}>
                Manage Entities
              </button>
            </li>
          </ul>
        </PopoverContent>
      </Popover>

      {state.user && (
        <ManageOrganizationDialog open={open} onToggle={setOpen} currentUser={state.user} />
      )}
      <ManageEntitiesDialog
        user={state.user}
        open={openManageEntities}
        toggleDialog={setOpenManageEntities}
      />
    </>
  )
}
