import React, { useState, useRef, ChangeEvent, useEffect } from 'react'
import { Button } from 'components/ui/Button'
import { Input } from 'components/ui/Input'

const MAX_LOGO_SIZE = 2 * 1024 * 1024 // 2MB

interface LogoAndColorPickerProps {
  currentLogoUrl: string
  currentColor: string
  alt: string
  onColorChange: (color: string) => void
  onLogoChange: (file: File) => void
  onError: (message: string) => void
  showColorInput?: boolean
  className?: string
}

export default function LogoAndColorPicker({
  currentLogoUrl,
  currentColor,
  alt,
  onColorChange,
  onLogoChange,
  onError,
  showColorInput = false,
  className = ''
}: LogoAndColorPickerProps) {
  const logoInput = useRef<HTMLInputElement>(null)
  const colorInput = useRef<HTMLInputElement>(null)
  const [showColorField, setShowColorField] = useState<boolean>(showColorInput)

  const handleLogoClick = () => {
    logoInput.current?.click()
  }

  const handleLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]

    if (!file) return

    if (file.size > MAX_LOGO_SIZE) {
      onError(`File size should be less than ${MAX_LOGO_SIZE / (1024 * 1024)}MB`)
      return
    }

    if (!(file instanceof Blob)) {
      onError('Invalid file')
      return
    }

    const reader = new FileReader()
    reader.onloadend = () => {
      onLogoChange(file)
    }

    reader.onerror = () => {
      onError('Could not read file')
    }

    reader.readAsDataURL(file)
  }

  useEffect(() => {
    if (showColorField) {
      colorInput.current?.focus()
    }
  })

  return (
    <div className={`LogoAndColorPreviewAndPicker mt-6 ${className}`}>
      <div
        className='form-input LogoAndColorPreview h-22 relative overflow-hidden items-center justify-center cursor-pointer'
        style={{ backgroundColor: currentColor }}
        onClick={() => (!currentColor ? setShowColorField(true) : handleLogoClick())}
      >
        {currentLogoUrl ? (
          <img className='max-w-full max-h-full m-auto' src={currentLogoUrl} alt={alt} />
        ) : (
          <span className='form-label flex items-center justify-center h-full w-full text-xs select-none'>
            Set logo {!currentColor ? 'or color' : ''}
          </span>
        )}
      </div>
      <div className='LogoAndColorPicker flex justify-between gap-2 relative mb-2'>
        {showColorField ? (
          <Input
            ref={colorInput}
            className='h-8 py-1 w-1/2'
            placeholder='e.g. #CCC111'
            title='e.g. #CCC111'
            value={currentColor}
            onChange={e => onColorChange(e.target.value)}
            onBlur={() => setShowColorField(false)}
          />
        ) : (
          <Button type='button' onClick={() => setShowColorField(true)} className='h-8 py-1 w-1/2'>
            {currentColor ? 'Change Color' : 'Pick Color'}
          </Button>
        )}
        <input
          type='file'
          className='hidden'
          accept='.jpg,.jpeg,.png,.svg'
          onChange={handleLogoChange}
          ref={logoInput}
        />
        <Button
          variant='success'
          type='button'
          className='w-1/2 h-8 py-1'
          onClick={handleLogoClick}
        >
          {currentLogoUrl ? 'Change Logo' : 'Upload Logo'}
        </Button>
      </div>
    </div>
  )
}
