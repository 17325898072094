import React, { memo, useEffect, useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import { Button } from 'components/ui/Button'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/Popover'
import { Checkbox } from 'components/ui/Checkbox'

interface StageFilterProps {
  globalFilter: { stages: any[] }
  setGlobalFilter: (globalFilter: (f: { stages: any[] }) => { stages: any[] }) => void
  stageRollups: string[]
}

const StageFilter: React.FC<StageFilterProps> = ({
  globalFilter,
  setGlobalFilter,
  stageRollups
}) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant='companiesOutline' role='combobox' size='sm'>
          Stage <FeatherIcon icon='chevron-down' size='16' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='bg-white z-[60] w-[--radix-popover-trigger-width] md:w-full max-h-[--radix-popover-content-available-height]'>
        <ul className='space-y-2'>
          {stageRollups &&
            stageRollups.map(stage => {
              return (
                <li key={`option-${stage}`} className='flex items-center space-x-2'>
                  <Checkbox
                    id={stage}
                    checked={!globalFilter.stages.includes(stage)}
                    onCheckedChange={checked => {
                      setGlobalFilter((f: { stages: any[] }) => {
                        return {
                          ...f,
                          stages: checked ? f.stages.filter(s => s !== stage) : [...f.stages, stage]
                        }
                      })
                    }}
                  />
                  <label className='cursor-pointer' htmlFor={stage}>
                    {stage}
                  </label>
                </li>
              )
            })}
        </ul>
      </PopoverContent>
    </Popover>
  )
}

export default memo(StageFilter)
