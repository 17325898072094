import { type FormEventHandler, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { handleLoginResponse, isLoggedIn } from 'services/authentication'
import useQuery from 'services/useQuery'
import { useLoginMutate } from 'hooks/api/useMutation.hooks'
import { LoginLogo } from 'components/core/logos/login-logo'
import { LoginLogoColored } from 'components/core/logos/login-logo-colored'
import { Input } from 'components/ui/Input'
import { PasswordInput } from 'components/ui/PasswordInput'
import { Button } from 'components/ui/Button'

// TODO: fix any type
export default function Login({ history }: { history: History }) {
  const query = useQuery()
  const forwardTo = query.get('forward_to') ?? '/opportunities'
  const emailQuery = query.get('email')

  const loggedIn = isLoggedIn()

  const { mutate, isLoading } = useLoginMutate()

  const browserHistory = useHistory()
  const [email, setEmail] = useState(emailQuery ?? '')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const loginHandler = async () => {
    mutate(
      { email, password },
      {
        onSuccess: data => {
          handleLoginResponse(data, history, forwardTo)
        },
        onError: err => {
          setError(err.message)
        }
      }
    )
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()
    if (!email || !password) return setError('Please fill in all fields!')
    await loginHandler()
  }

  useEffect(() => {
    if (loggedIn) {
      browserHistory.push('/opportunities')
    }
  }, [loggedIn])

  return (
    <div className='flex flex-row w-full h-full'>
      <section className='hidden md:flex flex-1 flex-col justify-center items-center bg-[#15324A] space-y-12 px-4'>
        <div>
          <LoginLogo />
        </div>
      </section>

      <section className='flex-1 flex flex-col justify-center items-center px-4'>
        <LoginLogoColored width={200} className='mb-4 md:hidden' />
        <h2 className='font-semibold text-xl sm:text-[25px] tracking-wider montserrat text-center m-0'>
          Log in to discover opportunities
        </h2>

        <form onSubmit={onSubmit} className='w-full max-w-[500px] !space-y-3 !montserrat mt-12'>
          <Input
            type='email'
            value={email}
            placeholder='Email'
            inputSize='lg'
            disabled={isLoading}
            onChange={e => setEmail(e.target.value)}
          />
          <PasswordInput
            value={password}
            placeholder='********'
            inputSize='lg'
            disabled={isLoading}
            onChange={e => setPassword(e.target.value)}
          />
          <Button type='submit' size='lg' disabled={isLoading} className='w-full'>
            Login
          </Button>

          {error && <p className='m-0 text-center text-[#CAA535]'>{error}</p>}
        </form>

        <div className='flex flex-col mt-8 space-y-4 montserrat tracking-wider text-sm'>
          <Link to='/register' className='hover:underline'>
            Create new account
          </Link>
          <Link to='/forgot-password' className='hover:underline'>
            Reset your password
          </Link>
        </div>
      </section>
    </div>
  )
}
