import * as React from 'react'
import { Search } from 'lucide-react'
import { cn } from 'services/utils'

export interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

/**
 * Displays a search input field or a component that contains a search magnifying glass icon.
 *
 * How to use: https://ui.shadcn.com/docs/components/input
 */
const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  ({ className, type, ...rest }, ref) => {
    return (
      <div
        className={cn(
          'flex items-center h-10 w-full rounded-md border border-input bg-background ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
          className ?? ''
        )}
      >
        <span className='py-2 px-3 pr-0'>
          <Search color='#0F172A' className='w-4 h-4' />
        </span>
        <input
          type={type}
          className='w-full px-3 py-2 mr-1 text-sm placeholder:text-muted-foreground focus-visible:outline-none ed:cursor-not-allowed disabled:opacity-50'
          ref={ref}
          {...rest}
        />
      </div>
    )
  }
)

SearchInput.displayName = 'SearchInput'

export { SearchInput }
