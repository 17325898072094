import { useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import z from 'zod'
import { Form, FormControl, FormField, FormItem, FormMessage } from 'components/ui/Form'
import { Input } from 'components/ui/Input'
import { LoadingButton } from 'components/ui/LoadingButton'
import { useCreateInvestorMutate } from 'hooks/api/useMutation.hooks'
import { Dialog, DialogContent, DialogFooter, DialogHeader } from 'components/ui/Dialog'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { Button } from 'components/ui/Button'
import { useToast } from 'hooks/userToast.hooks'
import { Investor } from 'types/api-types'
import { useHistory } from 'react-router-dom'

interface Props {
  investor?: Investor | null
}

const formSchema = z.object({
  id: z.number().optional(),
  name: z.string({ message: 'Must be present' })
})
type FormSchema = z.infer<typeof formSchema>

export function InvestorsForm({ investor = null }: Props) {
  const { toast } = useToast()

  const { mutate, isLoading } = useCreateInvestorMutate()

  const [open, setOpen] = useState<boolean>(false)
  const history = useHistory()

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: ''
    }
  })

  const onSubmitHandler: SubmitHandler<FormSchema> = formValue => {
    const { name } = formValue
    const data = {
      id: investor?.id,
      name
    }

    mutate(data, {
      onSuccess: (newInvestor: any) => {
        form.reset()
        toggleDialogOpen(false)
        toast({
          variant: 'success',
          description: `${name} created successfully`,
          duration: 2000
        })
        history.push(`investors/${newInvestor.id}`)
      },
      onError: error => {
        toast({
          variant: 'destructive',
          description: error.message ?? 'Something went wrong',
          duration: 2000
        })
      }
    })
  }

  const toggleDialogOpen = (status: boolean) => {
    form.reset()
    setOpen(status)
  }

  return (
    <Dialog open={open} onOpenChange={toggleDialogOpen}>
      <DialogTrigger asChild>
        <Button variant="success" size="sm">
          New Investor
        </Button>
      </DialogTrigger>

      <DialogContent className='max-w-3xl rounded-md'>
        <DialogHeader>
          <h2 className='m-0 text-xl font-medium'>
            Add investor
          </h2>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmitHandler)} className='mt-4'>
            <FormField
              control={form.control}
              name='name'
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormControl>
                      <Input placeholder='Name' disabled={isLoading} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            />

            <DialogFooter className='flex items-center sm:justify-start flex-row mt-6'>
              <Button
                type='button'
                className='flex-1 max-w-[262px]'
                onClick={() => toggleDialogOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                className='ml-2 flex-1 max-w-[262px] bg-[#52B749]'
                loading={isLoading}
              >
                Save
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
