import { useState } from 'react'
import { Trash2 } from 'lucide-react'
import { ManageDetailsDialog } from 'components/modals/manageDetails/ManageDetailsDialog'
import type { Member, UserInfo } from 'types/api-types'
import { RemoveMember } from './RemoveMember'
import { cn } from 'services/utils'

interface Props {
  currentUser: UserInfo
}

const statusColors: Record<string, string> = {
  pending_approval: 'bg-warn border-warn',
  approved: 'bg-success border-success',
  not_approved: 'bg-[#B8421C] border-[#B8421C]',
  disabled: 'bg-[#B8421C] border-[#B8421C]'
}

export function MemberList({ currentUser }: Props) {
  const [memberToDelete, setMemberToDelete] = useState<Member | null>(null)
  const [memberToEditStatus, setMemberToEditStatus] = useState<Member | null>(null)

  const isOrgAdmin = currentUser.is_org_admin

  return (
    <div className='mt-4'>
      <ul className='mt-4 max-h-[350px] overflow-y-auto p-2 bg-slate-50 rounded-md space-y-2'>
        {currentUser?.organization.members
          .sort((a, b) => b.user_id - a.user_id)
          .map(member => {
            const isSelf = member.user_id === currentUser.id
            const isMemberSiteAdmin = member.is_site_admin
            const isMemberRoleAdmin = member.role === 'admin'

            return (
              <li
                key={`member-${member.user_id}`}
                className='flex sm:flex-row md:items-center p-4 rounded-md bg-white'
              >
                <div className='flex flex-col flex-1'>
                  <button
                    type='button'
                    className='flex items-center space-x-3 font-medium text-sm sm:text-base break-all'
                    onClick={() => {
                      if (isSelf || isOrgAdmin) return
                      setMemberToEditStatus(member)
                    }}
                  >
                    <div className='flex-1 flex flex-col items-start'>
                      <div className='flex items-center space-x-2'>
                        <span
                          className={cn(
                            'inline-flex w-3 h-3 rounded-full',
                            statusColors[member.status]
                          )}
                        />
                        <span className='font-semibold'>{member.name}</span>
                      </div>
                      <span className='font-normal text-start'>{member.username}</span>
                      {!member.is_site_admin && (
                        <span className='text-sm text-gray-500'>
                          {member.role === 'admin' ? 'Admin' : 'Member'}
                        </span>
                      )}
                    </div>
                  </button>
                </div>

                <div className='flex items-center space-x-4 ml-auto mt-1 sm:mt-0'>
                  {!isMemberSiteAdmin && !isMemberRoleAdmin && !isSelf && (
                    <button type='button' onClick={() => setMemberToDelete(member)}>
                      <Trash2 className='w-5 h-5 text-red-400' />
                    </button>
                  )}
                </div>
              </li>
            )
          })}
      </ul>

      <ManageDetailsDialog
        member={memberToEditStatus}
        setMemberToEditStatus={setMemberToEditStatus}
      />

      <RemoveMember
        currentUser={currentUser}
        member={memberToDelete}
        setMemberToDelete={setMemberToDelete}
      />
    </div>
  )
}
