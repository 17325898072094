import { cn } from 'services/utils'

/**
 * Use to show a placeholder while content is loading.
 *
 * How to use: https://ui.shadcn.com/docs/components/skeleton
 */
function Skeleton({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={cn('animate-pulse rounded-md bg-gray-100', className ?? '')} {...props} />
}

export { Skeleton }
