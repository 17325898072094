import { useState } from 'react'
import { Button } from 'components/ui/Button'
import { Checkbox } from 'components/ui/Checkbox'
import { Label } from 'components/ui/Label'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/Card'
import { dollarFormat } from 'constants/DollarsFormat'
import { cn, convertDealType } from 'services/utils'
import type { OpportunityResponse } from 'types/api-types'
import { useGetOpportunity } from 'hooks/api/useQuery.hooks'
import { EditCommitmentRequirementsDialog } from './dialogs/EditCommitmentRequirementsDialog'
import { DealLoader } from './layouts/Loader'
import { useEditCommitmentRequirementMutate } from 'hooks/api/useMutation.hooks'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'
import { Skeleton } from 'components/ui/Skeleton'

interface Props {
  id: string
}
interface InnerProps {
  opportunity: OpportunityResponse
}

export function CommitmentRequirements({ id }: Props) {
  const { data: opportunity } = useGetOpportunity(id)

  const [openEditCommitmentReq, setOpenEditCommitmentReq] = useState<boolean>(false)

  if (!opportunity) return <DealLoader.Card />

  const opportunityType = convertDealType(opportunity?.opportunity_type)
  return (
    <>
      <Card className='h-full'>
        <CardHeader className='flex flex-row items-center p-5 space-y-0'>
          <CardTitle>Commitment Requirements</CardTitle>
          <Button
            type='button'
            variant='ghost'
            size='sm'
            className='ml-auto text-success'
            onClick={() => setOpenEditCommitmentReq(true)}
          >
            Edit
          </Button>
        </CardHeader>

        <CardContent>
          {opportunityType === 'MVP Fund' && <MVPContent opportunity={opportunity} />}
          {opportunityType === 'Champion Fund' && <ChampionContent opportunity={opportunity} />}
          {opportunityType === 'Co-Invest' && <CoInvestContent opportunity={opportunity} />}
        </CardContent>
      </Card>

      {openEditCommitmentReq && (
        <EditCommitmentRequirementsDialog
          opportunity={opportunity}
          open={openEditCommitmentReq}
          toggleOpen={setOpenEditCommitmentReq}
        />
      )}
    </>
  )
}

function MVPContent({ opportunity }: InnerProps) {
  return (
    <div className='space-y-4'>
      <p className='m-0 text-base'>
        <b>Minimum:</b> <span>{dollarFormat(opportunity.minimum_commitment)}</span>
      </p>
      <p className='m-0 text-base'>
        <b>Maximum:</b> <span>{dollarFormat(opportunity.maximum_commitment)}</span>
      </p>
    </div>
  )
}
function ChampionContent({ opportunity }: InnerProps) {
  return (
    <div className='space-y-4'>
      <p className='m-0 text-base'>
        <b>Minimum:</b> <span>{dollarFormat(opportunity.minimum_commitment)}</span>
      </p>
      <p className='m-0 text-base'>
        <b>Maximum:</b> <span>{dollarFormat(opportunity.maximum_commitment)}</span>
      </p>
    </div>
  )
}
function CoInvestContent({ opportunity }: InnerProps) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { mutate, isLoading } = useEditCommitmentRequirementMutate()

  const [investorsOnly, setInvestorsOnly] = useState<boolean>(
    () => !!opportunity.vintage_investors_only && !!opportunity.collection_investors_only
  )

  const onInvestorsToggle = (status: boolean) => {
    if (!opportunity) return
    mutate(
      {
        id: opportunity.id,
        vintage_investors_only: status,
        collection_investors_only: status
      },
      {
        onSuccess: async () => {
          toast({
            variant: 'success',
            description: 'Fund investment added/updated'
          })
          setInvestorsOnly(status)
          await queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(opportunity.id + '')
          })
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <div className='space-y-4'>
      <div className='flex itesm-center space-x-2'>
        {isLoading ? (
          <Skeleton className='h-6 w-full' />
        ) : (
          <>
            <Checkbox
              id='investors_only'
              checked={investorsOnly}
              className='w-5 h-5'
              onCheckedChange={onInvestorsToggle}
            />
            <Label htmlFor='investors_only' className='leading-1'>
              Fund Investors only
            </Label>
          </>
        )}
      </div>

      <div className='flex flex-col sm:flex-row space-y-4 sm:space-y-0'>
        <div className='flex-1 space-y-4 sm:space-y-6'>
          <h4 className='text-lg font-semibold'>MVP</h4>
          <div className='space-y-2 sm:space-y-4'>
            <p className='m-0 text-base'>
              <b>Minimum:</b> <span>{dollarFormat(opportunity.minimum_vintage_commitment)}</span>
            </p>
            <p className='m-0 text-base'>
              <b>Maximum:</b> <span>{dollarFormat(opportunity.maximum_vintage_commitment)}</span>
            </p>
          </div>
        </div>

        <div className='flex-1 space-y-4 sm:space-y-6'>
          <h4 className='text-lg font-semibold'>Champion</h4>
          <div className='space-y-2 sm:space-y-4'>
            <p className='m-0 text-base'>
              <b>Minimum:</b> <span>{dollarFormat(opportunity.minimum_collection_commitment)}</span>
            </p>
            <p className='m-0 text-base'>
              <b>Maximum:</b> <span>{dollarFormat(opportunity.maximum_collection_commitment)}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
