import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/Card'
import { Form, FormControl, FormField, FormItem, FormLabel } from 'components/ui/Form'
import { Checkbox } from 'components/ui/Checkbox'
import { convertDealType } from 'services/utils'
import { useUpdateOpportunityMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { useGetOpportunity } from 'hooks/api/useQuery.hooks'
import { OpportunityResponse } from 'types/api-types'
import { DealLoader } from './layouts/Loader'

interface Props {
  id: string
}

export function Visibility({ id }: Props) {
  const { data: opportunity } = useGetOpportunity(id)

  if (!opportunity) return <DealLoader.Card />

  return <VisibilityForm opportunity={opportunity} />
}

interface FormProps {
  opportunity: OpportunityResponse
}

const visibilitySchema = z.object({
  visible_on_champion: z.boolean().nullable(),
  visible_on_mvp: z.boolean().nullable(),
  show_in_portfolio: z.boolean()
})

type VisibilitySchema = z.infer<typeof visibilitySchema>

function VisibilityForm({ opportunity }: FormProps) {
  const opportunityType = convertDealType(opportunity.opportunity_type)

  const { toast } = useToast()

  const { mutate, isLoading } = useUpdateOpportunityMutate()

  const form = useForm<VisibilitySchema>({
    resolver: zodResolver(visibilitySchema),
    defaultValues: {
      visible_on_mvp: opportunity.visible_on_mvp,
      visible_on_champion: opportunity.visible_on_champion,
      show_in_portfolio: opportunity.show_in_portfolio || opportunity.show_in_portfolio_champ
    }
  })

  // watch for form changes and make API request.
  const { watch } = form
  useEffect(() => {
    const subscription = watch(value => {
      const { show_in_portfolio, ...rest } = value
      // Note: the BE expects `show_in_portfolio` or `show_in_portfolio_champ` fields to toggle the Our Companies page's visibility.
      // With the requested changes on the FE, I combined these two fields in the form, but send them as a separate field to the BE.
      // More info: https://opuslogica.atlassian.net/jira/software/projects/MVP/boards/4?selectedIssue=MVP-1057
      mutate(
        {
          id: opportunity.id,
          ...rest,
          show_in_portfolio,
          show_in_portfolio_champ: value.show_in_portfolio
        },
        {
          onSuccess: () => {
            toast({
              variant: 'success',
              description: 'Visibility updated'
            })
          },
          onError: error => {
            toast({
              variant: 'destructive',
              description: error.message ?? 'Something went wrong',
              duration: 2000
            })
          }
        }
      )
    })
    return () => subscription.unsubscribe()
  }, [watch, mutate])

  const isDisabled = opportunity.is_follow_on
  const isClosed = new Date(opportunity.closes) < new Date()

  return (
    <Card className='h-full'>
      <CardHeader>
        <CardTitle>Visibility</CardTitle>
      </CardHeader>

      <CardContent>
        <Form {...form}>
          <form className='space-y-2'>
            {['MVP Fund', 'Co-Invest'].includes(opportunityType ?? '') && (
              <FormField
                control={form.control}
                name='visible_on_mvp'
                render={({ field }) => (
                  <FormItem className='flex items-center space-y-0 space-x-2'>
                    <FormControl>
                      <Checkbox
                        checked={field.value ?? undefined}
                        className='w-5 h-5'
                        disabled={isLoading}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className='text-base font-normal space-x-2'>
                      <span>MVP Opportunities Page</span>
                      {isClosed && field.value && (
                        <span className='text-sm text-destructive'>(⚠️ Deal closed)</span>
                      )}
                    </FormLabel>
                  </FormItem>
                )}
              />
            )}
            {['Champion Fund', 'Co-Invest'].includes(opportunityType) && (
              <FormField
                control={form.control}
                name='visible_on_champion'
                render={({ field }) => (
                  <FormItem className='flex items-center space-y-0 space-x-2'>
                    <FormControl>
                      <Checkbox
                        checked={field.value ?? undefined}
                        className='w-5 h-5'
                        disabled={isLoading}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className='text-base font-normal space-x-2'>
                      <span>Champion Opportunities Page</span>
                      {isClosed && field.value && (
                        <span className='text-sm text-destructive'>(⚠️ Deal closed)</span>
                      )}
                    </FormLabel>
                  </FormItem>
                )}
              />
            )}
            {opportunityType === 'Co-Invest' && (
              <FormField
                control={form.control}
                name='show_in_portfolio'
                render={({ field }) => (
                  <FormItem className='flex items-center space-y-0 space-x-2'>
                    <FormControl>
                      <Checkbox
                        checked={field.value ?? undefined}
                        disabled={isLoading || isDisabled}
                        className='w-5 h-5'
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className='text-base font-normal'>
                      Full Portfolio (Our Companies) Page
                    </FormLabel>
                  </FormItem>
                )}
              />
            )}
          </form>
        </Form>
      </CardContent>
    </Card>
  )
}
