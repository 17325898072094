import { Visibility } from '../Visibility'
import { CommitmentRequirements } from '../CommitmentRequirements'
import { Commitments } from '../Commitments'
import { Summary } from '../Summary'
import { DueDiligence } from '../DueDiligence'
import { DataRoom } from '../DataRoom'
import { LayoutWrapper } from './Wrapper'
import { Valuation } from '../Valuation'
import { KeyPoints } from '../KeyPoints'
import { CarryTracking } from '../CarryTracking'
import { CompanyUpdates } from '../CompanyUpdates'
import { Videos } from '../Videos'
import { Section } from './Section'
import { Article } from './Article'

interface Props {
  id: string
}

export function ChampionFundLayout({ id }: Props) {
  return (
    <LayoutWrapper>
      <Section>
        <Article>
          <Visibility id={id} />
        </Article>
        <Article>
          <CommitmentRequirements id={id} />
        </Article>
      </Section>

      <Section>
        <Article>
          <Commitments id={id} />
        </Article>
        <Article>
          <Valuation id={id} />
          <div />
        </Article>
      </Section>

      <Section>
        <Article>
          <Summary id={id} />
        </Article>
      </Section>

      <Section>
        <Article>
          <KeyPoints id={id} />
        </Article>
      </Section>

      <Section>
        <Article>
          <DueDiligence id={id} />
        </Article>
        <Article>
          <CompanyUpdates id={id} />
        </Article>
      </Section>

      <Section>
        <Article>
          <DataRoom id={id} />
        </Article>
        <Article>
          <Videos id={id} />
          <div />
        </Article>
      </Section>

      <Section>
        <Article>
          <CarryTracking id={id} />
        </Article>
      </Section>
    </LayoutWrapper>
  )
}
