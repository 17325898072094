import type { OpportunityValuation } from 'types/api-types'
import { dollarFormat } from 'constants/DollarsFormat'

const FundValuationDetails: React.FC<OpportunityValuation> = (valuation) => [
  <tr key="total-invested-fund" className="h-10 border-b border-gray-200">
    <td>Total Invested:</td>
    <td className="px-4">{dollarFormat(valuation.total_invested)}</td>
  </tr>
]

export default FundValuationDetails
