import { useMemo } from 'react'
import type { ActionMeta } from 'react-select'
import { zodResolver } from '@hookform/resolvers/zod'
import { type SubmitHandler, useForm } from 'react-hook-form'
import DateTimePicker from 'react-datetime-picker'
import { useQueryClient } from '@tanstack/react-query'
import z from 'zod'
import { Button } from 'components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import { LoadingButton } from 'components/ui/LoadingButton'
import { CreatableSelect } from 'components/ui/CreatableSelect'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { Input } from 'components/ui/Input'
import { Skeleton } from 'components/ui/Skeleton'
import { OpportunityResponse } from 'types/api-types'
import {
  useCreateInvestorMutate,
  useCreatePropertyMutate,
  useUpdateOpportunityMutate
} from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory, staticKeyFactory } from 'helpers/api/factories/userKey'
import { useGetInvestorsList, useGetProperties } from 'hooks/api/useQuery.hooks'

interface Props {
  open: boolean
  toggleOpen: (open: boolean) => void
  opportunity: OpportunityResponse
}
type SelectedVal = { label: string; value: string | number }
type PropertyTypesKey = keyof typeof PROPERTY_TYPES
type FormPropertyTypesKey = keyof typeof FORM_PROPERTY_TYPES
type FormPropertyTypes = typeof FORM_PROPERTY_TYPES[FormPropertyTypesKey]

const DEFAULT_INVESTORS_LIMIT = 5_000
const PROPERTY_TYPES = {
  DEALSHARE_STAGE: 'dealshare_stage',
  DEALSHARE_SECTOR: 'dealshare_economic_sector',
  DEALSHARE_STAGE_ROLLUP: 'dealshare_stage_rollup',
  INVESTOR: 'investor'
} as const
const FORM_PROPERTY_TYPES = {
  DEALSHARE_STAGE: 'dealshare_stage_property_id',
  DEALSHARE_SECTOR: 'dealshare_economic_sector_property_id',
  DEALSHARE_STAGE_ROLLUP: 'dealshare_stage_rollup_property_id',
  INVESTOR: 'investor_id'
} as const

const editMVPFundSchema = z.object({
  name: z.string().min(1, { message: 'Name is required.' }),
  dealshare_stage_property_id: z.coerce.string().min(1, { message: 'Strage is required.' }),
  dealshare_economic_sector_property_id: z.coerce
    .string()
    .min(1, { message: 'Sector is required.' }),
  dealshare_stage_rollup_property_id: z.coerce
    .string()
    .min(1, { message: 'Stage rollup is required.' }),
  investor_id: z.string().min(1, { message: 'Investors is required.' }),
  closes: z.date({ message: 'Closing date is required.' })
})
type EditMVPFundSchema = z.infer<typeof editMVPFundSchema>

/**
 * Co-Invest Fund details dialog
 */
export function EditCoInvestDetailsDialog({ open, toggleOpen, opportunity }: Props) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const {
    data: properties,
    isLoading: isPropertiesLoading,
    isFetching: isPropertiesFetching
  } = useGetProperties()
  const {
    data: investors,
    isLoading: isInvestorsLoading,
    isFetching: isInvestorsFetching
  } = useGetInvestorsList(DEFAULT_INVESTORS_LIMIT)

  const options = useMemo(() => {
    if (!properties || !investors) return null
    return {
      dealshare_stage: properties
        .filter(d => d.property_type === PROPERTY_TYPES.DEALSHARE_STAGE)
        .map(d => ({ label: d.name, value: d.id })),
      dealshare_economic_sector: properties
        .filter(d => d.property_type === PROPERTY_TYPES.DEALSHARE_SECTOR)
        .map(d => ({ label: d.name, value: d.id })),
      dealshare_stage_rollup: properties
        .filter(d => d.property_type === PROPERTY_TYPES.DEALSHARE_STAGE_ROLLUP)
        .map(d => ({ label: d.name, value: d.id })),
      investors: investors.map(inv => ({ label: inv.name, value: inv.id }))
    }
  }, [properties, investors])

  const form = useForm<EditMVPFundSchema>({
    resolver: zodResolver(editMVPFundSchema),
    defaultValues: {
      name: opportunity.name,
      dealshare_stage_property_id: opportunity.dealshare_stage_property_id + '',
      dealshare_economic_sector_property_id: opportunity.dealshare_economic_sector_property_id + '',
      dealshare_stage_rollup_property_id: opportunity.dealshare_stage_rollup_property_id + '',
      investor_id: opportunity.investor_id + '',
      closes: new Date(opportunity.closes)
    }
  })

  const { mutate, isLoading } = useUpdateOpportunityMutate()
  const { mutate: createInvestorMutate, isLoading: isCreateInvestorLoading } =
    useCreateInvestorMutate()
  const { mutate: createPropertyMutate, isLoading: isCreatePropertyLoading } =
    useCreatePropertyMutate()

  const handleSubmit: SubmitHandler<EditMVPFundSchema> = values => {
    const {
      dealshare_economic_sector_property_id,
      dealshare_stage_property_id,
      dealshare_stage_rollup_property_id,
      investor_id,
      ...rest
    } = values
    mutate(
      {
        id: opportunity.id,
        dealshare_stage_property_id: +dealshare_stage_property_id,
        dealshare_economic_sector_property_id: +dealshare_economic_sector_property_id,
        dealshare_stage_rollup_property_id: +dealshare_stage_rollup_property_id,
        investor_id: +investor_id,
        ...rest
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(opportunity.id + '')
          })
          toast({
            variant: 'success',
            description: 'Co-Invest Details have been updated'
          })
          toggleOpen(false)
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  const handleCreatableSelect =
    (val: unknown, action: ActionMeta<unknown>) => (propertyType: FormPropertyTypes) => {
      if (action.action !== 'create-option') return

      const selectedVal = val as { label: string; value: string | number }
      if (propertyType === FORM_PROPERTY_TYPES.INVESTOR) {
        createInvestorMutate(
          { name: selectedVal.label },
          {
            onSuccess: async data => {
              await queryClient.invalidateQueries({
                queryKey: staticKeyFactory.investors(DEFAULT_INVESTORS_LIMIT)
              })
              form.setValue(propertyType, data.id + '')
            }
          }
        )
      } else {
        const foundFormProperty = Object.keys(FORM_PROPERTY_TYPES).find(
          key => FORM_PROPERTY_TYPES[key as FormPropertyTypesKey] === propertyType
        )
        if (!foundFormProperty) return
        createPropertyMutate(
          {
            name: selectedVal.label,
            property_type: PROPERTY_TYPES[foundFormProperty as PropertyTypesKey]
          },
          {
            onSuccess: async data => {
              await queryClient.invalidateQueries({ queryKey: staticKeyFactory.properties() })
              form.setValue(propertyType, data.id + '')
            }
          }
        )
      }
    }

  const isLoadingData =
    isPropertiesFetching || isPropertiesLoading || isInvestorsFetching || isInvestorsLoading

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='max-w-[1035px]'>
        <DialogHeader>
          <DialogTitle className='text-2xl'>Edit Co-Invest Details</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className='space-y-4'>
              <FormField
                control={form.control}
                name='name'
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Company Name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />

              <div className='flex sm:items-center flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0'>
                <div className='flex-1'>
                  <FormField
                    control={form.control}
                    name={FORM_PROPERTY_TYPES.DEALSHARE_STAGE}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Stage</FormLabel>
                          {isLoadingData || isCreatePropertyLoading ? (
                            <Skeleton className='w-full h-12 bg-gray-300' />
                          ) : (
                            <CreatableSelect
                              {...field}
                              options={options?.dealshare_stage}
                              value={options?.dealshare_stage.find(
                                op => +op.value === +field.value
                              )}
                              onChange={(val, action) => {
                                const selectedVal = val as SelectedVal
                                field.onChange(selectedVal.value)
                                handleCreatableSelect(val, action)(field.name)
                              }}
                            />
                          )}
                          <FormMessage />
                        </FormItem>
                      )
                    }}
                  />
                </div>
                <div className='flex-1'>
                  <FormField
                    control={form.control}
                    name={FORM_PROPERTY_TYPES.DEALSHARE_SECTOR}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Sector</FormLabel>
                          {isLoadingData || isCreatePropertyLoading ? (
                            <Skeleton className='w-full h-12 bg-gray-300' />
                          ) : (
                            <CreatableSelect
                              {...field}
                              options={options?.dealshare_economic_sector}
                              value={options?.dealshare_economic_sector.find(
                                op => +op.value === +field.value
                              )}
                              onChange={(val, action) => {
                                const selectedVal = val as SelectedVal
                                field.onChange(selectedVal.value)
                                handleCreatableSelect(val, action)(field.name)
                              }}
                            />
                          )}
                          <FormMessage />
                        </FormItem>
                      )
                    }}
                  />
                </div>
              </div>

              <div className='flex sm:items-center flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0'>
                <div className='flex-1'>
                  <FormField
                    control={form.control}
                    name={FORM_PROPERTY_TYPES.DEALSHARE_STAGE_ROLLUP}
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Stage Rollup</FormLabel>
                          {isLoadingData || isCreatePropertyLoading ? (
                            <Skeleton className='w-full h-12 bg-gray-300' />
                          ) : (
                            <CreatableSelect
                              {...field}
                              options={options?.dealshare_stage_rollup}
                              value={options?.dealshare_stage_rollup.find(
                                op => +op.value === +field.value
                              )}
                              onChange={(val, action) => {
                                const selectedVal = val as SelectedVal
                                field.onChange(selectedVal.value)
                                handleCreatableSelect(val, action)(field.name)
                              }}
                            />
                          )}
                          <FormMessage />
                        </FormItem>
                      )
                    }}
                  />
                </div>
                <div className='flex-1'>
                  <FormField
                    control={form.control}
                    name='closes'
                    render={({ field }) => {
                      return (
                        <FormItem>
                          <FormLabel>Closing Date</FormLabel>
                          <FormControl>
                            <DateTimePicker
                              value={field.value}
                              disableClock
                              clearIcon={null}
                              onChange={field.onChange}
                              className='mvp-datetime-picker border border-[#BCCDE5] bg-white h-12 px-4 rounded-lg mb-2 w-full'
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )
                    }}
                  />
                </div>
              </div>

              <FormField
                control={form.control}
                name={FORM_PROPERTY_TYPES.INVESTOR}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Lead Investor</FormLabel>
                      {isLoadingData || isCreateInvestorLoading ? (
                        <Skeleton className='w-full h-12 bg-gray-300' />
                      ) : (
                        <CreatableSelect
                          {...field}
                          options={options?.investors}
                          value={options?.investors.find(op => +op.value === +field.value)}
                          onChange={(val, action) => {
                            const selectedVal = val as SelectedVal
                            field.onChange(selectedVal.value + '')
                            handleCreatableSelect(val, action)(field.name)
                          }}
                        />
                      )}
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>

            <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
              <Button
                type='button'
                size='sm'
                disabled={isLoading}
                className='flex-1 max-w-[262px]'
                onClick={() => toggleOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                loading={isLoading}
                size='sm'
                className='flex-1 max-w-[262px] bg-success'
              >
                Save
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
