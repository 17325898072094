import { type SubmitHandler, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { zodResolver } from '@hookform/resolvers/zod'
import z from 'zod'
import { Button } from 'components/ui/Button'
import { CurrencyInput } from 'components/ui/CurrencyInput'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { LoadingButton } from 'components/ui/LoadingButton'
import { OpportunityResponse } from 'types/api-types'
import { useEditCommitmentRequirementMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'
import { Checkbox } from 'components/ui/Checkbox'
import { dealType } from 'services/utils'

interface Props {
  opportunity: OpportunityResponse
  open: boolean
  toggleOpen: (open: boolean) => void
}

const fundInvestmentSchema = z.object({
  minimum_commitment: z.number(),
  maximum_commitment: z.number(),
  minimum_vintage_commitment: z.number().nullable(),
  maximum_vintage_commitment: z.number().nullable(),
  minimum_collection_commitment: z.number().nullable(),
  maximum_collection_commitment: z.number().nullable()
})
type FundInvestmentSchema = z.infer<typeof fundInvestmentSchema>

export function EditCommitmentRequirementsDialog({ opportunity, open, toggleOpen }: Props) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { mutate, isLoading } = useEditCommitmentRequirementMutate()

  const form = useForm<FundInvestmentSchema>({
    resolver: zodResolver(fundInvestmentSchema),
    defaultValues: {
      minimum_commitment: opportunity.minimum_commitment ?? 0,
      maximum_commitment: opportunity.maximum_commitment ?? 0,
      minimum_vintage_commitment: opportunity.minimum_vintage_commitment ?? null,
      maximum_vintage_commitment: opportunity.maximum_vintage_commitment ?? null,
      minimum_collection_commitment: opportunity.minimum_collection_commitment ?? null,
      maximum_collection_commitment: opportunity.maximum_collection_commitment ?? null
    }
  })

  const handleSubmit: SubmitHandler<FundInvestmentSchema> = values => {
    mutate(
      {
        id: opportunity.id,
        ...values
      },
      {
        onSuccess: async () => {
          toast({
            variant: 'success',
            description: 'Fund investment added/updated'
          })
          await queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(opportunity.id + '')
          })
          toggleOpen(false)
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  const opportunityType = dealType(opportunity)
  const title =
    opportunityType === 'MVP Fund'
      ? 'MVP'
      : opportunityType === 'Champion Fund'
      ? 'Champion'
      : 'Co-Invest'

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='max-w-[1035px]'>
        <DialogHeader>
          <DialogTitle className='text-2xl'>Edit Commitment Requirements</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className='space-y-4'>
              {opportunityType !== 'Co-Invest' ? (
                <div className='flex flex-col sm:flex-row space-x-2'>
                  <FormField
                    control={form.control}
                    name='minimum_commitment'
                    render={({ field }) => {
                      const { onChange, value, ...rest } = field
                      return (
                        <FormItem className='flex-1'>
                          <FormLabel>{title} Min Commitment</FormLabel>
                          <FormControl>
                            <CurrencyInput
                              {...rest}
                              value={value ?? 0}
                              onValueChange={val => {
                                if (val !== undefined && +val < 25_000) onChange(+val)
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )
                    }}
                  />
                  <FormField
                    control={form.control}
                    name='maximum_commitment'
                    render={({ field }) => {
                      const { onChange, value, ...rest } = field
                      return (
                        <FormItem className='flex-1'>
                          <FormLabel>{title} Max Commitment</FormLabel>
                          <FormControl>
                            <CurrencyInput
                              {...rest}
                              value={value ?? 0}
                              onValueChange={val => {
                                if (val !== undefined && +val > 200_000) onChange(+val)
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className='space-y-2'>
                    <div className='flex flex-col sm:flex-row space-x-2'>
                      <FormField
                        control={form.control}
                        name='minimum_vintage_commitment'
                        render={({ field }) => {
                          const { onChange, value, ...rest } = field
                          return (
                            <FormItem className='flex-1'>
                              <FormLabel>MVP Minimum Commitment</FormLabel>
                              <FormControl>
                                <CurrencyInput
                                  {...rest}
                                  value={value ?? 0}
                                  onValueChange={val => val !== undefined && onChange(+val)}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )
                        }}
                      />
                      <FormField
                        control={form.control}
                        name='maximum_vintage_commitment'
                        render={({ field }) => {
                          const { onChange, value, ...rest } = field
                          return (
                            <FormItem className='flex-1'>
                              <FormLabel>MVP Maximum Commitment</FormLabel>
                              <FormControl>
                                <CurrencyInput
                                  {...rest}
                                  value={value ?? 0}
                                  onValueChange={val => val !== undefined && onChange(+val)}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className='space-y-2'>
                    <div className='flex flex-col sm:flex-row space-x-2'>
                      <FormField
                        control={form.control}
                        name='minimum_collection_commitment'
                        render={({ field }) => {
                          const { onChange, value, ...rest } = field
                          return (
                            <FormItem className='flex-1'>
                              <FormLabel>Champion Minimum Commitment</FormLabel>
                              <FormControl>
                                <CurrencyInput
                                  {...rest}
                                  value={value ?? 0}
                                  onValueChange={val => val !== undefined && onChange(+val)}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )
                        }}
                      />
                      <FormField
                        control={form.control}
                        name='maximum_collection_commitment'
                        render={({ field }) => {
                          const { onChange, value, ...rest } = field
                          return (
                            <FormItem className='flex-1'>
                              <FormLabel>Champion Maximum Commitment</FormLabel>
                              <FormControl>
                                <CurrencyInput
                                  {...rest}
                                  value={value ?? 0}
                                  onValueChange={val => val !== undefined && onChange(+val)}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
              <Button
                type='button'
                size='sm'
                disabled={isLoading}
                className='flex-1 max-w-[262px]'
                onClick={() => toggleOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                loading={isLoading}
                size='sm'
                className='flex-1 max-w-[262px] bg-success'
              >
                Save
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
