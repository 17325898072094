/**
 * File do have external URLs
 */

export const links = {
  hubspot: {
    company: (hubspotId: number | string) =>
      `https://app.hubspot.com/contacts/8426488/company/${hubspotId}`,
    contact: (hubspotId: number | string) =>
      `https://app.hubspot.com/contacts/8426488/contact/${hubspotId}`
  }
}
