import React, { useState, useEffect } from 'react'
import { Input as UiInput } from '../ui/Input' // name conflict
import { Label } from '../ui/Label'

interface InputProps {
  type?: string
  onChange?: (value: string) => void
  inputId?: string
  value?: string
  label?: string
  icon?: string
  min?: number
  max?: number
  className?: string
  disabled?: boolean
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
}

const Input: React.FC<InputProps> = ({
  type,
  onChange,
  inputId,
  value,
  label,
  min: minValue,
  max: maxValue,
  className = '',
  disabled = false,
  ...other
}) => {
  const inputProps = {
    id: inputId,
    type,
    min: minValue,
    max: maxValue,
    value,
    disabled,
    ...other
  }

  const [inputValue, setInputValue] = useState(value || '')

  const localOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    onChange && onChange(e.target.value)
  }

  return (
    <div className={`form-margin-bottom ${className}`}>
      {label && <Label htmlFor={inputId} className="form-label">{label}</Label>}
      <UiInput
        {...inputProps}
        name={inputId}
        value={inputValue}
        onChange={localOnChange}
      />
    </div>
  )
}

export default Input
