import { zodResolver } from '@hookform/resolvers/zod'
import { type SubmitHandler, useForm } from 'react-hook-form'
import DateTimePicker from 'react-datetime-picker'
import { useQueryClient } from '@tanstack/react-query'
import z from 'zod'
import { Button } from 'components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import { LoadingButton } from 'components/ui/LoadingButton'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { Input } from 'components/ui/Input'
import { CurrencyInput } from 'components/ui/CurrencyInput'
import { OpportunityResponse } from 'types/api-types'
import { useUpdateOpportunityMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'

interface Props {
  open: boolean
  toggleOpen: (open: boolean) => void
  opportunity: OpportunityResponse
}

const editMVPFundSchema = z.object({
  name: z.string().min(1, { message: 'Name is required.' }),
  vintage_anticipated_amount: z.string().min(1, { message: 'Amount is required.' }),
  closes: z.date({ message: 'Closing date is required.' })
})
type EditMVPFundSchema = z.infer<typeof editMVPFundSchema>

/**
 * MVP Fund details dialog
 */
export function EditMVPFundDetailsDialog({ open, toggleOpen, opportunity }: Props) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const form = useForm<EditMVPFundSchema>({
    resolver: zodResolver(editMVPFundSchema),
    defaultValues: {
      name: opportunity.name,
      vintage_anticipated_amount: opportunity.vintage_anticipated_amount ?? '',
      closes: new Date(opportunity.closes)
    }
  })

  const { mutate, isLoading } = useUpdateOpportunityMutate()

  const handleSubmit: SubmitHandler<EditMVPFundSchema> = values => {
    mutate(
      { id: opportunity.id, ...values },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(opportunity.id + '')
          })
          toast({
            variant: 'success',
            description: 'MVP Fund Details have been updated'
          })
          toggleOpen(false)
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='max-w-[1035px]'>
        <DialogHeader>
          <DialogTitle className='text-2xl'>Edit MVP Fund Details</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className='space-y-4'>
              <FormField
                control={form.control}
                name='name'
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Company Name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='vintage_anticipated_amount'
                render={({ field }) => {
                  const { onChange, value, ...rest } = field
                  return (
                    <FormItem>
                      <FormLabel>Anticipated Fund Size</FormLabel>
                      <FormControl>
                        <CurrencyInput
                          {...rest}
                          value={+value}
                          onValueChange={value => onChange(value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='closes'
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Closing Date</FormLabel>
                      <FormControl>
                        <DateTimePicker
                          value={field.value}
                          disableClock
                          clearIcon={null}
                          onChange={field.onChange}
                          className='mvp-datetime-picker border border-[#BCCDE5] bg-white h-12 px-4 rounded-lg mb-2 w-full'
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>

            <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
              <Button
                type='button'
                size='sm'
                disabled={isLoading}
                className='flex-1 max-w-[262px]'
                onClick={() => toggleOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                loading={isLoading}
                size='sm'
                className='flex-1 max-w-[262px] bg-success'
              >
                Save
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
