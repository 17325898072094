import React from 'react'
import styled from 'styled-components'
import logo from 'assets/images/hubspot.svg'

const HSImg = styled.img`
  width: ${props => (props.size ? props.size : '30px')};
  margin-left: 5px;
`

/**
 * @param {int} hubspotId - Contact id from hubspot
 * @param {CSS string} size - Width of the image, optional.
 */
export default function HubspotLink({ hubspotId, size, organization = false }) {
  return (
    <a
      target='__blank'
      href={`https://app.hubspot.com/contacts/8426488/${
        organization ? 'company' : 'contact'
      }/${hubspotId}/`}
    >
      <HSImg src={logo} size={size} />
    </a>
  )
}
