import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogOverlay
} from 'components/ui/AlertDialog'
import { Button } from 'components/ui/Button'

// TODO: temporarily accepting props. Each alert modal should do its own thing
interface Props {
  open: boolean
  toggleOpen: (open: boolean) => void
  header: string
  message: string
  action: () => void
}

export function ConfirmAlert({ open, toggleOpen, header, message, action }: Props) {
  return (
    <AlertDialog open={open} onOpenChange={toggleOpen}>
      <AlertDialogOverlay className='z-10' />
      <AlertDialogContent className='rounded-md max-w-3xl z-[1000]'>
        <AlertDialogHeader>
          <AlertDialogTitle>{header}</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>{message}</AlertDialogDescription>

        <AlertDialogFooter className='mt-4 flex-row space-x-2 sm:justify-start'>
          <Button
            variant='outline'
            type='button'
            size='sm'
            className='flex-1 max-w-[262px]'
            onClick={() => toggleOpen(false)}
          >
            Close
          </Button>

          <Button type='button' size='sm' className='flex-1 max-w-[262px]' onClick={action}>
            Confirm
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
