// backend types comes in opportunity_type
// "Collection" => CHAMPION_VINTAGE
// "Vintage" => MVP_VINTAGE
// "DealShare" => CO_INVEST
import { Opportunity } from '../../../../services/DataStore'

export const CO_INVEST = Opportunity.TYPE_DEALSHARE // 'Co-Invest'
export const MVP_VINTAGE =  Opportunity.TYPE_VINTAGE // 'MVP Vintage'
export const CHAMPION_VINTAGE = Opportunity.TYPE_COLLECTION // 'Champion Vintage'
export const TYPES = [ CO_INVEST, MVP_VINTAGE, CHAMPION_VINTAGE ]

export const DEAL_FORM_TITLES: { [key: string]: string } = {
  [CO_INVEST]: 'Co-Invest',
  [MVP_VINTAGE]: 'MVP Fund',
  [CHAMPION_VINTAGE]: 'Champion Fund'
}

export const getDealFormTitle = (type : string) => {
  return DEAL_FORM_TITLES[type] || 'Deal'
}
