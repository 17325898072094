import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'components/ui/Button'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from 'components/ui/AlertDialog'
import { LoadingButton } from 'components/ui/LoadingButton'
import { OpportunityResponse } from 'types/api-types'
import { useUpdateDueDiligenceMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'

interface Props {
  opportunity: OpportunityResponse
  open: boolean
  onClose: () => void
  selectedLink: OpportunityResponse['links'][number] | null
}

export function DeleteDueDiligenceDialog({ opportunity, selectedLink, open, onClose }: Props) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { mutate, isLoading } = useUpdateDueDiligenceMutate()

  const handleDelete = async () => {
    if (!selectedLink) return

    const updatedLinks = opportunity.links.map(kp => {
      if (kp.id === selectedLink.id) return { ...kp, _destroy: 1 }
      return kp
    })

    mutate(
      { id: opportunity.id, links: updatedLinks },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(`${opportunity.id}`),
            type: 'all'
          })
          toast({
            variant: 'success',
            description: 'Due diligence deleted'
          })
          onClose()
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <AlertDialog open={open} onOpenChange={onClose}>
      <AlertDialogContent className='max-w-3xl'>
        <AlertDialogHeader>
          <AlertDialogTitle>Remove Due Diligence</AlertDialogTitle>
        </AlertDialogHeader>

        <AlertDialogDescription className='flex flex-col'>
          <span>Are you sure you want to remove</span>
          <span className='mt-2 font-medium'>{selectedLink?.label_html}</span>
        </AlertDialogDescription>

        <AlertDialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
          <Button
            type='button'
            size='sm'
            disabled={isLoading}
            className='flex-1 max-w-[262px]'
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            type='button'
            loading={isLoading}
            size='sm'
            className='flex-1 max-w-[262px] bg-archived'
            onClick={handleDelete}
          >
            Remove
          </LoadingButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
