import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'components/ui/Button'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from 'components/ui/AlertDialog'
import { LoadingButton } from 'components/ui/LoadingButton'
import { OpportunityResponse } from 'types/api-types'
import { useUpdateKeyPointsMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'
import type { OpportunityKeyPoint } from '../KeyPoints'

interface Props {
  opportunity: OpportunityResponse
  open: boolean
  toggleOpen: () => void
  keyPoint?: OpportunityKeyPoint
}

export function DeleteKeyPointDialog({ opportunity, keyPoint, open, toggleOpen }: Props) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { mutate, isLoading } = useUpdateKeyPointsMutate()

  const handleDelete = async () => {
    if (!keyPoint) return

    // const updatedKeyPoints = opportunity.key_points.filter(kp => kp.id !== keyPoint.id)
    const updatedKeyPoints = opportunity.key_points.map(kp => {
      if (kp.id === keyPoint.id) return { ...kp, _destroy: 1 }
      return kp
    })

    mutate(
      { id: opportunity.id, keyPoints: updatedKeyPoints },
      {
        onSuccess: data => {
          queryClient.setQueryData<OpportunityResponse>(
            opportunityKeyFactory.opportunityByDeal(opportunity.id + ''),
            oldData => {
              if (!oldData) return undefined
              return {
                ...oldData,
                key_points: data.key_points
              }
            }
          )
          toast({
            variant: 'success',
            description: 'Key point deleted'
          })
          toggleOpen()
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <AlertDialog open={open} onOpenChange={toggleOpen}>
      <AlertDialogContent className='max-w-3xl'>
        <AlertDialogHeader>
          <AlertDialogTitle>Remove Key Point</AlertDialogTitle>
        </AlertDialogHeader>

        <AlertDialogDescription className='flex flex-col'>
          <span>Are you sure you want to remove the key point?</span>
          <span className='mt-2 font-medium'>{keyPoint?.text_html}</span>
        </AlertDialogDescription>

        <AlertDialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
          <Button
            type='button'
            size='sm'
            disabled={isLoading}
            className='flex-1 max-w-[262px]'
            onClick={toggleOpen}
          >
            Cancel
          </Button>
          <LoadingButton
            type='button'
            loading={isLoading}
            size='sm'
            className='flex-1 max-w-[262px] bg-archived'
            onClick={handleDelete}
          >
            Remove
          </LoadingButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
