import { ComponentPropsWithoutRef } from 'react'

export function RankingLogo({ className }: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M8.67 14H4C2.9 14 2 14.9 2 16V22H8.67V14Z'
        stroke='#111928'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3299 10H10.6599C9.55991 10 8.65991 10.9 8.65991 12V22H15.3299V12C15.3299 10.9 14.4399 10 13.3299 10Z'
        stroke='#111928'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.0001 17H15.3301V22H22.0001V19C22.0001 17.9 21.1001 17 20.0001 17Z'
        stroke='#111928'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
