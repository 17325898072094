import { useState } from 'react'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from 'components/ui/AlertDialog'
import { LoadingButton } from 'components/ui/LoadingButton'
import { useResendApprovalEmailMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { useManageDetailsDialog } from 'contexts/ManageDetailsDialog.context'
import { Button } from 'components/ui/Button'

export function ResendApprovalEmail() {
  const { toast } = useToast()

  const { state } = useManageDetailsDialog()

  const [open, setOpen] = useState<boolean>(false)

  const { mutate, isLoading } = useResendApprovalEmailMutate()

  const resendApprovalEmailHandler = () => {
    if (!state.user) return
    mutate(state.user.id, {
      onSuccess: () => {
        toast({
          variant: 'success',
          description: 'Approval email was sent',
          duration: 2000
        })
        setOpen(false)
      },
      onError: error => {
        toast({
          variant: 'destructive',
          description: error.message ?? 'Something went wrong',
          duration: 2000
        })
      }
    })
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger className='w-full text-left'>Resend approval email</AlertDialogTrigger>

      <AlertDialogContent className='rounded-md max-w-3xl'>
        <AlertDialogHeader>
          <AlertDialogTitle className='text-2xl montserrat'>Resend approval email</AlertDialogTitle>
          <AlertDialogDescription className='text-base montserrat text-[#4A4A4A] break-all'>
            Approval email will be sent to {state.user?.username}.
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter className='mt-4 flex-row space-x-2 sm:justify-start'>
          <Button className='flex-1 max-w-[262px]' size='sm' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <LoadingButton
            type='button'
            loading={isLoading}
            size='sm'
            className='ml-2 flex-1 max-w-[262px] bg-[#52B749]'
            onClick={resendApprovalEmailHandler}
          >
            Resend email
          </LoadingButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
