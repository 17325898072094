import { Skeleton } from 'components/ui/Skeleton'
import { LayoutWrapper } from 'components/core/v2/deal/layouts/Wrapper'
import { Card, CardContent, CardHeader } from 'components/ui/Card'

function DealCardLoader() {
  return (
    <Card>
      <CardHeader>
        <Skeleton className='w-full h-12' />
      </CardHeader>
      <CardContent className='space-y-2'>
        <Skeleton className='w-full h-6' />
        <Skeleton className='w-full h-6' />
        <Skeleton className='w-full h-6' />
      </CardContent>
    </Card>
  )
}

function DealBodyLoader() {
  return (
    <LayoutWrapper>
      <section className='flex space-x-4'>
        <article className='flex-1'>
          <DealCardLoader />
        </article>
        <article className='flex-1'>
          <DealCardLoader />
        </article>
      </section>

      <section className='flex space-x-4'>
        <article className='flex-1'>
          <DealCardLoader />
        </article>
        <article className='flex-1'>
          <DealCardLoader />
        </article>
      </section>
    </LayoutWrapper>
  )
}

function DealHeaderLoader() {
  return (
    <header className='bg-[#10273A] px-4'>
      <div className='max-w-[1500px] mx-auto px-4 pt-[57px] pb-[113px] isolate'>
        <div className='flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-x-10 sm:space-y-0'>
          <Skeleton className='sm:w-[273px] h-[195px] rounded-xl p-4 relative group' />

          <div className='flex-1 space-y-4'>
            {[...Array(4)].map((_, idx) => (
              <Skeleton key={`deal-header-skeleton-${idx}`} className='w-full max-w-[300px] h-8' />
            ))}
          </div>
        </div>
      </div>
    </header>
  )
}

export const DealLoader = {
  Header: DealHeaderLoader,
  Body: DealBodyLoader,
  Card: DealCardLoader
}
