import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import { WithContext as ReactTags } from 'react-tag-input'
import { Button } from './ui/Button'
import Input from './fields/Input'
import CurrencyInput from './fields/CurrencyInput'
import DateTimePicker from './fields/DateTimePicker'
import Select from './fields/Select'
import Textarea from './fields/Textarea'
import { DataStore, Opportunity, Property } from '../services/DataStore'
import { authenticatedFetch } from '../services/authentication'
import { dealName } from '../services/utils'
import configuration from '../services/config'
import SavingProgress from './SavingProgress'
import Checkbox from './core/Checkbox'
import { getDealFormTitle } from './core/v2/deals/DealTypes'
import LogoAndColorPicker from './fields/LogoAndColorPicker'
import ConfirmationAlert from './ConfirmationAlert'
import { useHistory } from 'react-router-dom'
import { Label } from './ui/Label'
import { CreatableSelect } from './ui/CreatableSelect'

const TYPE_OPTIONS = [
  { value: Opportunity.TYPE_DEALSHARE, label: 'Company Investment' },
  { value: Opportunity.TYPE_VINTAGE, label: 'MVP Vintage' },
  { value: Opportunity.TYPE_COLLECTION, label: 'Champion Vintage' }
]

const INVESTOR_ID = 'investor_id'
const DEFAULT_INVESTORS_LIMIT = 5_000

// !JSX element class does not support attributes because it does not have a 'props' property.
// errors -> when used in TSX component.
// /**
//  *
//  * @param deal - The Opportunity to edit. If falsy, creates a new Opportunity
//  * @param {function} close - Function that is called when the user is finished with the form
//  * @constructor
//  */
export default function EditDeal({ deal, close, history }) {
  history = history || useHistory()
  const BLANK_DEAL = {
    opportunity_type: Opportunity.TYPE_DEALSHARE,
    background_color: '#FFFFFF'
  }
  const INITIAL_DEAL = deal ? JSON.parse(JSON.stringify(deal)) : BLANK_DEAL // BLANK_DEAL to fix controlled/uncontrolled input warning

  // A copy of the deal that we will use for editing
  const [dealCopy, setDealCopy] = useState(Object.assign(new Opportunity(), BLANK_DEAL, deal))
  /**
   * The Resource (A.K.A. Link, A.K.A. Due Diligence item) to edit. When set, the edit modal is shown
   */
  const [error, setError] = useState(false)
  const [saving, setSaving] = useState(false)
  const [successSaving, setSuccessSaving] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [vintageOpportunities, setVintageOpportunities] = useState([])
  const [parents, setParents] = useState([])
  const [loadingParents, setLoadingParents] = useState(false)
  const [shortCount, setShortCount] = useState(0)
  const [isModified, setIsModified] = useState(false)
  const [confirmationAlertOpen, setConfirmationAlertOpen] = useState(false)

  const SHORT_MAX = 650

  const [economicSectorOptions, setEconomicSectorOptions] = useState([])
  const [stageOptions, setStageOptions] = useState([])
  const [stageRollupOptions, setStageRollupOptions] = useState([])
  const [investorsOptions, setInvestorsOptions] = useState([])
  const [tags, setTags] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setDealCopy(Object.assign(new Opportunity(), BLANK_DEAL, deal))
  }, [deal])

  const propertyOptionStates = useMemo(() => {
    return {
      [Property.TYPE_ECONOMIC_SECTOR]: {
        set: setEconomicSectorOptions,
        val: economicSectorOptions,
        property: 'dealshare_economic_sector_property_id'
      },
      [Property.TYPE_DEALSHARE_STAGE]: {
        set: setStageOptions,
        val: stageOptions,
        property: 'dealshare_stage_property_id'
      },
      [Property.TYPE_DEALSHARE_STAGE_ROLLUP]: {
        set: setStageRollupOptions,
        val: stageRollupOptions,
        property: 'dealshare_stage_rollup_property_id'
      },
      [INVESTOR_ID]: {
        set: setInvestorsOptions,
        val: investorsOptions,
        property: INVESTOR_ID
      }
      // investor_id: {
      //   set: setInvestorsOptions,
      //   val: investorsOptions,
      //   property: 'investor_id'
      // }
    }
  }, [
    economicSectorOptions,
    setEconomicSectorOptions,
    stageOptions,
    setStageOptions,
    stageRollupOptions,
    setStageRollupOptions,
    investorsOptions,
    setInvestorsOptions
  ])

  const submitedOnce = useRef(false)

  // disable platform according to deal type
  useEffect(() => {
    if (dealCopy.isVintage()) {
      handleInputChange('champion', false)
    }
    if (dealCopy.isCollection()) {
      handleInputChange('mvp', false)
    }
  }, [dealCopy.opportunity_type])

  useEffect(() => {
    DataStore.findAll('properties', {}, { force: true }).then(r => {
      setStageOptions(
        r
          .filter(o => o.property_type === Property.TYPE_DEALSHARE_STAGE)
          .map(s => {
            return { label: s.name, value: s.id }
          })
      )
      setEconomicSectorOptions(
        r
          .filter(o => o.property_type === Property.TYPE_ECONOMIC_SECTOR)
          .map(s => {
            return { label: s.name, value: s.id }
          })
      )
      setStageRollupOptions(
        r
          .filter(o => o.property_type === Property.TYPE_DEALSHARE_STAGE_ROLLUP)
          .map(s => {
            return { label: s.name, value: s.id }
          })
      )
    })
  }, [setEconomicSectorOptions])

  function validColor(c) {
    const ele = document.createElement('div')
    ele.style.color = c
    const temp = ele.style.color.length > 0 || !!c.match(/^linear-gradient/)
    return temp
  }

  const validator = () => {
    if (dealCopy.isDealshare()) {
      if (
        !dealCopy.minimum_vintage_commitment ||
        !dealCopy.maximum_vintage_commitment ||
        !dealCopy.minimum_collection_commitment ||
        !dealCopy.maximum_collection_commitment
      ) {
        setError('Minimum and Maximum commitment are required')
        return false
      }

      if (!dealCopy.dealshare_stage_property_id) {
        setError('Co-Invests must have an associated Stage')
        return false
      }
      if (!dealCopy.dealshare_economic_sector_property_id) {
        setError('Co-Invests must have an associated Sector')
        return false
      }
      if (!dealCopy.dealshare_stage_rollup_property_id) {
        setError('Co-Invests must have an associated Stage Rollup')
        return false
      }
    }

    if (!dealCopy.isDealshare() && (!dealCopy.minimum_commitment || !dealCopy.maximum_commitment)) {
      setError('Minimum and Maximum commitment are required')
      return false
    }

    if (dealCopy.isCollection() && !dealCopy.dealshare_vintage_id) {
      setError('Champion Vintages must have an associated MVP Vintage')
      return false
    }
    if (!dealCopy.name || dealCopy.name.trim().length === 0) {
      setError('Deal must have a Company Name')
      return false
    }

    if (!dealCopy.background_color || !validColor(dealCopy.background_color)) {
      setError('Please add a valid color')
      return false
    }
    if (!dealCopy.closes) {
      setError('Deal must have a Closing Date')
      return false
    }
    if (dealCopy.isDealshare() && !dealCopy.company_url) {
      setError('Deal must have a Company Website')
      return false
    }
    if (!dealCopy.short_description_html || !dealCopy.long_description_html) {
      setError('Deal must have a Short and Long Description')
      return false
    }
    if (dealCopy.isVintage() && !dealCopy.vintage_anticipated_amount) {
      setError('Deal must have an Anticipated Fund Size')
      return false
    }
    setError(false)
    return true
  }

  const handleSave = () => {
    submitedOnce.current = true

    if (!validator()) return

    setSaving(true)

    let savePromise
    dealCopy.name = dealCopy.name.trim() // Small fix to remove blank spaces.
    dealCopy.tag_list = tags.map(item => item.id).join(',')
    if (deal?.id) {
      // edit
      savePromise = DataStore.update('opportunities', deal.id, dealCopy, {
        onUploadProgress: progress =>
          setUploadProgress(Math.round((progress.loaded / progress.total) * 100))
      })
    } else {
      // new deal
      savePromise = DataStore.create('opportunities', dealCopy)
      //   .then((newDeal) => {
      //   console.log("TODO: forward to Deal page. Not sure why this doesn't work", history);
      // })
    }

    savePromise
      .then(savedDeal => {
        setDealCopy(Object.assign(new Opportunity(), savedDeal))
        setSuccessSaving(true)
        history.push('/admin/deals/' + savedDeal.id)
      })
      .catch(err => {
        if (err.response?.status === 401) {
          history.push('/')
        } else {
          setSaving(false)
          setError(err?.response?.data?.message || err.message || 'Could not save Deal')
        }
      })
  }

  const closeAfterSuccess = () => {
    setSaving(false)
    setSuccessSaving(false)
    close()
  }

  const handleAddTag = tag => {
    setTags([...tags, tag])
    handleCloseTagsList()
  }

  const handleCloseTagsList = () => {
    setSearchResults([])
    setInputValue('')
  }

  const handleDeleteTag = index => {
    const newTags = [...tags]
    newTags.splice(index, 1)
    setTags(newTags)
  }

  const leaveForm = () => {
    setIsModified(false)
    setConfirmationAlertOpen(false)
    if (deal) setDealCopy(Object.assign(new Opportunity(), BLANK_DEAL, INITIAL_DEAL))
    close()
  }

  const handleFormLeaving = () => {
    if (isModified) {
      setConfirmationAlertOpen(true)
    } else {
      leaveForm()
    }
  }

  /**
   * Handles economic sector and deashare stage changes
   * @type {function(*, *): Promise<void>}
   */
  const handlePropertyChange = useCallback(
    async (property_type, selectedOption, actionMeta) => {
      let id

      if (actionMeta && actionMeta.action === 'create-option') {
        let newProperty
        if (property_type === INVESTOR_ID)
          newProperty = await DataStore.create('investors', {
            name: selectedOption.label
          })
        else
          newProperty = await DataStore.create('properties', {
            name: selectedOption.label,
            property_type: property_type
          })

        const newOption = { label: newProperty.name, value: newProperty.id }
        const optionState = propertyOptionStates[property_type]
        optionState.set(
          [...optionState.val, newOption].sort(
            (a, b) => !!a.label && a.label.localeCompare(b.label)
          )
        )
        id = newOption.value
      } else {
        id = selectedOption.value
      }

      if (error) setError(false)

      if (property_type === INVESTOR_ID) handleInputChange(property_type, id)
      else handleInputChange(`${property_type}_property_id`, id)
    },
    [propertyOptionStates, error]
  )

  const removeField = useCallback(
    fields => {
      const auxCopy = { ...dealCopy }
      let shouldSet = false
      fields.forEach(field => {
        if (dealCopy[field]) {
          shouldSet = true
          delete auxCopy[field]
        }
      })
      if (shouldSet) setDealCopy(Object.assign(new Opportunity(), auxCopy))
      return null
    },
    [dealCopy]
  )

  const searchValues = async query => {
    setIsLoading(true)
    try {
      let params = `?name=${query}`
      if (deal && deal.id) {
        params = params + `&opportunity_id=${deal.id}`
      }
      const response = await authenticatedFetch(`tags${params}`)
      const structuredData = response.map(item => {
        return {
          id: item,
          text: item.charAt(0).toUpperCase() + item.slice(1)
        }
      })
      setSearchResults(structuredData)
    } catch (error) {
      setSearchResults([])
      console.error('Error fetching search results:', error)
    }
    setIsLoading(false)
  }

  const handleTagsInputChange = value => {
    setInputValue(value)
  }

  useEffect(() => {
    DataStore.findAll('opportunities', {
      opportunity_type: Opportunity.TYPE_VINTAGE,
      slim: true
    }).then(res => {
      // this works, but the datastore doesn't get refreshed so subsequent deal forms have empty lists.
      // this is a problem in master branch as well.
      setVintageOpportunities(res.filter(o => o.associated_collection?.id === dealCopy.id))
    })
  }, [setVintageOpportunities])

  useEffect(() => {
    // Hard coded the limit value so that we can get all the investors.
    // This value may need to be updated in the future if there are more than the set value of investors
    DataStore.findAll('investors', { limit: DEFAULT_INVESTORS_LIMIT }).then(result => {
      setInvestorsOptions(result.map(r => ({ label: r.name, value: r.id })))
    })
  }, [setInvestorsOptions])

  useEffect(() => {
    setLoadingParents(true)
    DataStore.findAll('opportunities', {
      parents_only: true,
      order_by: 'name',
      order: 'asc',
      limit: 100,
      slim: true
    }).then(res => {
      const ps = res
        .map(o => {
          return { id: o.id, name: dealName(o) }
        })
        .filter(o => o.id !== dealCopy.id)
      setParents(ps)
      setLoadingParents(false)
    })
  }, [setParents])

  useEffect(() => {
    setSearchResults([])
    const delayDebounceFn = setTimeout(() => {
      if (inputValue.length > 2) {
        searchValues(inputValue)
      } else {
        setSearchResults([])
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [inputValue])

  useEffect(() => {
    let newArray = []
    if (deal && deal.tag_list && deal.tag_list.length > 0) {
      newArray = deal.tag_list.map(value => ({
        id: value,
        text: value.charAt(0).toUpperCase() + value.slice(1)
      }))
      setTags(newArray)
    }
  }, [deal])

  /**
   * Used to get the parent deal information and replace the dealCopy values.
   * @param dealId - ID to find the parent deal values.
   */
  const getParentDeal = async dealId => {
    if (dealId) {
      DataStore.clear()
      const parentDeal = await DataStore.find('opportunities', dealId)
      setDealCopy(prevState => {
        const copy = Object.assign(new Opportunity(), prevState)
        const newKeyPoints = parentDeal.key_points?.map(({ text_html, order }) => ({
          text_html,
          order
        }))
        copy.short_description_html = parentDeal.short_description_html
        copy.long_description_html = parentDeal.long_description_html
        copy.company_url = parentDeal.company_url
        copy.logo = parentDeal.logo
        copy.background_color = parentDeal.background_color
        copy.key_points = newKeyPoints
        copy.dealshare_economic_sector_property_id =
          parentDeal.dealshare_economic_sector_property_id
        return copy
      })
    }
  }

  /**
   * Update the dealCopy object when the form changes
   * TODO: I really doubt this is the easiest way to keep an object and form in sync.
   *
   * @param name - Name of the property to change
   * @param value - New value of the property
   */
  function handleInputChange(name, value) {
    setIsModified(true)

    if (name === 'short_description_html') {
      setShortCount(value.length)
      if (value.length >= SHORT_MAX) {
        return
      }
    }
    setDealCopy(prevState => {
      const copy = Object.assign(new Opportunity(), prevState)
      // replace '\n' with '<br>' if this is an html field
      if (name.endsWith('_html')) {
        copy[name] = value.replaceAll('\n', '<br/>')
      } else {
        if (name === 'opportunity_type') {
          copy.visible_on_mvp = false
          copy.visible_on_champion = false
        }
        copy[name] = value
      }
      // Update the values from the Parent Deal.
      // Only if a parent deal is selected *
      if (name === 'parent_id') {
        getParentDeal(copy.parent_id)
      }
      return copy
    })
    if (error) {
      setError(false)
    }
  }

  function logoPicked(fileObject) {
    // load the image as a "data url". It will then show up on the page. When we push, the server will be smart enough
    // to realize when the logo has changed, and will update it
    // This isn't the most efficient way to upload images.
    const reader = new FileReader()
    reader.onload = function (event) {
      setError(false)
      const dataUrl = event.target.result
      setDealCopy(prevState => {
        const newState = Object.assign(new Opportunity(), prevState)
        !newState.logo && (newState.logo = {})
        newState.logo.url = dataUrl
        newState.logo.name = fileObject.name
        return newState
      })
    }
    reader.readAsDataURL(fileObject)
  }

  function logoUrl(url) {
    // If it's a data url, just pass through
    // If it's a url from the server, prefix the API to the url
    if (!url) {
      return ''
    } else if (url.indexOf('data:') > -1) {
      return url
    } else {
      return configuration.apiBaseUrl + url
    }
  }

  function availableOnPlatform(opportunity_type) {
    if (opportunity_type === Opportunity.TYPE_VINTAGE) {
      return 'Available on MVP'
    }

    if (opportunity_type === Opportunity.TYPE_COLLECTION) {
      return 'Available on Champion'
    }

    return ''
  }

  return (
    dealCopy && (
      <form className='relative'>
        <h2 className='text-lg font-semibold'>
          {deal?.id ? 'Edit' : 'New'} {getDealFormTitle(deal.opportunity_type)}
        </h2>
        {false && (
          <Select
            label='Deal type'
            options={TYPE_OPTIONS}
            value={{ value: dealCopy.opportunity_type }}
            onChange={val => handleInputChange('opportunity_type', val.value)}
            tooltip={availableOnPlatform(dealCopy.opportunity_type)}
          />
        )}

        {/* ------------ section 0, dealshare only ------------ */}

        {dealCopy.isDealshare() && !dealCopy.is_parent && (
          <Select
            options={parents.map(o => {
              return { value: o.id, label: o.name }
            })}
            label='Parent Deal'
            placeholder='None'
            inputId='follow_on_deal'
            loading={loadingParents}
            value={{ value: dealCopy.parent_id }}
            onChange={val => handleInputChange('parent_id', val.value)}
          />
        )}

        {/* ------------ section 1, dynamic based on deal type ------------ */}

        <div className='grid grid-cols-2 gap-2'>
          <div className='colum1'>
            {!dealCopy.isVintage() && !dealCopy.isDealshare() ? (
              <Select
                options={vintageOpportunities.map(o => {
                  return { value: o.id, label: o.name }
                })}
                label={`${
                  dealCopy.isCollection() ? 'Champion’s Associated MVP Fund' : "Co-Invest Vintage's"
                }`}
                value={{ value: dealCopy.dealshare_vintage_id }}
                onChange={val => handleInputChange('dealshare_vintage_id', val.value)}
              />
            ) : (
              removeField(['dealshare_vintage_id'])
            )}

            <Input
              label='Company name'
              value={dealCopy.name || ''}
              onChange={val => handleInputChange('name', val)}
            />

            <Input
              label='Company website'
              value={dealCopy.company_url || ''}
              onChange={val => handleInputChange('company_url', val)}
            />

            {dealCopy.isDealshare() ? (
              <>
                <Label className='text-[#3A688D]'>Stage</Label>
                <CreatableSelect
                  value={stageOptions.find(
                    option => option.value === dealCopy.dealshare_stage_property_id
                  )}
                  options={stageOptions}
                  className='mb-2'
                  onChange={(selectedOption, actionMeta) =>
                    handlePropertyChange(Property.TYPE_DEALSHARE_STAGE, selectedOption, actionMeta)
                  }
                />
                <Select
                  label='Stage rollup'
                  options={stageRollupOptions}
                  onChange={(selectedOption, actionMeta) =>
                    handlePropertyChange(
                      Property.TYPE_DEALSHARE_STAGE_ROLLUP,
                      selectedOption,
                      actionMeta
                    )
                  }
                  value={{ value: dealCopy.dealshare_stage_rollup_property_id }}
                />

                <Label className='text-[#3A688D]'>Sector</Label>
                <CreatableSelect
                  value={economicSectorOptions.find(
                    option => option.value === dealCopy.dealshare_economic_sector_property_id
                  )}
                  options={economicSectorOptions}
                  className='mb-2'
                  onChange={(selectedOption, actionMeta) =>
                    handlePropertyChange(Property.TYPE_ECONOMIC_SECTOR, selectedOption, actionMeta)
                  }
                />
              </>
            ) : (
              removeField([
                'dealshare_economic_sector_property_id',
                'dealshare_stage_property_id',
                'dealshare_stage_rollup_property_id'
              ])
            )}

            {!dealCopy.isDealshare() ? (
              <CurrencyInput
                label={`${dealCopy.isVintage() ? 'MVP' : 'Champion'} Min Commitment`}
                value={dealCopy.minimum_commitment || ''}
                onChange={val => handleInputChange('minimum_commitment', val)}
              />
            ) : (
              removeField(['minimum_commitment'])
            )}

            {!dealCopy.isDealshare() ? (
              <CurrencyInput
                label={`${dealCopy.isVintage() ? 'MVP' : 'Champion'} Max Commitment`}
                value={dealCopy.maximum_commitment || ''}
                onChange={val => handleInputChange('maximum_commitment', val)}
              />
            ) : (
              removeField(['maximum_commitment'])
            )}

            {/* This field is the last on collection and dealshares but 4th on vintage, so, it's duplicated */}
            {dealCopy.isVintage() && (
              <DateTimePicker
                label='Closing date'
                value={(dealCopy.closes && new Date(dealCopy.closes)) || new Date()}
                onChange={val => handleInputChange('closes', val)}
              />
            )}
          </div>
          <div className='colum2'>
            <LogoAndColorPicker
              currentColor={dealCopy.background_color}
              currentLogoUrl={logoUrl(dealCopy.logo?.url)}
              onError={msg => setError(msg)}
              onColorChange={val => handleInputChange('background_color', val)}
              onLogoChange={logoPicked}
            />

            <Input
              inputId='schedule_call_url'
              label='Schedule call URL'
              value={dealCopy.schedule_call_url || ''}
              onChange={val => handleInputChange('schedule_call_url', val)}
            />

            {/* This field is the last on collection and dealshares but 4th on vintage, so, it's duplicated */}
            {!dealCopy.isVintage() && (
              <DateTimePicker
                label='Closing date'
                value={(dealCopy.closes && new Date(dealCopy.closes)) || new Date()}
                onChange={val => handleInputChange('closes', val)}
              />
            )}

            {dealCopy.isVintage() ? (
              <CurrencyInput
                label='Anticipated Fund Size'
                allowDecimals={true}
                value={dealCopy.vintage_anticipated_amount || undefined}
                onChange={val => handleInputChange('vintage_anticipated_amount', val)}
              />
            ) : (
              removeField(['vintage_anticipated_amount'])
            )}
          </div>
        </div>

        {/* ------------ section 2, specific to dealshare ------------ */}
        <div className='my-2'>
          {dealCopy.isDealshare() ? (
            <Checkbox
              className='mb-2'
              label='Fund Investors Only'
              toggle={() => {
                handleInputChange('vintage_investors_only', !dealCopy.vintage_investors_only)
                handleInputChange('collection_investors_only', !dealCopy.collection_investors_only)
              }}
              selected={dealCopy.vintage_investors_only}
            />
          ) : (
            removeField(['vintage_investors_only', 'collection_investors_only', 'mvp'])
          )}

          {dealCopy.isDealshare() ? (
            <>
              <div className='flex gap-2 w-full'>
                <CurrencyInput
                  label='MVP Min Commitment'
                  className='w-1/2'
                  value={dealCopy.minimum_vintage_commitment || ''}
                  onChange={val => handleInputChange('minimum_vintage_commitment', val)}
                />
                <CurrencyInput
                  label='MVP Max Commitment'
                  className='w-1/2'
                  value={dealCopy.maximum_vintage_commitment || ''}
                  onChange={val => handleInputChange('maximum_vintage_commitment', val)}
                />
              </div>
              <div className='flex gap-2 w-full'>
                <CurrencyInput
                  label='Champion Min Commitment'
                  className='w-1/2'
                  value={dealCopy.minimum_collection_commitment || ''}
                  onChange={val => handleInputChange('minimum_collection_commitment', val)}
                />
                <CurrencyInput
                  label='Champion Min Commitment'
                  className='w-1/2'
                  value={dealCopy.maximum_collection_commitment || ''}
                  onChange={val => handleInputChange('maximum_collection_commitment', val)}
                />
              </div>
            </>
          ) : (
            removeField([
              'minimum_vintage_commitment',
              'maximum_vintage_commitment',
              'champion',
              'minimum_collection_commitment',
              'maximum_collection_commitment'
            ])
          )}

          {removeField(['collection_anticipated_amount'])}
        </div>

        {/* ------------ section 3, shared for all deal types ------------ */}

        <div className='TagsField relative'>
          <ReactTags
            autofocus={false}
            tags={tags}
            handleAddition={handleAddTag}
            handleDelete={handleDeleteTag}
            handleInputChange={handleTagsInputChange}
            allowDragDrop={false}
            inputValue={inputValue}
            classNames={{
              tag: 'form-label form-margin-bottom input-border-color inline-block rounded-md pl-2 pr-1 mr-2',
              tagInputField: 'base-input-field flex items-center h-12 p-2',
              remove: 'form-label rounded-full px-1'
            }}
          />
          {isLoading ? (
            <ul className='form-input Tagsuggestions absolute z-50 max-w-[240px] top-20 left-1 mb-4 overflow-hidden shadow-xl'>
              <li key={0}>Loading...</li>
            </ul>
          ) : (
            searchResults?.length > 0 && (
              <ul className='form-input Tagsuggestions absolute z-50 max-w-[240px] top-20 left-1 mb-4 overflow-hidden shadow-xl'>
                {searchResults.map(result => (
                  <li
                    className='h-8 hover:bg-gray-100 hover:cursor-pointer py-1 px-4 border-b border-gray-400'
                    key={result.id}
                    onClick={() => handleAddTag(result)}
                  >
                    {result.text}
                  </li>
                ))}
              </ul>
            )
          )}
        </div>

        <Textarea
          inputId='short_description'
          label={`Short description (${shortCount} / ${SHORT_MAX})`}
          value={
            dealCopy.short_description_html
              ? dealCopy.short_description_html.replaceAll('<br/>', '\n')
              : ''
          }
          onChange={val => handleInputChange('short_description_html', val)}
        />

        <Textarea
          label='Long description'
          size='xl'
          value={
            dealCopy.long_description_html
              ? dealCopy.long_description_html.replaceAll('<br/>', '\n')
              : ''
          }
          onChange={val => handleInputChange('long_description_html', val)}
        />

        {/* ------------ section 3.1, only for co-invest ------------ */}

        {dealCopy.isDealshare() ? (
          <>
            <Label className='text-[#3A688D]'>Investors</Label>
            <CreatableSelect
              value={investorsOptions.find(option => option.value === dealCopy.investor_id)}
              options={investorsOptions}
              className='mb-2'
              onChange={(selectedOption, actionMeta) =>
                handlePropertyChange(INVESTOR_ID, selectedOption, actionMeta)
              }
            />
          </>
        ) : (
          removeField([INVESTOR_ID])
        )}

        {/* ------------ section 4, form actions and error handling ------------ */}
        {error && (
          <div
            className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-2'
            role='alert'
          >
            {error}
          </div>
        )}

        <SavingProgress
          saving={saving}
          success={successSaving}
          close={closeAfterSuccess}
          percentage={uploadProgress}
          // Pass in the percentage here and the bar should update.
          // If you don't pass a percentage it will use the spinner.
        />
        <div className='FormFooter flex gap-2'>
          <Button type='button' className='w-1/4' onClick={handleFormLeaving}>
            Cancel
          </Button>
          <Button variant='success' type='button' className='w-1/4' onClick={() => handleSave()}>
            Save
          </Button>
        </div>
        <ConfirmationAlert
          open={confirmationAlertOpen}
          toggleOpen={isOpen => setConfirmationAlertOpen(isOpen)}
          onCancel={() => setConfirmationAlertOpen(false)}
          onConfirm={leaveForm}
        />
      </form>
    )
  )
}
