import { useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import z from 'zod'
import { Plus } from 'lucide-react'
import { Form, FormControl, FormField, FormItem, FormMessage } from 'components/ui/Form'
import { Input } from 'components/ui/Input'
import { LoadingButton } from 'components/ui/LoadingButton'
import { useInviteMemberToOrganizationMutate } from 'hooks/api/useMutation.hooks'
import { Dialog, DialogContent, DialogFooter, DialogHeader } from 'components/ui/Dialog'
import { DialogTrigger } from '@radix-ui/react-dialog'
import { Button } from 'components/ui/Button'
import { useToast } from 'hooks/userToast.hooks'
import { UserInfo } from 'types/api-types'

interface Props {
  currentUser: UserInfo
}

const formSchema = z.object({
  email: z.string().email({ message: 'Must be a valid email format' })
})
type FormSchema = z.infer<typeof formSchema>

export function AddNewMember({ currentUser }: Props) {
  const { toast } = useToast()

  const { mutate, isLoading } = useInviteMemberToOrganizationMutate()

  const [open, setOpen] = useState<boolean>(false)

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: ''
    }
  })

  const onSubmitHandler: SubmitHandler<FormSchema> = formValue => {
    if (!currentUser) return
    const { email } = formValue
    const data = { id: currentUser.organization.id, email }

    mutate(data, {
      onSuccess: () => {
        form.reset()
        toggleDialogOpen(false)
        toast({
          variant: 'success',
          description: `${email} has been invited to the organization`,
          duration: 2000
        })
      },
      onError: error => {
        toast({
          variant: 'destructive',
          description: error.message ?? 'Something went wrong',
          duration: 2000
        })
      }
    })
  }

  const toggleDialogOpen = (status: boolean) => {
    form.reset()
    setOpen(status)
  }

  return (
    <Dialog open={open} onOpenChange={toggleDialogOpen}>
      <DialogTrigger asChild>
        <Button variant='outline' size='sm'>
          <Plus className='w-3 h-3' />
          <span className='ml-1'>Add member</span>
        </Button>
      </DialogTrigger>

      <DialogContent className='max-w-3xl rounded-md'>
        <DialogHeader>
          <h2 className='m-0 text-2xl font-medium'>
            Invite member into {currentUser?.organization.name}
            {currentUser?.organization.name === 'Modern Venture Partner' && (
              <i className='ml-1 text-gray-500 text-sm'>
                (All members of this organization will be admins)
              </i>
            )}
          </h2>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmitHandler)} className='mt-4'>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormControl>
                      <Input placeholder='invite@mvp-vc.com' disabled={isLoading} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            />

            <p className='m-0 mt-2 text-sm text-gray-500'>
              <i>
                Invited members have full administrative access but cannot add or remove members.
              </i>
            </p>

            <DialogFooter className='flex items-center sm:justify-start flex-row mt-6'>
              <Button
                type='button'
                className='flex-1 max-w-[262px]'
                onClick={() => toggleDialogOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                className='ml-2 flex-1 max-w-[262px] bg-[#52B749]'
                loading={isLoading}
              >
                Invite
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
