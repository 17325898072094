import type { ComponentPropsWithoutRef } from 'react'

export function ChampionIcon({
  width = '40',
  height = '40',
  className
}: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 800 800'
    >
      <defs>
        <clipPath id='clip-path'>
          <path
            style={{ fill: 'none' }}
            d='M578.43,585.1a250.22,250.22,0,0,0,76.4-133.42l-111.23.79A146.37,146.37,0,0,1,503,510.39Z'
          />
        </clipPath>
        <clipPath id='clip-path-2'>
          <path
            className='cls-1'
            d='M296.31,302a148.29,148.29,0,0,1,109.35-47.85c62.8,0,116.47,38.62,138.09,92.95H655.08C630.26,234,527.92,149,405.66,149a256.23,256.23,0,0,0-184.29,77.67Z'
          />
        </clipPath>
      </defs>

      <rect style={{ fill: '#06283c' }} x='-0.02' y='-0.01' width='800' height='800' />
      <polygon
        style={{ fill: '#FFFFFF' }}
        points='144.62 448.23 144.62 651 346.34 651 144.62 448.23'
      />
      <rect style={{ fill: '#50b4b1' }} x='390.3' y='509.86' width='2.77' />
      <path
        style={{ fill: '#FFFFFF' }}
        d='M578.43,585.1a250.22,250.22,0,0,0,76.4-133.42l-111.23.79A146.37,146.37,0,0,1,503,510.39Z'
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d='M296.31,302a148.29,148.29,0,0,1,109.35-47.85c62.8,0,116.47,38.62,138.09,92.95H655.08C630.26,234,527.92,149,405.66,149a256.23,256.23,0,0,0-184.29,77.67Z'
      />
      <polygon
        style={{ fill: '#FFFFFF' }}
        points='144.62 354.67 439.42 651 643.53 651 144.62 149.5 144.62 354.67'
      />
      <g style={{ clipPath: 'url(#clip-path)' }}>
        <polygon
          style={{ fill: '#cececd' }}
          points='589.99 597.57 491.3 500.31 513.78 477.58 615.26 570.12 589.99 597.57'
        />
      </g>
      <g style={{ clipPath: 'url(#clip-path-2)' }}>
        <rect
          style={{ fill: '#cececd' }}
          x='384.11'
          y='-49.57'
          width='35.64'
          height='873.8'
          transform='translate(-156.18 396.21) rotate(-44.85)'
        />
      </g>
    </svg>
  )
}
