import { useManageDetailsDialog } from 'contexts/ManageDetailsDialog.context'
import { EditOrganizationPopover } from './EditOrganizationPopover'

export function EditOrganization() {
  const { state } = useManageDetailsDialog()

  return (
    <div className='border border-[#e3e3e3] rounded-lg p-4'>
      <div>
        <div className='flex items-center justify-between'>
          <h3 className='m-0 font-semibold text-lg md:text-xl montserrat normal-case'>
            Organization
          </h3>

          <EditOrganizationPopover />
        </div>
        <p className='m-0 mt-4 montserrat break-all text-base'>{state.user?.organization.name}</p>
      </div>
    </div>
  )
}
