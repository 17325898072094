import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { dollarFormat, dollarShortener } from 'constants/DollarsFormat'
import FeatherIcon from 'feather-icons-react'
import { rgba } from 'polished'
import useQuery from 'services/useQuery'
import { style } from 'themes/styles'
import ReactHtmlParser from 'react-html-parser'
import Button from 'components/core/Button'
import Modal from 'components/core/Modal'
import Toast from 'components/core/Toast'
import ScrollContainer from 'components/core/ScrollContainer'
import CommitmentsForm from 'components/CommitmentsForm'
import ShareDealForm from 'components/ShareDealForm'
import DataRoom from 'components/DataRoom'
import CustomContainer from 'components/core/CustomContainer'
import MediaItem from 'components/MediaItem'
import { isDarkColor, dataTypes, dealName, cn } from 'services/utils'
import { DataStore } from '../../services/DataStore'
import {
  isAdmin,
  PLATFORMS,
  userInfo,
  currentPlatform,
  authenticatedFetch
} from 'services/authentication'
import { OPPORTUNITY_TYPES } from 'services/opportunities'
import moment from 'moment'
import ReactImageVideoLightbox from 'react-image-video-lightbox'
import { useMediaQuery } from 'react-responsive'
import { Label } from '../../constants/StyleComponents'
import { formatCloseDate } from '../../services/TimeUtils'
import SavingProgress from '../../components/SavingProgress'
import { Mixpanel } from 'services/mixpanel'
import HeaderNew from 'components/core/HeaderNew'
import configuration from 'services/config'
import GlobalLayout from 'components/core/GlobalLayout'

// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const Media = style.StyledComponent.div``

const Commitment = style.StyledComponent.div`
  background: ${rgba(style.vars.colors.success, 0.3)};
  border: 1px solid ${style.vars.colors.success};
  padding: ${style.vars.grid}px ${style.vars.grid * 2}px;
  margin-bottom: ${style.vars.grid * 2}px;
  color: ${style.vars.colors.success};
  ${style.vars.borderRadius};
  ${style.vars.flexRow};
`

const CommitmentMessage = style.StyledComponent(Label)`
  margin: 0 ${style.vars.grid}px 0 0;
  color: ${style.vars.colors.text};
  flex: 1;
`

const CustomLabel = style.StyledComponent.label`
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
`

const EditCommitment = style.StyledComponent.div`
  cursor: pointer;
`

const DataRoomLink = style.StyledComponent.div`
  ${style.vars.flexRow};
  align-items: flex-start;
  width: auto;
  cursor: pointer;
  padding: ${style.vars.grid}px 0;
`

const DataRoomIcon = style.StyledComponent.div`
  margin-right: ${style.vars.grid * 1.5}px;
  height: 32px;
  flex: 0 0 32px;
  border-radius: 4px;
  ${style.vars.flexCenter};
`

const VideoPlayer = style.StyledComponent.div`
  width: 100vw;
  position: absolute;
  top: 0;
  height: 100vh;
  z-index: 10000;

  div:first-child{
    background-color: rgb(42 42 42 / 89%) !important;
  }
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

function DropdownTitle({ className, label, isShow, handleClick }) {
  return (
    <div className={cn('flex items-center gap-2 text-white cursor-pointer', className)}>
      <label className='text-sm font-sans'>{label}</label>
      <FeatherIcon
        icon={isShow ? 'arrow-down-circle' : 'arrow-up-circle'}
        size={22}
        color={style.color.championGreen}
        onClick={handleClick}
      />
    </div>
  )
}
function DropdownLink({ className, label, url }) {
  return (
    <a
      href={url}
      target='_blank'
      rel='noreferrer'
      className={cn('flex items-center gap-2 text-white', className)}
    >
      <label className='text-sm font-sans'>{label}</label>
      <FeatherIcon icon='globe' size={22} color={style.color.championGreen} />
    </a>
  )
}

export default function Opportunities({ history }) {
  const [isScrolled, setIsScrolled] = useState(false)
  const [deal, setDeal] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [minimum, setMinimum] = useState(null)
  const [maximum, setMaximum] = useState(null)
  const [showCommitmentForm, setShowCommitmentForm] = useState(false)
  const [showShareForm, setShowShareForm] = useState(false)
  const [showDataRoom, setShowDataRoom] = useState(false)
  const [user, setUser] = useState(null)
  const [currentUserCommitment, setCurrentUserCommitment] = useState(null)
  const [userAllowedToInvest, setUserAllowedToInvest] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [dueDiligenceResources, setDueDiligenceResources] = useState([])
  const [companyUpdatesResources, setCompanyUpdatesResources] = useState([])
  const [dataRoomResources, setDataRoomResources] = useState([])
  const [videoResources, setVideoResources] = useState([])
  const [investorLogo, setInvestorLogo] = useState(null)
  const [displayAlert, setDisplayAlert] = useState(false)
  const [videoPopup, setVideoPopup] = useState(false)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 769 })
  const [dropdown, setDropdown] = useState('')
  const [goToTop, setGoToTop] = useState(false)
  const platform = currentPlatform()
  const { id } = useParams()
  const query = useQuery()

  const isClosed = useMemo(() => deal && deal.closed, [deal])
  const darkBg = useMemo(() => deal && isDarkColor(deal.background_color || '#FFFFFF'), [deal])
  const fundYear = deal?.fund_years.at(-1)
  const portfolioUrl =
    deal?.opportunity_type === OPPORTUNITY_TYPES.VINTAGE
      ? `https://www.mvp-vc.com/portfolio-categories/${fundYear}`
      : `/companies?year=${fundYear}`

  useEffect(() => {
    if (deal) {
      setDueDiligenceResources(
        deal.links
          .filter(link => {
            // TODO: filter links on server
            return (
              link.group === 'due_diligence' &&
              ((platform === PLATFORMS.CHAMPION && link.visible_on_champion) ||
                (platform === PLATFORMS.MVP && link.visible_on_mvp))
            )
          })
          .sort((a, b) => a.order - b.order)
      )
      setDataRoomResources(
        deal.links
          .filter(link => {
            // TODO: filter links on server
            return (
              link.group === 'data_room' &&
              ((platform === PLATFORMS.CHAMPION && link.visible_on_champion) ||
                (platform === PLATFORMS.MVP && link.visible_on_mvp))
            )
          })
          .sort((a, b) => a.order - b.order)
      )
      setVideoResources(
        deal.links
          .filter(link => {
            return (
              link.group === 'video_link' &&
              ((platform === PLATFORMS.CHAMPION && link.visible_on_champion) ||
                (platform === PLATFORMS.MVP && link.visible_on_mvp))
            )
          })
          .sort((a, b) => a.order - b.order)
          .map(link => {
            return {
              ...link,
              type: 'video',
              title: link.label_html,
              altTag: 'video'
            }
          })
      )
      setCompanyUpdatesResources(
        deal.links
          .filter(link => {
            return (
              link.group === dataTypes.company_updates &&
              ((platform === PLATFORMS.CHAMPION && link.visible_on_champion) ||
                (platform === PLATFORMS.MVP && link.visible_on_mvp))
            )
          })
          .sort((a, b) => a.order - b.order)
          .map(link => {
            return {
              ...link,
              type: 'company_update',
              title: link.label_html
            }
          })
      )
      Mixpanel.track(`Deal View`, {
        opportunity_id: deal.id,
        opportunity_name: deal.name,
        opportunity_status: deal.status,
        opportunity_type: deal.opportunity_type
      })
      setInvestorLogo(deal.logo.url)
    }
    // TODO: initialization of other values that depend on `deal` should happen here, not randomly everywhere else and sometimes on every digest
  }, [deal, platform])

  useEffect(() => {
    userInfo()
      .then(u => {
        setUser(u)
      })
      .catch(e => {
        console.error('could not get user', e)
      })
  }, [setUser])

  useEffect(() => {
    query.get('showCommitment') && setShowCommitmentForm(true)
    ;(async () => {
      try {
        const opp = await authenticatedFetch(`opportunities/${id}`)
        opp && setDeal(opp)
        if (opp?.opportunity_type === 'DealShare') {
          if (platform === PLATFORMS.CHAMPION) {
            setMinimum(opp.minimum_collection_commitment)
            setMaximum(opp.maximum_collection_commitment)
          } else {
            setMinimum(opp.minimum_vintage_commitment)
            setMaximum(opp.maximum_vintage_commitment)
          }
        } else {
          setMinimum(opp.minimum_commitment)
          setMaximum(opp.maximum_commitment)
        }
      } catch (error) {
        history.push('/404')
      }
    })()
  }, [id, setDeal, setShowCommitmentForm])

  useEffect(() => {
    if (!!deal && !!user && !isClosed) {
      let canInvest
      const commitmentIds = user.commitments.map(c => c.opportunity_id)
      if (platform === PLATFORMS.CHAMPION) {
        const collectionIds = deal?.funds?.map(f => f.fund_collection_id)
        canInvest =
          deal?.opportunity_type === OPPORTUNITY_TYPES.COLLECTION ||
          (deal?.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE &&
            !deal.collection_investors_only) ||
          (deal?.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE &&
            deal.collection_investors_only &&
            commitmentIds.filter(e => collectionIds.includes(e)).length > 0)
      } else {
        canInvest =
          deal?.opportunity_type === OPPORTUNITY_TYPES.VINTAGE ||
          (deal?.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE &&
            !deal.vintage_investors_only) ||
          (deal?.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE &&
            deal.vintage_investors_only &&
            deal.funds?.filter(e => commitmentIds.includes(e.fund_id)).length > 0)
      }
      setUserAllowedToInvest(canInvest)
      setShowMessage(!canInvest)
    }
  }, [deal, user, history])

  useEffect(() => {
    if (deal && !isAdmin()) {
      DataStore.clear()
      DataStore.findAll('commitments', { opportunity_id: deal.id }).then(commitments => {
        if (commitments && commitments.length > 0) {
          const amount_req = commitments.reduce((a, c) => a + Number(c.amount_requested || 0), 0)
          const amount_tot = commitments.reduce((a, c) => a + Number(c.final_amount_total || 0), 0)
          const c = { amount_requested: amount_req, final_amount_total: amount_tot }

          setCurrentUserCommitment(c)
        } else {
          setCurrentUserCommitment(false)
        }
      })
    }
  }, [deal])

  const headerAction = {
    label: 'back',
    icon: 'arrow-left',
    action: () => history.push('/opportunities')
  }

  function CommitmentInfo({ commitment, showForm }) {
    async function openCommitmentForm() {
      if (isAdmin()) {
        setDisplayAlert(true)
        return
      }
      showForm(true)
    }

    if (commitment?.final_amount_total) {
      return (
        <Commitment>
          You have invested {dollarFormat(commitment.final_amount_total)} in this deal.
        </Commitment>
      )
    } else if (commitment && user && !user.is_admin) {
      const amount = commitment?.amount_requested || commitment[0]?.amount_requested

      return (
        <Commitment>
          <CommitmentMessage>
            You have committed {dollarFormat(amount)} to this deal.
          </CommitmentMessage>
          <EditCommitment onClick={() => showForm(true)}>Edit</EditCommitment>
        </Commitment>
      )
    } else {
      return (
        <Button
          action={userAllowedToInvest && openCommitmentForm}
          inactive={!userAllowedToInvest || isClosed || user.is_admin}
          label='Invest'
          marginBottom
          className={`commitment-btn${
            !userAllowedToInvest || isClosed || user.is_admin ? '--inactive' : ''
          }`}
        />
      )
    }
  }

  function ActionsGroup({ deal, showShare }) {
    return (
      <div className='commitment-actions'>
        <div>
          <CommitmentInfo
            deal={deal}
            commitment={currentUserCommitment}
            showForm={setShowCommitmentForm}
          />
          {deal.schedule_call_url && (
            <Button
              action={() => window.open(deal.schedule_call_url)}
              className='opportunity-button'
              label='Schedule a Call'
              marginBottom
            />
          )}
        </div>
      </div>
    )
  }

  const updateLocalCommitment = commitment => {
    if (!isAdmin() && !Number.isInteger(commitment)) {
      setCurrentUserCommitment(commitment)
    } else {
      setCurrentUserCommitment(commitment)
    }
  }

  const onToggleDropdown = value => () => {
    setDropdown(dropdown === value ? '' : value)
  }

  // Extracted to use it in two places.
  const GeneralInfoSection = () => {
    return (
      <CustomContainer
        className='opportunity-design__actions !flex flex-col lg:!px-5'
        height='unset'
        width='100%'
      >
        <div>
          <ActionsGroup
            commitment={currentUserCommitment}
            showForm={setShowCommitmentForm}
            showShare={setShowShareForm}
            deal={deal}
          />
        </div>
        <div className='informative'>
          {deal.closes && moment(deal.closes).isValid() && (
            <CustomLabel>
              {isClosed ? 'Initial Deal Date' : 'Closing Date'}: &nbsp;
              {isClosed ? (
                <>
                  {deal?.deal_dates?.map((item, index) => {
                    return (
                      <p key={index}>
                        <span>
                          {item.stage}: <b>{formatCloseDate(item.closes)}</b>
                        </span>
                      </p>
                    )
                  })}
                </>
              ) : (
                <b>{formatCloseDate(deal.closes)}</b>
              )}
            </CustomLabel>
          )}
        </div>
        {minimum !== null && moment(deal.closes).isAfter() ? (
          <>
            <div className='informative'>
              <CustomLabel>
                Requirement:&nbsp;
                {maximum ? (
                  <b>
                    &nbsp;{dollarShortener(minimum)} - {dollarShortener(maximum)}
                  </b>
                ) : (
                  <b>&nbsp{dollarShortener(minimum)}+</b>
                )}
              </CustomLabel>
            </div>
            <div className='informative' onClick={() => setShowShareForm(true)}>
              <CustomLabel className='cursor-pointer'>Share this deal&nbsp;</CustomLabel>
              <FeatherIcon
                className='website-icon quicklook-button inline-block cursor-pointer'
                icon='share'
                size={22}
                color={style.color.championGreen}
              />
            </div>
          </>
        ) : (
          <div className='informative'>
            <p onClick={() => setShowShareForm(true)} className='flex'>
              <CustomLabel>Share this deal </CustomLabel>
              <FeatherIcon
                className='website-icon quicklook-button inline-block self-center'
                icon='share'
                size={22}
                color={style.color.championGreen}
              />
            </p>
          </div>
        )}
      </CustomContainer>
    )
  }

  const InvestButtonSection = () => {
    return <GeneralInfoSection />
  }

  const LeftSidebar = () => {
    return (
      <div className='space-y-6'>
        <div>
          <DropdownTitle
            label='QUICK LOOK'
            isShow={dropdown === 'quicklook'}
            handleClick={onToggleDropdown('quicklook')}
          />
          {dropdown === 'quicklook' && (
            <ul className='space-y-3 mt-4'>
              {deal?.key_points
                ?.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
                .map((item, index) => {
                  return (
                    <li key={index} className='font-sans text-clamp-0.875'>
                      {ReactHtmlParser(item.text_html)}
                    </li>
                  )
                })}
            </ul>
          )}
        </div>
        <div>
          <DropdownTitle
            label={isClosed ? 'RESOURCES' : 'DUE DILIGENCE MATERIALS'}
            isShow={dropdown === 'resources'}
            handleClick={onToggleDropdown('resources')}
          />
          {dropdown === 'resources' && (
            <div className='mt-4'>
              {dueDiligenceResources.map((item, index) => {
                return (
                  <MediaItem
                    className='material-item'
                    key={index}
                    item={item}
                    opportunity={deal}
                    history={history}
                  />
                )
              })}
              {dataRoomResources?.length > 0 && (
                <DataRoomLink className='material-item' onClick={() => setShowDataRoom(true)}>
                  <DataRoomIcon>
                    <FeatherIcon icon='folder' size={22} color={style.color.championGreen} />
                  </DataRoomIcon>
                  <span>Data Room</span>
                </DataRoomLink>
              )}
            </div>
          )}
        </div>
        {videoResources && !!videoResources.length && (
          <div>
            <DropdownTitle
              label='VIDEOS'
              isShow={dropdown === 'videos'}
              handleClick={onToggleDropdown('videos')}
            />
            {dropdown === 'videos' && (
              <div className='mt-4'>
                {videoResources.map((item, index) => {
                  return (
                    <MediaItem
                      className='material-item'
                      key={index}
                      item={item}
                      opportunity={deal}
                      history={history}
                      customClickAction={() => setVideoPopup(true)}
                    />
                  )
                })}
              </div>
            )}
          </div>
        )}

        <div>
          <DropdownTitle
            label='COMPANY UPDATES'
            isShow={dropdown === 'updates'}
            handleClick={onToggleDropdown('updates')}
          />
          {dropdown === 'updates' && (
            <div className='mt-4'>
              {companyUpdatesResources.map((item, index) => {
                return (
                  <MediaItem
                    className='material-item'
                    key={index}
                    item={item}
                    opportunity={deal}
                    history={history}
                    onClick={() => window.open(item.url, '_blank')}
                  />
                )
              })}
            </div>
          )}
        </div>
        {portfolioUrl && deal?.opportunity_type !== OPPORTUNITY_TYPES.DEAL_SHARE && (
          <DropdownLink label='VIEW PORTFOLIO' url={portfolioUrl} />
        )}
        {deal.company_url && <DropdownLink label='OPEN WEBSITE' url={deal.company_url} />}
      </div>
    )
  }

  return (
    <>
      {displayAlert && (
        <Toast message="Admins can't make commitments" close={() => setDisplayAlert(false)} />
      )}

      <ScrollContainer
        hasHeader
        isScrolled={isScrolled}
        setIsScrolled={setIsScrolled}
        goToTop={goToTop}
        setGoToTop={setGoToTop}
      >
        <HeaderNew />

        {errorMessage && <p className='text-center mt-20 text-[#CAA535]'>{errorMessage}</p>}

        {deal && !errorMessage && (
          <>
            <GlobalLayout bgColor={'#10273A'}>
              <div className='flex flex-col lg:flex-row text-white relative'>
                <div className='absolute top-0 left-0 w-full h-6 bg-[#10273A]'></div>
                <div className='lg:w-9/12 lg:border lg:border-[#CCD5E2] lg:border-l-0 lg:border-y-0 lg:border-r-1'>
                  <div className='flex flex-col md:flex-row items-center md:items-stretch pb-5 lg:border lg:border-[#CCD5E2] lg:border-b-1 lg:border-t-0 lg:border-x-0'>
                    <div
                      className='max-w-[320px] md:max-w-[200px] flex justify-center py-1 z-10 md:max-h-[60px]'
                      style={{ backgroundColor: deal.background_color }}
                    >
                      <img
                        src={`${configuration.apiBaseUrl}${investorLogo}`}
                        alt={deal.name}
                        className='max-w-[100%] max-h-[100%] object-fill'
                      />
                    </div>

                    <div className='z-10 mt-5 md:mt-0 md:ml-5'>
                      <div className='text-3xl font-medium'>
                        {isClosed ? deal.name : dealName(deal)}
                      </div>
                      {isClosed ? (
                        <span>{deal?.investor_list && `Alongside: ${deal.investor_list}`}</span>
                      ) : (
                        <span>{deal?.investor?.name && `Alongside: ${deal.investor.name}`}</span>
                      )}
                    </div>
                  </div>
                  <div className='lg:hidden'>
                    <InvestButtonSection />
                  </div>
                  <div className='flex lg:flex-row pt-5'>
                    <div className='lg:w-3/12 hidden lg:flex lg:flex-col'>
                      <LeftSidebar />
                    </div>
                    <div className='lg:w-9/12 lg:px-5 [&_iframe]:max-w-full'>
                      <p className='text-white'>{ReactHtmlParser(deal.long_description_html)}</p>
                    </div>
                  </div>
                </div>
                <div className='lg:w-3/12 hidden lg:flex'>
                  <InvestButtonSection />
                </div>
                <div className='flex flex-col mt-5 lg:hidden'>
                  <LeftSidebar />
                </div>
              </div>
            </GlobalLayout>

            {showCommitmentForm && (
              <Modal
                show={showCommitmentForm}
                noPadding
                shadeDoesNotClose={true}
                close={() => setShowCommitmentForm(false)}
              >
                <CommitmentsForm
                  opportunityId={deal.id}
                  editedCommitment={currentUserCommitment}
                  setCommitment={setCurrentUserCommitment}
                  userId={user && user.id}
                  afterSuccess={c => updateLocalCommitment(c)}
                  close={() => setShowCommitmentForm(false)}
                />
              </Modal>
            )}

            <Modal show={showShareForm} close={() => setShowShareForm(false)}>
              <ShareDealForm deal={deal} close={() => setShowShareForm(false)} />
            </Modal>

            <Modal small show={showDataRoom} close={() => setShowDataRoom(false)}>
              <DataRoom
                items={dataRoomResources}
                close={() => setShowDataRoom(false)}
                deal={deal}
                history={history}
                isClosed={isClosed}
              />
            </Modal>

            {videoPopup && (
              <VideoPlayer className='video-popup'>
                <ReactImageVideoLightbox
                  data={videoResources}
                  startIndex={0}
                  onCloseCallback={() => setVideoPopup(false)}
                />
              </VideoPlayer>
            )}

            <SavingProgress
              saving={showMessage}
              success={showMessage}
              close={() => setShowMessage(false)}
              message={`To make a commitment in this opportunity a fund investment is needed `}
            />
          </>
        )}
      </ScrollContainer>
    </>
  )
}
