import React, { useRef } from 'react'
import CurrencyInput from 'react-currency-input-field'
import Button from '../Button'
import { style } from '../../../themes/styles'

// STYLE ##########################################################

const Container = style.StyledComponent.div`
  width: 100%;
  padding: ${style.vars.grid}px ${style.vars.grid * 2}px;
  border: 1px solid;
  border-color: ${'transparent'};
  background: ${style.vars.colors.cellSubtle};
  margin-bottom: ${style.vars.grid * 2}px;
  ${style.vars.box};
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  ${style.vars.borderRadius};
`

const InputButton = style.StyledComponent(Button)`
  bottom: 10px;
  position: relative;
  right: 10px;
  width: 12%;
`

const InputRow = style.StyledComponent.div`
  ${style.vars.flexRow};
`

const Label = style.StyledComponent.div`
  ${style.vars.label};
  color: ${style.vars.colors.subtleText};
  text-transform: ${props => (props.label.includes('@') ? 'none' : 'capitalize')};
  width: 100%;
`

const Input = style.StyledComponent(CurrencyInput)`
  width: 100%;
  height: 30px;
  outline: none;
  border: none;
  background: none;
  caret-color: ${props => (props.disabled ? 'transparent' : 'black')};
  padding: 0;
  ${style.vars.bodyText};
  &:-webkit-autofill,
  &:-internal-autofill-selected {
    -webkit-text-fill-color: ${style.vars.colors.text};
    -webkit-box-shadow: 0 0 0 1000px ${style.vars.colors.cellSubtle} inset;
  }
`

// COMPONENT ##########################################################

/**
 *
 * @param {function} onChange - Called with new value when value changes
 * @param {string} label
 * @param className
 * @param {number|undefined} value - The value. Make `undefined` for no value
 * @param {string} note - Informational text to be shown in input box. Optional.
 * @param {boolean} allowDecimals - Whether to allow decimals. Defaults to true
 * @param {boolean} disabled - If true, lightens background and disables Input box input. Defaults to false
 * @param {string} buttonLabel - Label to display into the input button and if will be displayed or not.
 * @param {function} buttonAction - Function to call when the button is clicked
 * @returns {JSX.Element}
 * @constructor
 */
export default function CurrencyInputComponent({
  onChange,
  label,
  className,
  value,
  note,
  allowDecimals = true,
  maxDecimals = 2,
  disabled = false,
  buttonLabel,
  buttonAction
}) {
  const handleInputChange = newValue => {
    if (!onChange || value === Number(newValue)) return

    onChange(newValue)
  }

  const inputField = useRef(null)

  return (
    <Container
      fee={note}
      disabled={disabled}
      className={className}
      onClick={() => inputField.current.focus()}
    >
      {label && <Label label={label}>{label}</Label>}
      <InputRow>
        <Input
          ref={inputField}
          precision='0'
          prefix='$'
          allowDecimals={allowDecimals}
          decimalScale={allowDecimals ? maxDecimals : 0}
          decimalsLimit={allowDecimals ? maxDecimals : 0}
          disabled={disabled}
          value={value}
          onValueChange={handleInputChange}
        />
        {buttonLabel && !disabled && (
          <InputButton label={buttonLabel} action={() => buttonAction()} />
        )}
        {note && <Label>{note}</Label>}
      </InputRow>
    </Container>
  )
}
