import type { UserInfo } from 'types/api-types'
import { Link } from 'react-router-dom'
import { RankingLogo } from 'components/core/logos/Ranking'
import { Hubspot } from 'components/core/logos/Hubspot'
import { EditUserDetailsPopover } from './EditUserDetailsPopover'
import { EditStatus } from './EditStatus'
import { links } from 'services/links'

interface Props {
  member: UserInfo | undefined
  isClientDetail?: boolean
}

export function EditUser({ member, isClientDetail }: Props) {
  const isMVPAdmin = member?.is_admin

  return (
    <div className='border border-[#e3e3e3] rounded-lg p-4'>
      <hgroup>
        <div className='flex items-center justify-between'>
          <div className='flex xs:items-center flex-col xs:flex-row'>
            <h2 className='m-0 font-semibold text-lg md:text-2xl montserrat'>{member?.name}</h2>
            {!isMVPAdmin && (
              <div className='flex space-x-4 xs:ml-4'>
                <Link
                  to={`/admin/clients/${member?.id}/portfolio`}
                  className='inline-flex items-center text-base font-medium montserrat xs:space-x-2'
                >
                  <span className='hidden md:inline'>Portfolio</span>
                  <RankingLogo className='mb-2 md:mb-2.5' />
                </Link>
                {!!member?.hubspot_id && (
                  <a
                    target='__blank'
                    href={links.hubspot.contact(member.hubspot_id)}
                    className='inline-flex items-center text-[#FF7859] text-base font-medium montserrat xs:space-x-2'
                  >
                    <span className='hidden md:inline'>Hubspot</span>
                    <Hubspot className='md:mb-1' />
                  </a>
                )}
              </div>
            )}
          </div>

          <EditUserDetailsPopover isClientDetail={isClientDetail} />
        </div>
        <p className='m-0 mt-4 montserrat break-all text-base'>{member?.username}</p>
      </hgroup>

      <EditStatus />
    </div>
  )
}
