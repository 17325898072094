import { useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Pencil, Plus } from 'lucide-react'
import { Button } from 'components/ui/Button'
import { dollarFormat } from 'constants/DollarsFormat'
import { useManageDetailsDialog } from 'contexts/ManageDetailsDialog.context'
import { useGetClientCommitments } from 'hooks/api/useQuery.hooks'
import configuration from 'services/config'
import { dealName } from 'services/utils'
import { userKeyFactory } from 'helpers/api/factories/userKey'
import { CommitmentsDialog } from 'components/modals/commitments/CommitmentsDialog'
import { CommitmentsResponse } from '../../../../helpers/api/get-api'

export function Commitments() {
  const queryClient = useQueryClient()

  const { state } = useManageDetailsDialog()

  const { data } = useGetClientCommitments(state.user?.id)

  const [openEditCommitment, setOpenEditCommitment] = useState<boolean>(false)
  const [editedCommitment, setEditedCommitment] = useState<any>(null)

  const updateCommitments = async () => {
    Promise.all([
      queryClient.invalidateQueries({
        queryKey: userKeyFactory.commitments(state.user?.id),
        refetchType: 'all'
      }),
      queryClient.invalidateQueries({
        queryKey: userKeyFactory.entities(state.user?.id),
        refetchType: 'all'
      })
    ])

  }

  const groupedCommitments = useMemo(() => {
    return data?.reduce((acc, commitment) => {
      const existingCommitmentIndex = acc.findIndex(
        comm => comm.opportunity.name === commitment.opportunity.name
      )

      if (existingCommitmentIndex > -1) {
        const existingCommitment = acc[existingCommitmentIndex]

        const newSum =
          Number(existingCommitment.amount_requested ?? 0) +
          Number(commitment.amount_requested ?? 0)

        const newFinalAmountInvested =
          Number(existingCommitment.final_amount_invested ?? 0) +
          Number(commitment.final_amount_invested ?? 0)

        acc[existingCommitmentIndex] = {
          ...acc[existingCommitmentIndex],
          amount_requested: String(newSum),
          final_amount_invested: String(newFinalAmountInvested)
        }
      } else {
        acc.push(commitment)
      }

      return acc
    }, [] as CommitmentsResponse[])
  }, [data])

  return (
    <>
      <div className='border border-[#e3e3e3] rounded-lg p-4'>
        <div>
          <div className='flex items-center justify-between'>
            <h3 className='m-0 font-semibold text-lg md:text-xl montserrat normal-case'>
              Commitments
            </h3>
            <Button
              variant='ghost'
              className='space-x-1 text-[#111928]'
              onClick={() => {
                setEditedCommitment(null)
                setOpenEditCommitment(true)
              }}
            >
              <Plus className='w-4 sm:w-3 h-4 sm:h-3' />
              <span className='hidden sm:inline-block'>Add Commitment</span>
            </Button>
          </div>
        </div>

        <ul className='flex flex-col space-y-3 mt-2 overflow-y-auto max-h-[300px] py-2'>
          {groupedCommitments?.map(commitment => {
            return (
              <li
                key={`commitment-${commitment.id}`}
                className='flex items-center border-t border-[#EDEDED] pt-3'
              >
                <div
                  className='w-full max-w-[88px] h-[63px] rounded-md p-2'
                  style={{ backgroundColor: commitment.opportunity.background_color }}
                >
                  <img
                    src={configuration.apiBaseUrl + commitment.opportunity.logo.url}
                    alt={commitment.opportunity.name}
                    onError={({ currentTarget }) => {
                      currentTarget.className = 'w-full max-w-[88px] h-[63px] rounded-md invisible'
                    }}
                    className='w-full h-full object-contain'
                  />
                </div>
                <div className='ml-4'>
                  <h4 className='text-sm sm:text-lg font-medium'>
                    {dealName(commitment.opportunity)}
                  </h4>
                  <div className='mt-2 flex flex-col sm:flex-row space-y-1 sm:space-y-0'>
                    <span className='text-xs sm:text-base text-[#5D5D5D]'>
                      <b className='font-medium text-black'>Requested:</b>{' '}
                      {dollarFormat(commitment.amount_requested)}
                    </span>
                    <span className='text-xs sm:text-base sm:ml-4 text-[#5D5D5D]'>
                      <b className='font-medium text-black'>Allocated:</b>{' '}
                      {dollarFormat(commitment.final_amount_invested ?? 0)}
                    </span>
                  </div>
                </div>

                <Button
                  variant='ghost'
                  className='ml-auto'
                  onClick={() => {
                    setEditedCommitment(commitment)
                    setOpenEditCommitment(true)
                  }}
                >
                  <Pencil color='#4E9535' className='w-4 h-4' />
                </Button>
              </li>
            )
          })}
        </ul>
      </div>

      <CommitmentsDialog
        open={openEditCommitment}
        toggleOpen={setOpenEditCommitment}
        user={state.user}
        afterSuccessCb={updateCommitments}
        editedCommitment={editedCommitment}
        setEditedCommitment={setEditedCommitment}
      />
    </>
  )
}
