import { Dialog, DialogContent } from 'components/ui/Dialog'
import CommitmentsForm from 'components/CommitmentsForm'
import type { UserInfo } from 'types/api-types'

// TODO: fix types and props when refactoring commitments modal
interface Props {
  open: boolean
  toggleOpen: (open: boolean) => void
  user: UserInfo | undefined
  editedCommitment: any
  setEditedCommitment: any
  afterSuccessCb: any
}

export function CommitmentsDialog({
  open,
  toggleOpen,
  user,
  editedCommitment,
  setEditedCommitment,
  afterSuccessCb
}: Props) {
  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='max-w-5xl'>
        <CommitmentsForm
          setCommitment={setEditedCommitment}
          editedCommitment={editedCommitment}
          opportunityId={editedCommitment && editedCommitment.opportunity_id}
          userId={user?.id}
          orgType={undefined} // state.user?.orgType doesn't exist?
          orgName={user?.name}
          afterSuccess={afterSuccessCb}
          close={() => toggleOpen(false)}
        />
      </DialogContent>
    </Dialog>
  )
}
