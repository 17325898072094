import { useMemo } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { zodResolver } from '@hookform/resolvers/zod'
import z from 'zod'
import { Button } from 'components/ui/Button'
import { CurrencyInput } from 'components/ui/CurrencyInput'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/Select'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { LoadingButton } from 'components/ui/LoadingButton'
import { Skeleton } from 'components/ui/Skeleton'
import { dollarFormat } from 'constants/DollarsFormat'
import { OpportunityResponse } from 'types/api-types'
import { useAddOrUpdateFund } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'
import { useGetOpportunitiesByOpportunityTypes } from 'hooks/api/useQuery.hooks'

interface Props {
  opportunity: OpportunityResponse
  open: boolean
  toggleOpen: (open: boolean) => void
  idx: number | null
}

const fundInvestmentSchema = z.object({
  fund_id: z.string({ message: 'Fund selection is required.' }),
  investment_value: z.string({ message: 'Investment value is required.' }),
  reserve_amount: z.string({ message: 'Reserve amount is required.' })
})
type FundInvestmentSchema = z.infer<typeof fundInvestmentSchema>

export function EditFundInvestmentDialog({ opportunity, idx, open, toggleOpen }: Props) {
  const { funds } = opportunity
  const fund = idx === null ? idx : funds[idx]

  const {
    data: opportunitiesByOpportunityType,
    isLoading: opportunitiesByOpportunityTypeLoading,
    isFetching: opportunitiesByOpportunityTypeFetching
  } = useGetOpportunitiesByOpportunityTypes('Vintage')

  const vintageOptions = useMemo(() => {
    if (!opportunitiesByOpportunityType) return []
    if (fund) return opportunitiesByOpportunityType

    return opportunitiesByOpportunityType.filter(opp => {
      return funds.every(fund => fund.fund_id !== opp.id)
    })
  }, [opportunitiesByOpportunityType])

  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { mutate, isLoading } = useAddOrUpdateFund()

  const form = useForm<FundInvestmentSchema>({
    resolver: zodResolver(fundInvestmentSchema),
    defaultValues: {
      fund_id: fund?.fund_id + '',
      investment_value: fund?.investment_value ?? '0',
      reserve_amount: fund?.reserve_amount ?? '0'
    }
  })

  const handleSubmit: SubmitHandler<FundInvestmentSchema> = values => {
    mutate(
      {
        opportunity_id: opportunity.id,
        fund_id: +values.fund_id,
        investment_value: +values.investment_value,
        reserve_amount: +values.reserve_amount
      },
      {
        onSuccess: async () => {
          toast({
            variant: 'success',
            description: 'Fund investment added/updated'
          })
          await queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(opportunity.id + '')
          })
          toggleOpen(false)
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  const currentTotal = +form.watch('investment_value') + +form.watch('reserve_amount')
  const selectedFundId = form.watch('fund_id')
  const totalFundInvestment = funds.reduce((acc, curr) => {
    if (curr.fund_id === fund?.fund_id) return +acc + 0
    return +acc + (+(curr.investment_value ?? 0) + +(curr.reserve_amount ?? 0))
  }, 0)

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='max-w-[1035px]'>
        <DialogHeader>
          <DialogTitle className='text-2xl'>Fund Investments</DialogTitle>
        </DialogHeader>

        {fund?.fund_name && <h3 className='text-lg normal-case font-semibold'>{fund.fund_name}</h3>}

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className='space-y-4'>
              <FormField
                control={form.control}
                name='fund_id'
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>{fund ? '' : 'Add '}Fund Investment</FormLabel>
                      <FormControl>
                        <Select
                          defaultValue={
                            vintageOptions.find(vintage => vintage.id === +field.value)?.id + ''
                          }
                          disabled={!!fund}
                          onValueChange={val => {
                            if (fund) return
                            field.onChange(val)
                          }}
                        >
                          <FormControl>
                            {opportunitiesByOpportunityTypeLoading ||
                            opportunitiesByOpportunityTypeFetching ? (
                              <Skeleton className='w-full h-12 bg-gray-300' />
                            ) : (
                              <SelectTrigger size='lg' className='border-[#BCCDE5] px-4'>
                                <SelectValue placeholder='Stage' defaultValue={field.value} />
                              </SelectTrigger>
                            )}
                          </FormControl>
                          <SelectContent>
                            {vintageOptions.map(vintage => {
                              return (
                                <SelectItem key={vintage.id} value={vintage.id + ''}>
                                  {vintage.name}
                                </SelectItem>
                              )
                            })}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='investment_value'
                render={({ field }) => {
                  const { onChange, value, ...rest } = field
                  return (
                    <FormItem>
                      <FormLabel>Investment Period Amount</FormLabel>
                      <FormControl>
                        <CurrencyInput
                          {...rest}
                          value={value}
                          onValueChange={value => onChange(value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='reserve_amount'
                render={({ field }) => {
                  const { onChange, value, ...rest } = field
                  return (
                    <FormItem>
                      <FormLabel>Reserve Amount</FormLabel>
                      <FormControl>
                        <CurrencyInput
                          {...rest}
                          value={value}
                          onValueChange={value => onChange(value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>

            <div className='mt-6 space-y-2'>
              <p className='text-base font-medium m-0'>
                Total {vintageOptions.find(op => op.id === +selectedFundId)?.name ?? ''}:{' '}
                {dollarFormat(currentTotal)}
              </p>
              <p className='text-base font-medium m-0'>
                Total Fund Investment: {dollarFormat(totalFundInvestment + currentTotal)}
              </p>
            </div>

            <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
              <Button
                type='button'
                size='sm'
                disabled={isLoading}
                className='flex-1 max-w-[262px]'
                onClick={() => toggleOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                loading={isLoading}
                size='sm'
                className='flex-1 max-w-[262px] bg-success'
              >
                Save
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
