import { useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { type SubmitHandler, useForm } from 'react-hook-form'
import DateTimePicker from 'react-datetime-picker'
import { useQueryClient } from '@tanstack/react-query'
import z from 'zod'
import { Button } from 'components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/Select'
import { LoadingButton } from 'components/ui/LoadingButton'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { Skeleton } from 'components/ui/Skeleton'
import { Input } from 'components/ui/Input'
import { OpportunityResponse } from 'types/api-types'
import { useUpdateOpportunityMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'
import { useGetOpporrunitiesByOpportunityType } from 'hooks/api/useQuery.hooks'

interface Props {
  open: boolean
  toggleOpen: (open: boolean) => void
  opportunity: OpportunityResponse
}

const editMVPFundSchema = z.object({
  name: z.string().min(1, { message: 'Name is required.' }),
  dealshare_vintage_id: z.string({ message: 'Associated MVP fund is required.' }),
  closes: z.date({ message: 'Closing date is required.' })
})
type EditMVPFundSchema = z.infer<typeof editMVPFundSchema>

/**
 * MVP Fund details dialog
 */
export function EditChampionFundDetailsDialog({ open, toggleOpen, opportunity }: Props) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const {
    data,
    isFetching: isOpportunityTypeFetching,
    isLoading: isOpportunityTypeLoading
  } = useGetOpporrunitiesByOpportunityType({ opportunity_type: 'Vintage', slim: true })
  const championAssociatedMvpFundList = useMemo(() => {
    if (!data) return []
    return data.filter(d => d.associated_collection?.id === opportunity.id)
  }, [data])

  const form = useForm<EditMVPFundSchema>({
    resolver: zodResolver(editMVPFundSchema),
    defaultValues: {
      name: opportunity.name,
      dealshare_vintage_id: opportunity.dealshare_vintage_id + '',
      closes: new Date(opportunity.closes)
    }
  })

  const { mutate, isLoading } = useUpdateOpportunityMutate()

  const handleSubmit: SubmitHandler<EditMVPFundSchema> = values => {
    mutate(
      { id: opportunity.id, ...values, dealshare_vintage_id: +values.dealshare_vintage_id },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(opportunity.id + '')
          })
          toast({
            variant: 'success',
            description: 'Champion Fund Details have been updated'
          })
          toggleOpen(false)
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='max-w-[1035px]'>
        <DialogHeader>
          <DialogTitle className='text-2xl'>Edit Champion Fund Details</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className='space-y-4'>
              <FormField
                control={form.control}
                name='dealshare_vintage_id'
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Champion&apos;s Associated MVP Fund</FormLabel>
                      <FormControl>
                        <Select defaultValue={field.value} onValueChange={field.onChange}>
                          <FormControl>
                            {isOpportunityTypeFetching || isOpportunityTypeLoading ? (
                              <Skeleton className='w-full h-12 bg-gray-300' />
                            ) : (
                              <SelectTrigger size='lg' className='border-[#BCCDE5] px-4'>
                                <SelectValue
                                  placeholder='Stage Rollup'
                                  defaultValue={field.value}
                                />
                              </SelectTrigger>
                            )}
                          </FormControl>
                          <SelectContent>
                            {championAssociatedMvpFundList.map(item => {
                              return (
                                <SelectItem key={item.id} value={item.id + ''}>
                                  {item.name}
                                </SelectItem>
                              )
                            })}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='name'
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Company Name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='closes'
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Closing Date</FormLabel>
                      <FormControl>
                        <DateTimePicker
                          value={field.value}
                          disableClock
                          clearIcon={null}
                          onChange={field.onChange}
                          className='mvp-datetime-picker border border-[#BCCDE5] bg-white h-12 px-4 rounded-lg mb-2 w-full'
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>

            <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
              <Button
                type='button'
                size='sm'
                disabled={isLoading}
                className='flex-1 max-w-[262px]'
                onClick={() => toggleOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                loading={isLoading}
                size='sm'
                className='flex-1 max-w-[262px] bg-success'
              >
                Save
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
