import React from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Button } from '../ui/Button'
import { Label } from '../ui/Label'

interface CurrencyInputProps {
  onChange: (value: string) => void
  label?: string
  className?: string
  value?: number
  note?: string
  allowDecimals?: boolean
  maxDecimals?: number
  disabled?: boolean
  buttonLabel?: string
  buttonAction?: () => void
}

const CurrencyInputComponent: React.FC<CurrencyInputProps> = ({
  onChange,
  label,
  className,
  value,
  note,
  allowDecimals = true,
  maxDecimals = 2,
  disabled = false,
  buttonLabel,
  buttonAction
}) => {
  const handleInputChange = (newValue: string) => {
    onChange && value !== Number(newValue) && onChange(newValue)
  }

  return (
    <div className={className}>
      {label && <Label className="form-label">{label}</Label>}
      <div className='flex items-start justify-between'>
        <CurrencyInput
          prefix='$'
          allowDecimals={allowDecimals}
          decimalScale={allowDecimals ? maxDecimals : 0}
          decimalsLimit={allowDecimals ? maxDecimals : 0}
          disabled={disabled}
          value={value}
          onValueChange={(value) => handleInputChange(value || '')}
          className={`form-input flex-grow h-12 px-4 ${
            buttonLabel ? 'rounded-r-none' : 'rounded-r-lg'
          }`}
        />
        {buttonLabel && !disabled && (
          <Button className='rounded-l-none' onClick={buttonAction}>
            {buttonLabel}
          </Button>
        )}
      </div>
      {note && <Label>{note}</Label>}
    </div>
  )
}

export default CurrencyInputComponent
