import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import sv from '../../themes/styles'
import useDebounce from '../../services/useDebounce'
import { Content, PageHeader, PageTitle } from '../../constants/StyleComponents'
import Header from '../../components/core/Header'
import Headings from '../../components/core/Headings'
import Button from '../../components/core/Button'
import Input from '../../components/core/fields/Input'
import MultiselectDropdown from '../../components/core/fields/MultiselectDropdown'
import ScrollContainer from '../../components/core/ScrollContainer'
import LoadingSpinner from '../../components/core/LoadingSpinner'
import Modal from '../../components/core/Modal'
import NewUser from '../../components/NewUser'
import ClientRow from '../../components/ClientRow'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import UserStatusOptions from '../../constants/UserStatusOptions'

import { getUsersPage } from '../../services/DataStore'
import { currentPlatform, authenticatedFetch, PLATFORMS } from '../../services/authentication'
import HeaderNew from 'components/core/HeaderNew'
import { cn } from 'services/utils'
import { ManageClientDetailsDialog } from 'components/modals/manageDetails/ManageClientDetailsDialog'
// STYLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const StyledPageTitle = styled(PageTitle)`
  margin: 0;
`

const StyledPageHeader = styled(PageHeader)`
  margin-bottom: 32px;
`

const SearchField = styled(Input)`
  width: 200px;
  margin-left: auto;
  margin-right: ${sv.grid * 2}px;
`

const ListContainer = styled.div`
  overflow: auto;
  flex: 1;
`

const List = styled.div`
  flex: 1;
`

const Loading = styled.div`
  width: 100%;
  height: 300px;
  ${sv.flexCenter};
`

const PlatformTabs = styled.div`
  display: flex;
  margin-bottom: 2em;
`

// COMPONENT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const platformTabList = [
  { title: 'Champion', value: 'champion' },
  { title: 'MVP', value: 'mvp' },
  { title: 'Pending', value: 'pending' }
]

export default function Clients() {
  const [isScrolled, setIsScrolled] = useState(false)
  const [showNewClientModal, setShowNewClientModal] = useState(false)
  const [clientsByPlatform, setClientsByPlatform] = useState({
    champion: [],
    mvp: [],
    pending: []
  })
  const [loading, setLoading] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [filterStatuses, setFilterStatuses] = useState(['pending_email_validation', 'approved'])
  const [searchMetadata, setSearchMetadata] = useState({
    order_by: 'created_at',
    order: 'desc'
  })
  const [platform, setPlatform] = useState(currentPlatform())
  const [clientCount, setClientCount] = useState(0)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [headingItems, setHeadingItems] = useState([])

  const [selectedClient, setSelectedClient] = useState(null)

  const setClientsValues = newClients => {
    setClientsByPlatform({
      ...clientsByPlatform,
      [platform]: [...clientsByPlatform[platform], ...newClients]
    })
    setClientCount(newClients.headers.total)
    if (
      newClients.headers &&
      Number(newClients.headers.offset) + Number(newClients.length) >= newClients.headers.total
    ) {
      setHasNextPage(false)
    } else setHasNextPage(true)
  }

  const handleLoadMore = useCallback(() => {
    setLoading(true)

    getUsersPage({
      ...searchMetadata,
      offset: clientsByPlatform[platform].length,
      statuses: filterStatuses,
      platform: platform
    })
      .then(results => {
        setClientsValues(results)
      })
      .finally(() => {
        setLoading(false)
      })

    headersUpdate()
  }, [clientsByPlatform, searchMetadata, filterStatuses, platform])

  const newSearch = useCallback(
    (force = false) => {
      setHasNextPage(true)
      handleLoadMore()
    },
    [handleLoadMore, clientsByPlatform]
  )

  const handlePlatform = newPlatform => {
    if (platform === newPlatform) return
    setClientsByPlatform({ ...clientsByPlatform, [platform]: [] })
    setPlatform(newPlatform)
    setSearchMetadata({
      order_by: 'created_at',
      order: 'desc'
    })
    headersUpdate()
    setSearchMetadata({ ...searchMetadata, search_term: searchTerm })
  }

  const headersUpdate = () => {
    setHeadingItems([])
    const newHeadItems = [
      {
        label: 'Name',
        width: null,
        action: () => handleOrder('people.fname'),
        orderable: true,
        selected: searchMetadata.order_by === 'people.fname',
        asc: searchMetadata.order === 'asc'
      },
      {
        label: 'Invested (This Year)',
        width: 200,
        action: () => handleOrder('invested_current'),
        orderable: true,
        selected: searchMetadata.order_by === 'invested_current',
        asc: searchMetadata.order === 'asc'
      },
      {
        label: 'Invested (All-Time)',
        width: 200,
        action: () => handleOrder('invested_all_time'),
        orderable: true,
        selected: searchMetadata.order_by === 'invested_all_time',
        asc: searchMetadata.order === 'asc'
      },
      {
        label: 'Account Status',
        width: 200,
        action: () => handleOrder('status'),
        orderable: true,
        selected: searchMetadata.order_by === 'status',
        asc: searchMetadata.order === 'asc'
      },
      {
        label: 'Date Created',
        width: 200,
        action: () => handleOrder('created_at'),
        orderable: true,
        selected: searchMetadata.order_by === 'created_at',
        asc: searchMetadata.order === 'asc'
      },
      {
        label: 'Last login',
        width: 200,
        action: () => handleOrder('last_sign_in_at'),
        orderable: true,
        selected: searchMetadata.order_by === 'last_sign_in_at',
        asc: searchMetadata.order === 'asc'
      }
    ]

    setHeadingItems(newHeadItems)
  }

  useEffect(() => {
    setClientsByPlatform({ ...clientsByPlatform, [platform]: [] })
    setSearchMetadata({ ...searchMetadata, search_term: debouncedSearchTerm })
    // do NOT listen to the linter here; if you add 'newSearch' or 'seearchMetaData' to the dependency array the view will get stuck in an infinite loop
  }, [debouncedSearchTerm])

  // Run the search when the searchMetadata changes
  useEffect(() => {
    setLoading(true)

    getUsersPage({
      ...searchMetadata,
      offset: clientsByPlatform[platform].length,
      statuses: filterStatuses,
      platform: platform
    })
      .then(results => {
        setClientsValues(results)
      })
      .finally(() => {
        setLoading(false)
      })

    headersUpdate()
  }, [searchMetadata, filterStatuses, platform]) // do NOT add `newSearch` to the dependencies array here

  const handleOrder = property => {
    setSearchMetadata({
      ...searchMetadata,
      order_by: property,
      order: searchMetadata.order === 'asc' ? 'desc' : 'asc'
    })
  }

  const updateLocalClient = client => {
    const clientsCopy = [...clientsByPlatform[platform]]
    const clientToUpdate = clientsCopy.find(c => c.id === client.id)
    clientToUpdate.status = client.status
    if (client.commitments) {
      clientToUpdate.commitments = [...client.commitments]
    }
    setClientsByPlatform({ ...clientsByPlatform, [platform]: clientsCopy })
  }

  const handleNewClientSuccess = () => {
    setShowNewClientModal(false)
    newSearch(true)
  }

  const handleFilterStatuses = statuses => {
    const newStatuses = statuses.map(status => status.value)
    setClientsByPlatform({ ...clientsByPlatform, [platform]: [] })
    setFilterStatuses(newStatuses)
  }

  const infiniteRef = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: handleLoadMore,
    scrollContainer: 'parent'
  })

  const defaultStatusFilters = UserStatusOptions.filter(option => {
    return !(option.value === 'disabled' || option.value === 'not_approved')
  })
  return (
    <ScrollContainer hasHeader isScrolled={isScrolled} setIsScrolled={setIsScrolled}>
      {/* <Header onAdmin isScrolled={isScrolled} /> */}
      <HeaderNew />

      <Content isAdmin>
        <StyledPageHeader>
          <StyledPageTitle>Clients ({clientCount})</StyledPageTitle>
          <SearchField
            noMargin
            icon='search'
            placeholder='Search clients...'
            onChange={value => setSearchTerm(value)}
          />
          <MultiselectDropdown
            options={UserStatusOptions} // Options to display in the dropdown
            placeholder='Filter by status...'
            onChange={handleFilterStatuses}
            displayValue='label'
            icon='filter'
            defaultValue={defaultStatusFilters}
          />
          <Button label='New Client' action={() => setShowNewClientModal(true)} />
        </StyledPageHeader>

        <PlatformTabs>
          {platformTabList.map(({ title, value }) => (
            <button
              key={`platform-${value}`}
              disabled={loading || platform === value}
              className={cn(
                'inline-flex p-4 w-[150px] justify-center items-center bg-[#eee] rounded-lg mr-8 font-semibold',
                platform === value && 'bg-[#d4dde9]'
              )}
              onClick={() => handlePlatform(value)}
            >
              {title}
            </button>
          ))}
        </PlatformTabs>

        <Headings items={headingItems} />
        <ListContainer>
          <List ref={infiniteRef}>
            {!!clientsByPlatform[platform].length &&
              clientsByPlatform[platform].map((client, index) => (
                <ClientRow
                  key={index}
                  client={client}
                  updateLocalClient={updateLocalClient}
                  setShowDialog={() => setSelectedClient(client)}
                  setClients={user => {
                    setClientsByPlatform(prevState => {
                      return {
                        ...prevState,
                        [platform]: prevState[platform].map(client => {
                          if (client.id !== user.id) return client
                          return {
                            ...client,
                            username: user.email,
                            name: `${user.person.fname} ${user.person.lname}`,
                            linkedin_url: user.linkedin_url,
                            global_carry: user.global_carry,
                            person: {
                              ...client.person,
                              fname: user.person.fname,
                              lname: user.person.lname
                            },
                            fname: user.person.fname,
                            lname: user.person.lname
                          }
                        })
                      }
                    })
                  }}
                />
              ))}
            {loading && (
              <Loading>
                <LoadingSpinner />
              </Loading>
            )}
          </List>
        </ListContainer>
      </Content>
      <Modal show={showNewClientModal} close={() => setShowNewClientModal(false)} shadeDoesNotClose>
        <NewUser close={() => handleNewClientSuccess()} />
      </Modal>

      <ManageClientDetailsDialog
        member={selectedClient}
        open={!!selectedClient}
        toggleOpen={status => {
          if (!status) setSelectedClient(null)
        }}
        setClients={user => {
          setClientsByPlatform(prevState => {
            return {
              ...prevState,
              [platform]: prevState[platform].map(client => {
                if (client.id !== user.id) return client
                return {
                  ...client,
                  username: user.email,
                  name: `${user.person.fname} ${user.person.lname}`,
                  linkedin_url: user.linkedin_url,
                  global_carry: user.global_carry,
                  person: {
                    ...client.person,
                    fname: user.person.fname,
                    lname: user.person.lname
                  },
                  fname: user.person.fname,
                  lname: user.person.lname
                }
              })
            }
          })
        }}
      />
    </ScrollContainer>
  )
}
