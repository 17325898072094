import mixpanel from 'mixpanel-browser'

const env_check =
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_API_URL === 'https://api-v2.mvp-vc.com'

mixpanel.init('cffdbf7faee8fe9da6b60672265e6de2', {
  api_host: 'https://mixpanel-proxy-vn4kb.ondigitalocean.app/',
  debug: !env_check,
  opt_out_tracking_by_default: false,
  ignore_dnt: true // do not track, as we do not have to comply with Europe's GDPR
})

export const Mixpanel = {
  identify: id => {
    if (env_check) mixpanel.identify(id)
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props)
  },
  people: {
    set: props => {
      if (env_check) mixpanel.people.set(props)
    }
  },
  register: props => {
    if (env_check) mixpanel.register(props)
  },
  register_once: props => {
    if (env_check) mixpanel.register_once(props)
  }
}
