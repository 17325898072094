import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Camera, Pencil } from 'lucide-react'
import configuration from 'services/config'
import { dateTimeFormat } from 'services/TimeUtils'
import { useGetOpportunity, useUserQuery } from 'hooks/api/useQuery.hooks'
import type { OpportunityResponse } from 'types/api-types'
import { Button } from 'components/ui/Button'
import { cn, dealStatus, dealType } from 'services/utils'
import { OPPORTUNITY_TYPES } from 'services/opportunities'
import { EditLogoDialog } from './dialogs/EditLogoDialog'
import { EditOpportunityStatus } from './EditOpportunityStatus'
import { EditMVPFundDetailsDialog } from './dialogs/editFundDetails/EditMVPFundDetailsDialog'
import { EditCoInvestDetailsDialog } from './dialogs/editFundDetails/EditCoInvestDetailsDialog'
import { EditChampionFundDetailsDialog } from './dialogs/editFundDetails/EditChampionFundDetailDialog'
import { DealLoader } from './layouts/Loader'

const statusMapper: Record<string, { className: string; mobileClassName: string; label: string }> =
  {
    active: {
      className: 'text-[#6DB557] border-[#6DB557] bg-green-50/20',
      mobileClassName: 'bg-[#6DB557]',
      label: 'Live'
    },
    draft: {
      className: 'text-[#F88A0A] border-[#F88A0A] bg-orange-50/20',
      mobileClassName: 'bg-[#F88A0A]',
      label: 'Draft'
    },
    archived: {
      className: 'text-[#F47777] border-[#F47777] bg-red-50/20',
      mobileClassName: 'bg-[#F47777]',
      label: 'Archived'
    }
  }

interface Props {
  id: string
}

export function DealHeader({ id }: Props) {
  const { data: user } = useUserQuery()

  const { data: opportunity, isLoading, isFetching } = useGetOpportunity(id)

  const [openLogoEditDialog, setOpenLogoEditDialog] = useState<boolean>(false)
  const [openEditInvestmentDetailsDialog, setOpenEditInvestmentDetailsDialog] = useState<
    keyof typeof OPPORTUNITY_TYPES | string | null
  >(null)

  const isAdmin = !!user?.is_admin || !!user?.super_admin

  const isMvpFundOpen = openEditInvestmentDetailsDialog === 'MVP Fund'
  const isChampionFundOpen = openEditInvestmentDetailsDialog === 'Champion Fund'
  const isCoInvestFundOpen = openEditInvestmentDetailsDialog === 'Co-Invest'

  if (!opportunity || isLoading || isFetching) return <DealLoader.Header />

  return (
    <>
      <header className='bg-[#10273A] px-4'>
        <div className='max-w-[1500px] mx-auto px-4 pt-[57px] pb-[113px] isolate'>
          <div className='flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-x-10 sm:space-y-0'>
            <div
              className='sm:w-[273px] h-[195px] rounded-xl p-4 relative group'
              style={{ backgroundColor: opportunity.background_color }}
            >
              <button
                type='button'
                className='opacity-0 group-hover:opacity-100 absolute left-0 top-0 rounded-t-md w-full p-4 bg-gray-400'
                onClick={() => setOpenLogoEditDialog(true)}
              >
                <Camera color='#ffffff' className='ml-auto' />
              </button>
              <img
                src={configuration.apiBaseUrl + opportunity.logo.url}
                alt={opportunity.name}
                onError={({ currentTarget }) => {
                  currentTarget.className = 'w-full rounded-lg invisible'
                }}
                className='w-full h-full object-contain'
              />
            </div>

            <div className='flex-1'>
              <hgroup className='flex items-center'>
                <h1 className='text-white m-0 text-4xl'>{opportunity.name}</h1>
                <Button
                  variant='ghost'
                  className='ml-auto self-start'
                  onClick={() => setOpenEditInvestmentDetailsDialog(dealType(opportunity))}
                >
                  <Pencil color='#4E9535' />
                </Button>
              </hgroup>
              {
                {
                  [OPPORTUNITY_TYPES.DEAL_SHARE]: (
                    <CoInvestText isAdmin={isAdmin} opportunity={opportunity} />
                  ),
                  [OPPORTUNITY_TYPES.VINTAGE]: (
                    <MVPText isAdmin={isAdmin} opportunity={opportunity} />
                  ),
                  [OPPORTUNITY_TYPES.COLLECTION]: (
                    <ChampionText isAdmin={isAdmin} opportunity={opportunity} />
                  )
                }[opportunity.opportunity_type]
              }

              {/* Hide this button on viewport > 1024px */}
              {isAdmin && (
                <div className='mt-4 lg:hidden'>
                  <EditOpportunityStatus opportunity={opportunity} />
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {openLogoEditDialog && (
        <EditLogoDialog
          opportunity={opportunity}
          open={openLogoEditDialog}
          toggleOpen={setOpenLogoEditDialog}
        />
      )}

      {isMvpFundOpen && (
        <EditMVPFundDetailsDialog
          open={isMvpFundOpen}
          toggleOpen={() => setOpenEditInvestmentDetailsDialog(null)}
          opportunity={opportunity}
        />
      )}
      {isChampionFundOpen && (
        <EditChampionFundDetailsDialog
          open={isChampionFundOpen}
          toggleOpen={() => setOpenEditInvestmentDetailsDialog(null)}
          opportunity={opportunity}
        />
      )}
      {isCoInvestFundOpen && (
        <EditCoInvestDetailsDialog
          open={isCoInvestFundOpen}
          toggleOpen={() => setOpenEditInvestmentDetailsDialog(null)}
          opportunity={opportunity}
        />
      )}
    </>
  )
}

const closesTerm = (date: string | Date) => {
  const today = new Date()
  if (new Date(date) < today) return 'Closed'
  return 'Closes'
}

interface TextProps {
  isAdmin: boolean
  opportunity: OpportunityResponse
}

function ChampionText({ isAdmin, opportunity }: TextProps) {
  return (
    <div>
      <p className='text-white m-0 mt-4 text-lg'>
        <span className='font-semibold'>{closesTerm(opportunity.closes)}:</span>{' '}
        {dateTimeFormat(opportunity.closes)}
      </p>
      <p className='text-white m-0 mt-2 text-lg'>
        <span className='font-semibold'>Opportunity:</span>{' '}
        <Link to={`/opportunities/${opportunity.id}`} className='hover:underline'>
          {opportunity.id}
        </Link>
      </p>
      {isAdmin && (
        <div className='mt-4 hidden lg:block'>
          <EditOpportunityStatus opportunity={opportunity} />
        </div>
      )}
    </div>
  )
}
function MVPText({ isAdmin, opportunity }: TextProps) {
  return (
    <div>
      <p className='text-white m-0 mt-4 text-lg'>
        <span className='font-semibold'>{closesTerm(opportunity.closes)}:</span>{' '}
        {dateTimeFormat(opportunity.closes)}
      </p>
      <p className='text-white m-0 mt-2 text-lg'>
        <span className='font-semibold'>Opportunity:</span>{' '}
        <Link to={`/opportunities/${opportunity.id}`} className='hover:underline'>
          {opportunity.id}
        </Link>
      </p>
      {isAdmin && (
        <div className='mt-4 hidden lg:block'>
          <EditOpportunityStatus opportunity={opportunity} />
        </div>
      )}
    </div>
  )
}
function CoInvestText({ isAdmin, opportunity }: TextProps) {
  const isFollowOn = opportunity.is_follow_on
  const followOnDealsList = opportunity.follow_on_deals?.filter(deal => deal.id !== opportunity.id)

  return (
    <div className='flex flex-col lg:flex-row mr-10 mt-4'>
      <div className='space-y-2'>
        <p className='text-white m-0 text-lg'>
          <span className='font-semibold'>Lead investor:</span> {opportunity.investor?.name}
        </p>
        <p className='text-white m-0 text-lg'>
          <span className='font-semibold'>Opportunity:</span>{' '}
          <Link to={`/opportunities/${opportunity.id}`} className='hover:underline'>
            {opportunity.id}
          </Link>
        </p>
        <p className='text-white m-0 text-lg'>
          <span className='font-semibold'>{closesTerm(opportunity.closes)}:</span>{' '}
          {dateTimeFormat(opportunity.closes)}
        </p>
        {isAdmin && (
          <div className='mt-4 hidden lg:block'>
            <EditOpportunityStatus opportunity={opportunity} />
          </div>
        )}
      </div>

      {opportunity.parent_deal && (
        <div className='mt-2 space-y-2 lg:mt-0 lg:ml-auto'>
          <p className='text-white m-0 text-lg'>
            <span className='font-semibold'>Initial Deal:</span>{' '}
            <Link to={`/admin/deals/${opportunity.parent_deal.id}`} className='hover:underline'>
              <span>{opportunity.parent_deal.name}</span>{' '}
              <span>({opportunity.parent_deal.dealshare_stage.name})</span>
            </Link>
          </p>
        </div>
      )}

      {!isFollowOn && (
        <div className='mt-2 space-y-2 lg:mt-0 lg:ml-auto'>
          <p className='text-white m-0 text-lg'>
            <span className='font-semibold'>Follow-ons:</span>
          </p>

          {!followOnDealsList?.length ? (
            <span className='text-lg font-bold text-white '>No follow on deals</span>
          ) : (
            <ul className='space-y-2'>
              {followOnDealsList.map(deal => {
                const status = dealStatus(deal.status)
                return (
                  <li
                    key={`follow_on_deal-${deal.id}`}
                    className='flex items-center text-lg text-white space-x-2'
                  >
                    <Link to={`/admin/deals/${deal.id}`} className='space-x-1 hover:underline'>
                      <span>{deal.name}</span> <span>[{deal.stage}]</span>
                    </Link>
                    <span
                      className={cn(
                        'border rounded-full px-2 font-medium text-sm',
                        statusMapper[status]?.className
                      )}
                    >
                      {statusMapper[status]?.label}
                    </span>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  )
}
