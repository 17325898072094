import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'components/ui/Button'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from 'components/ui/AlertDialog'
import { LoadingButton } from 'components/ui/LoadingButton'
import { OpportunityResponse } from 'types/api-types'
import { useDeleteFund } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'

interface Props {
  opportunity: OpportunityResponse
  open: boolean
  toggleOpen: (open: boolean) => void
  idx: number
}

export function DeleteFundInvestmentDialog({ opportunity, idx, open, toggleOpen }: Props) {
  const { funds } = opportunity
  const fund = funds[idx]

  const queryClient = useQueryClient()
  const { toast } = useToast()

  const { mutate, isLoading } = useDeleteFund()

  const handleDelete = () => {
    mutate(
      {
        opportunityId: opportunity.id,
        fundId: fund.fund_id
      },
      {
        onSuccess: async () => {
          toast({
            variant: 'success',
            description: 'Fund investment removed'
          })
          await queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(opportunity.id + '')
          })
          toggleOpen(false)
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <AlertDialog open={open} onOpenChange={toggleOpen}>
      <AlertDialogContent className='max-w-3xl'>
        <AlertDialogHeader>
          <AlertDialogTitle>Remove Fund Investment</AlertDialogTitle>
        </AlertDialogHeader>

        <AlertDialogDescription>
          Are you sure you want to remove {fund?.fund_name}?
        </AlertDialogDescription>

        <AlertDialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
          <Button
            type='button'
            size='sm'
            disabled={isLoading}
            className='flex-1 max-w-[262px]'
            onClick={() => toggleOpen(false)}
          >
            Cancel
          </Button>
          <LoadingButton
            type='button'
            loading={isLoading}
            size='sm'
            className='flex-1 max-w-[262px] bg-archived'
            onClick={handleDelete}
          >
            Remove
          </LoadingButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
