import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { X } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import { LoadingButton } from 'components/ui/LoadingButton'
import { OpportunityResponse } from 'types/api-types'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { Input } from 'components/ui/Input'
import { Textarea } from 'components/ui/Textarea'
import { useEditSummaryMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'

interface Props {
  open: boolean
  toggleOpen: (open: boolean) => void
  opportunity: OpportunityResponse
}

const summarySchema = z.object({
  schedule_call_url: z.string().nullable(),
  tag: z.string(),
  company_url: z.string().nullable(),
  short_description_html: z.string().optional(),
  long_description_html: z.string().optional()
})
type SummarySchema = z.infer<typeof summarySchema>

export function EditSummaryDialog({ open, toggleOpen, opportunity }: Props) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const form = useForm<SummarySchema>({
    resolver: zodResolver(summarySchema),
    defaultValues: {
      schedule_call_url: opportunity.schedule_call_url,
      tag: '',
      company_url: opportunity.company_url,
      short_description_html: opportunity.short_description_html,
      long_description_html: opportunity.long_description_html
    }
  })

  const [tagList, setTagList] = useState<string[]>(opportunity.tag_list)

  const { mutateAsync, isLoading } = useEditSummaryMutate()

  const onSubmit: SubmitHandler<SummarySchema> = values => {
    const { tag, ...rest } = values
    mutateAsync(
      { id: opportunity.id, ...rest, tag_list: tagList.join(',') },
      {
        onSuccess: async () => {
          toast({
            variant: 'success',
            description: 'Opportunity summary updated'
          })
          await queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(opportunity.id + '')
          })
          toggleOpen(false)
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='max-w-[1035px] max-h-[85%] overflow-y-auto'>
        <DialogHeader>
          <DialogTitle className='text-2xl'>Edit Summary Details</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className='space-y-4'>
              <FormField
                control={form.control}
                name='schedule_call_url'
                render={({ field }) => {
                  return (
                    <FormItem className='flex-1'>
                      <FormLabel>Schedule Call URL</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          value={field.value ?? ''}
                          placeholder='Schedule Call URL'
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='tag'
                render={({ field }) => {
                  return (
                    <FormItem className='flex-1'>
                      <FormLabel>Add Tags</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder='Press enter to add a new tag'
                          onKeyDown={e => {
                            if (e.key === 'Enter') e.preventDefault()
                          }}
                          onKeyUp={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              if (tagList.includes(field.value)) return
                              else setTagList([...tagList, field.value])
                              field.onChange('')
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                      <ul className='flex items-center space-x-2'>
                        {tagList.map(tag => (
                          <li
                            key={tag}
                            className='flex items-center border border-[#10273A] rounded-sm bg-[#06283C12] px-2 py-0.5 text-sm'
                          >
                            {tag}
                            <button
                              type='button'
                              className='ml-2'
                              onClick={() => {
                                if (tagList.includes(tag))
                                  setTagList(prev => prev.filter(st => st !== tag))
                              }}
                            >
                              <X className='w-3 h-3' />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='company_url'
                render={({ field }) => {
                  return (
                    <FormItem className='flex-1'>
                      <FormLabel>Website</FormLabel>
                      <FormControl>
                        <Input {...field} value={field.value ?? ''} placeholder='Website' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='short_description_html'
                render={({ field }) => {
                  return (
                    <FormItem className='flex-1'>
                      <FormLabel>Short Description</FormLabel>
                      <FormControl>
                        <Textarea {...field} value={field.value ?? ''} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='long_description_html'
                render={({ field }) => {
                  return (
                    <FormItem className='flex-1'>
                      <FormLabel>Long Description</FormLabel>
                      <FormControl>
                        <Textarea {...field} value={field.value ?? ''} size='xl' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>

            <DialogFooter className='mt-8 flex flex-row space-x-2 sm:justify-start'>
              <Button
                type='button'
                size='sm'
                disabled={isLoading}
                className='flex-1 max-w-[262px]'
                onClick={() => toggleOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                loading={isLoading}
                size='sm'
                className='flex-1 max-w-[262px] bg-success'
              >
                Save
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
