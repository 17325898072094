import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { OPPORTUNITY_TYPES } from './opportunities'

export function isValidEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export function capitalize(str) {
  return str.length ? str.replace(str[0], str[0].toUpperCase()) : ''
}

/**
 * @param {string} color in "rgb(1,2,3)" or hex form
 * @return {boolean} True if the color is "dark," false if it is not dark.
 */
export function isDarkColor(color) {
  // Variables for red, green, blue values
  let r, g, b
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    if (color.match(/^linear-gradient/)) {
      // If its a gradient --> grab the first color
      color = color.split(' ')[1]
    }
    // If hex --> Convert it to RGB
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
  return hsp < 127.5
}

export function dealName(deal) {
  if (deal.dealshare_stage) {
    return `${deal.name} [${deal.dealshare_stage.name}]`
  } else {
    return deal.name
  }
}
export function dealType(deal) {
  if (deal.opportunity_type === OPPORTUNITY_TYPES.COLLECTION) return 'Champion Fund'
  if (deal.opportunity_type === OPPORTUNITY_TYPES.VINTAGE) return 'MVP Fund'
  return 'Co-Invest'
}

/**
 * Converts deal types
 * @param {string | undefined} dealType
 */
export function convertDealType(dealType) {
  if (!dealType) return ''
  switch (dealType) {
    case OPPORTUNITY_TYPES.COLLECTION:
      return 'Champion Fund'
    case OPPORTUNITY_TYPES.VINTAGE:
      return 'MVP Fund'
    default:
      return 'Co-Invest'
  }
}

// pass a string. allows zero
export function makeNumberHandleZero(numStr) {
  if (!numStr) {
    return ''
  } else {
    const num = Number(numStr)
    return num >= 0 ? num : ''
  }
}
export function isValidNumber(num) {
  return typeof num !== 'undefined' && num !== null && num !== '' && !Number.isNaN(num)
}

// Function to clean up the Linkedin profile URL.
export const linkedInRegex = new RegExp(
  '(https?)?:?(//)?(([w]{3}||ww).)?linkedin.com/in/[A-z0-9_-]+/?'
)

// Data types constants to use on the file type read/update. Ex.[src/components/EditResource]
export const dealTypes = {
  DataRoom: 'Data Room',
  data_room: 'data_room',
  DueDiligence: 'Due Diligence',
  due_diligence: 'due_diligence',
  Videos: 'Video Link',
  video_link: 'video_link'
}

export const dataTypes = {
  DataRoom: 'Data Room',
  data_room: 'data_room',
  DueDiligence: 'Due Diligence',
  due_diligence: 'due_diligence',
  Videos: 'Video Link',
  video_link: 'video_link',
  CompanyUpdates: 'Company Updates',
  company_updates: 'company_updates'
}

/**
 * Tailwind merge classNames
 * @param  {string[]} inputs array classNames
 * @returns tailwind classes
 * @example className={cn('font-bold', isDisabled ? 'text-red-500' : 'text-blue-500')}
 */
export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

/**
 * Convert deal status
 * @param {string} status
 * @returns {'active' | 'inactive' | 'archived'}
 */
export function dealStatus(status) {
  if (status === 'inactive') return 'draft'
  return status
}
