import { PropsWithChildren, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { DndContext, DragEndEvent, type UniqueIdentifier } from '@dnd-kit/core'
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable'
import { GripVertical, Pencil, Trash2 } from 'lucide-react'
import ReactHtmlParser from 'react-html-parser'
import type { OpportunityResponse } from 'types/api-types'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/Card'
import { Button } from 'components/ui/Button'
import { useUpdateKeyPointsMutate } from 'hooks/api/useMutation.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'
import { useToast } from 'hooks/userToast.hooks'
import { useGetOpportunity } from 'hooks/api/useQuery.hooks'
import { KeyPointDialog } from './dialogs/KeyPointDialog'
import { DeleteKeyPointDialog } from './dialogs/DeleteKeyPointDialog'
import { DealLoader } from './layouts/Loader'

interface Props {
  id: string
}
export function KeyPoints({ id }: Props) {
  const { data: opportunity } = useGetOpportunity(id)

  if (!opportunity) return <DealLoader.Card />

  return <KeyPointsChild opportunity={opportunity} />
}

interface InnerProps {
  opportunity: OpportunityResponse
}

export type OpportunityKeyPoints = OpportunityResponse['key_points']
export type OpportunityKeyPoint = OpportunityKeyPoints[number]

function KeyPointsChild({ opportunity }: InnerProps) {
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const items = useMemo(() => {
    return opportunity.key_points.sort((a, b) => a.order - b.order)
  }, [opportunity])

  const [openKeyPoint, setOpenKeyPoint] = useState<boolean>(false)
  const [openDeleteKeyPoint, setDeleteOpenKeyPoint] = useState<boolean>(false)
  const [selectedKeyPoint, setSelectedKeyPoint] = useState<OpportunityKeyPoint | undefined>()

  const { mutate } = useUpdateKeyPointsMutate()

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (over && active.id !== over.id) {
      const activeIdx = items.findIndex(({ id }) => id === active.id)
      const overIdx = items.findIndex(({ id }) => id === over.id)

      const reArranged = arrayMove(items, activeIdx, overIdx).map((result, idx) => ({
        ...result,
        order: idx
      }))

      queryClient.setQueryData<OpportunityResponse>(
        opportunityKeyFactory.opportunityByDeal(opportunity.id + ''),
        oldData => {
          if (!oldData) return undefined
          return {
            ...oldData,
            key_points: reArranged
          }
        }
      )

      mutate(
        { id: opportunity.id, keyPoints: reArranged },
        {
          onError: error => {
            toast({
              variant: 'destructive',
              description: error.message ?? 'Something went wrong',
              duration: 2000
            })
            queryClient.setQueryData<OpportunityResponse>(
              opportunityKeyFactory.opportunityByDeal(opportunity.id + ''),
              oldData => {
                if (!oldData) return undefined
                return {
                  ...oldData,
                  key_points: items
                }
              }
            )
          }
        }
      )
    }
  }

  return (
    <>
      <Card>
        <CardHeader className='flex flex-row items-center p-5 space-y-0'>
          <CardTitle>Key Points</CardTitle>
          <Button
            type='button'
            variant='ghost'
            size='sm'
            className='ml-auto text-success'
            onClick={() => {
              setOpenKeyPoint(true)
              setSelectedKeyPoint(undefined)
            }}
          >
            Add new
          </Button>
        </CardHeader>

        <CardContent>
          {!items.length ? (
            <p className='text-base text-slate-700 text-center'>
              This deal has no key points at this time
            </p>
          ) : (
            <DndContext onDragEnd={onDragEnd}>
              <SortableContext items={items}>
                <ul className='space-y-2'>
                  {items.map(point => {
                    return (
                      <li key={point.id}>
                        <SortableItem
                          id={point.id}
                          onEditKeyPoint={() => {
                            setSelectedKeyPoint(point)
                            setOpenKeyPoint(true)
                          }}
                          onDeleteKeypoint={() => {
                            setSelectedKeyPoint(point)
                            setDeleteOpenKeyPoint(true)
                          }}
                        >
                          {ReactHtmlParser(point.text_html)}
                        </SortableItem>
                      </li>
                    )
                  })}
                </ul>
              </SortableContext>
            </DndContext>
          )}
        </CardContent>
      </Card>

      {openKeyPoint && (
        <KeyPointDialog
          open={openKeyPoint}
          toggleOpen={() => {
            setSelectedKeyPoint(undefined)
            setOpenKeyPoint(false)
          }}
          opportunity={opportunity}
          keyPoint={selectedKeyPoint}
        />
      )}
      {openDeleteKeyPoint && (
        <DeleteKeyPointDialog
          open={openDeleteKeyPoint}
          toggleOpen={() => {
            setSelectedKeyPoint(undefined)
            setDeleteOpenKeyPoint(false)
          }}
          opportunity={opportunity}
          keyPoint={selectedKeyPoint}
        />
      )}
    </>
  )
}

interface SortableItemProps extends PropsWithChildren {
  id: UniqueIdentifier
  onEditKeyPoint: () => void
  onDeleteKeypoint: () => void
}
function SortableItem({ children, id, onEditKeyPoint, onDeleteKeypoint }: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({ id })

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition
      }
    : undefined

  return (
    <div
      ref={setNodeRef}
      className='flex items-center hover:bg-slate-50/50 rounded-md'
      style={style}
    >
      <Button ref={setActivatorNodeRef} {...listeners} {...attributes} variant='ghost' size='sm'>
        <GripVertical className='w-5 h-5 text-gray-400' />
      </Button>
      <p className='m-0 text-base ml-4'>{children}</p>

      <div className='flex items-center ml-auto pl-4 space-x-2'>
        <Button variant='ghost' size='sm'>
          <Pencil type='button' color='#4E9535' className='w-4 h-4' onClick={onEditKeyPoint} />
        </Button>
        <Button type='button' size='sm' variant='ghost' onClick={onDeleteKeypoint}>
          <Trash2 className='w-4 h-4 text-archived' />
        </Button>
      </div>
    </div>
  )
}
