import { Button } from 'components/ui/Button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/Card'
import type { OpportunityResponse } from 'types/api-types'
import { dollarFormat } from 'constants/DollarsFormat'
import { Link } from 'react-router-dom'
import { Table } from 'components/core/v2/Table'
import { ColumnDef } from '@tanstack/react-table'
import { useTable } from 'hooks/useTable.hooks'
import { useGetOpportunity, useUserQuery } from 'hooks/api/useQuery.hooks'
import { DealLoader } from './layouts/Loader'

interface Props {
  id: string
}
interface InnerProps {
  opportunity: OpportunityResponse
}

export function CarryTracking({ id }: Props) {
  const { data: opportunity } = useGetOpportunity(id)
  if (!opportunity) return <DealLoader.Card />
  return <CarryTrackingChild opportunity={opportunity} />
}

function CarryTrackingChild({ opportunity }: InnerProps) {
  const { data: user } = useUserQuery()

  const { overall } = opportunity?.carry_tracking ?? {}

  const columns: ColumnDef<{
    init_inv: string
    fees: string
    total: string
    value: string
    carry: string
  }>[] = [
    {
      header: 'Initial Invest',
      accessorKey: 'init_inv',
      accessorFn: row => dollarFormat(row.init_inv)
    },
    { header: 'Fees', accessorKey: 'fees', accessorFn: row => dollarFormat(row.fees) },
    { header: 'Final Total', accessorKey: 'total', accessorFn: row => dollarFormat(row.total) },
    { header: 'Value', accessorKey: 'value', accessorFn: row => dollarFormat(row.value) },
    { header: 'Carry', accessorKey: 'carry', accessorFn: row => dollarFormat(row.carry) }
  ]

  const data = [
    {
      init_inv: overall.init_inv,
      fees: overall.fees,
      total: overall.total,
      value: overall.value,
      carry: overall.carry
    }
  ]

  const table = useTable({
    columns,
    data: data
  })

  if (!user?.super_admin) return null

  return (
    <Card className='h-full'>
      <CardHeader className='flex items-center flex-row'>
        <CardTitle>Carry Tracking</CardTitle>
        <Button size='sm' variant='success' asChild className='ml-auto'>
          <Link to={`/admin/deals/${opportunity.id}/carries`}>View Carry</Link>
        </Button>
      </CardHeader>

      <CardContent>
        {+overall.carry > 0 ? (
          <Table table={table} />
        ) : (
          <p className='text-base text-center text-slate-700 m-0'>
            This deal has no carry at this time
          </p>
        )}
      </CardContent>
    </Card>
  )
}
