import type { OpportunityValuation } from 'types/api-types'
import { dateFormat } from 'services/TimeUtils'
import { dollarFormat } from 'constants/DollarsFormat'

const CoInvestValuationDetails: React.FC<OpportunityValuation> = (valuation) => [
  <tr key="fund-investment" className="h-10 border-b border-gray-200">
    <td>Fund Investment:</td>
    <td className="px-4">{dollarFormat(valuation.fund_total)}</td>
    <td>Funds Wired:</td>
    <td className="px-4">{dateFormat(valuation.funding_date)}</td>
  </tr>,
  <tr key="co-invest" className="h-10 border-b border-gray-200">
    <td>Co-Invest:</td>
    <td className="px-4">{dollarFormat(valuation.investment_total)}</td>
    <td>Initial Valuation:</td>
    <td className="px-4">{dollarFormat(valuation.initial_post_valuation)}</td>
  </tr>,
  <tr key="total-invested-co-invest" className="h-10 border-b border-gray-200">
    <td>Total Invested:</td>
    <td className="px-4">{dollarFormat(valuation.total_invested)}</td>
    <td>Current Valuation:</td>
    <td className="px-4">{dollarFormat(valuation.current_pre_valuation)}</td>
  </tr>
]

export default CoInvestValuationDetails