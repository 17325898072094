import { useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import z from 'zod'
import { Edit2 } from 'lucide-react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem } from 'components/ui/Form'
import { Input } from 'components/ui/Input'
import { Button } from 'components/ui/Button'
import { dollarFormat } from 'constants/DollarsFormat'
import { EntitiesResponse } from 'helpers/api/get-api'
import { useAddNewEntityMutate, useEditEntityNameMutate } from 'hooks/api/useMutation.hooks'
import { LoadingButton } from 'components/ui/LoadingButton'
import { userKeyFactory } from 'helpers/api/factories/userKey'
import { UserInfo } from 'types/api-types'
import { RemoveEntity } from './RemoveEntity'

interface Props {
  user: UserInfo | undefined
  entity: { name: string; isNew: boolean }
  entityData: EntitiesResponse | undefined
  cancelAddingEntity: () => void
}

const formSchema = z.object({
  name: z.string()
})
type FormSchema = z.infer<typeof formSchema>

export function ManageEntityItem({ user, entity, entityData, cancelAddingEntity }: Props) {
  const queryClient = useQueryClient()

  const [isEditing, setIsEditing] = useState<boolean>(false)

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...entity
    }
  })

  const { mutate: addNewEntityMutate, isLoading: isNewEntityLoading } = useAddNewEntityMutate()
  const { mutate: editNameMutate, isLoading: isEditNameLoading } = useEditEntityNameMutate()

  const handleSave: SubmitHandler<FormSchema> = formValue => {
    const { name } = formValue

    if (!user) return

    if (entity.isNew) {
      addNewEntityMutate(
        {
          name,
          userId: user.id,
          organizationId: user.organization.id
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: userKeyFactory.entities(user.id),
              type: 'all'
            })
            setIsEditing(false)
          }
        }
      )
      return
    }

    if (entityData)
      editNameMutate(
        { name, id: entityData.id },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: userKeyFactory.entities(user.id),
              type: 'all'
            })
            setIsEditing(false)
          }
        }
      )
  }

  const canDelete = useMemo(() => {
    if (!entityData) return false

    const totalFunded = Number(entityData.total_funded)

    return totalFunded === 0
  }, [entityData])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSave)}>
        <FormField
          control={form.control}
          name='name'
          render={({ field }) => {
            return (
              <FormItem className='flex items-center space-y-0 border border-[#EDEDED] rounded-md p-4'>
                {isEditing || !entityData ? (
                  <div className='flex items-center w-full space-x-2'>
                    <FormControl>
                      <Input {...field} value={field.value} />
                    </FormControl>
                    <LoadingButton
                      type='submit'
                      loading={isEditNameLoading || isNewEntityLoading}
                      disabled={!form.formState.isDirty}
                    >
                      Save
                    </LoadingButton>
                    <Button
                      type='button'
                      variant='destructive'
                      onClick={() => {
                        if (entity.isNew) cancelAddingEntity()
                        setIsEditing(false)
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className='flex items-center w-full'>
                      <div>
                        <h4 className='text-base font-semibold'>{entityData?.name}</h4>
                        <p className='text-sm font-normal text-gray-700 m-0'>
                          {dollarFormat(entityData?.total_funded)} invested
                        </p>
                      </div>

                      <div className='ml-auto'>
                        <Button
                          type='button'
                          variant='ghost'
                          size='sm'
                          onClick={() => setIsEditing(true)}
                        >
                          <Edit2 color='#4E9535' className='w-4 sm:w-5 h-4 sm:h-5' />
                        </Button>

                        {canDelete ? (
                          <RemoveEntity user={user} name={entity.name} id={entityData?.id} />
                        ) : (
                          <div className='inline-flex invisible'>
                            <RemoveEntity user={user} name={entity.name} id={entityData?.id} />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </FormItem>
            )
          }}
        />
      </form>
    </Form>
  )
}
