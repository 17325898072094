import { useState } from 'react'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from 'components/ui/AlertDialog'
import { LoadingButton } from 'components/ui/LoadingButton'
import { useRemoveAdminMemberFromOrganizationMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { useManageDetailsDialog } from 'contexts/ManageDetailsDialog.context'
import { Button } from 'components/ui/Button'

export function RemoveAdminMember() {
  const { toast } = useToast()

  const { state, handlers } = useManageDetailsDialog()

  const [open, setOpen] = useState<boolean>(false)
  const { mutate, isLoading } = useRemoveAdminMemberFromOrganizationMutate()

  const removeHandler = () => {
    if (!state.user) return

    mutate(state.user.id, {
      onSuccess: () => {
        toast({
          variant: 'success',
          description: 'Member was removed',
          duration: 2000
        })
        setOpen(false)
        handlers.onCloseManageDetailsDialog()
      },
      onError: error => {
        toast({
          variant: 'destructive',
          description: error.message ?? 'Something went wrong',
          duration: 2000
        })
      }
    })
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger className='w-full text-left'>Remove as admin</AlertDialogTrigger>

      <AlertDialogContent className='rounded-md max-w-3xl'>
        <AlertDialogHeader>
          <AlertDialogTitle className='text-2xl montserrat'>Remove as admin</AlertDialogTitle>
          <AlertDialogDescription className='text-base montserrat text-[#4A4A4A] break-all'>
            {state.user?.username} will be removed
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter className='mt-4 flex-row space-x-2 sm:justify-start'>
          <Button className='flex-1 max-w-[262px]' size='sm' onClick={() => setOpen(false)}>
            Close
          </Button>
          <LoadingButton
            type='button'
            variant='destructive'
            loading={isLoading}
            size='sm'
            className='ml-2 flex-1 max-w-[262px]'
            onClick={removeHandler}
          >
            Remove
          </LoadingButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
