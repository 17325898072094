import { type SubmitHandler, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { zodResolver } from '@hookform/resolvers/zod'
import z from 'zod'
import { Button } from 'components/ui/Button'
import { CurrencyInput } from 'components/ui/CurrencyInput'
import { Input } from 'components/ui/Input'
import DateTimePicker from 'react-datetime-picker'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { LoadingButton } from 'components/ui/LoadingButton'
import { OpportunityResponse } from 'types/api-types'
import { useUpdateOpportunityMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'

interface Props {
  opportunity: OpportunityResponse
  open: boolean
  toggleOpen: (open: boolean) => void
}

const valuationSchema = z.object({
  initial_post_valuation: z.string(),
  current_pre_valuation: z.string(),
  funding_date: z.date(),
  current_multiple: z.string(),
})
type ValuationSchema = z.infer<typeof valuationSchema>

export function EditValuationDialog({ opportunity, open, toggleOpen }: Props) {
  const { valuation } = opportunity
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useUpdateOpportunityMutate()

  const form = useForm<ValuationSchema>({
    resolver: zodResolver(valuationSchema),
    defaultValues: {
      initial_post_valuation: valuation?.initial_post_valuation || '',
      current_pre_valuation: valuation?.current_pre_valuation || '',
      funding_date: valuation?.funding_date ? new Date(valuation?.funding_date) : undefined,
      current_multiple: valuation?.current_multiple ? Number.parseFloat(valuation?.current_multiple || '').toFixed(4) : ''
    }
  })

  const handleSubmit: SubmitHandler<ValuationSchema> = values => {
    mutate(
      {
        id: opportunity.id,
        initial_post_valuation: values?.initial_post_valuation?.toString(),
        current_pre_valuation: values?.current_pre_valuation?.toString(),
        funding_date: values?.funding_date,
        moic: values?.current_multiple?.toString(),
      },
      {
        onSuccess: async () => {
          toast({
            variant: 'success',
            description: 'Valuation updated'
          })
          await queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(opportunity.id + '')
          })
          toggleOpen(false)
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogContent className='max-w-[1035px]'>
        <DialogHeader>
          <DialogTitle>Valuation</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form className="grid grid-cols-2 gap-4" onSubmit={form.handleSubmit(handleSubmit)}>
            <div className='space-y-4'>
              <FormField
                control={form.control}
                name='funding_date'
                render={({ field }) => {
                  const { onChange, value, ...rest } = field
                  return (
                    <FormItem>
                      <FormLabel>Funds wired</FormLabel>
                      <FormControl>
                        <DateTimePicker
                          onChange={onChange}
                          value={value && new Date(value)}
                          disableClock
                          clearIcon={null}
                          className="form-input mvp-datetime-picker h-12 px-4"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='current_multiple'
                render={({ field }) => {
                  const { onChange, value, ...rest } = field
                  return (
                    <FormItem>
                      <FormLabel>MOIC</FormLabel>
                      <FormControl>
                        <Input
                          {...rest}
                          value={value || ''}
                          onChange={value => onChange(value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>
            <div className='space-y-4'>
              <FormField
                control={form.control}
                name='initial_post_valuation'
                render={({ field }) => {
                  const { onChange, value, ...rest } = field
                  return (
                    <FormItem>
                      <FormLabel>Initial Post-Money Valuation</FormLabel>
                      <FormControl>
                        <CurrencyInput
                          {...rest}
                          value={value || ''}
                          onValueChange={value => onChange(value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='current_pre_valuation'
                render={({ field }) => {
                  const { onChange, value, ...rest } = field
                  return (
                    <FormItem>
                      <FormLabel>Current Pre-Money Valuation</FormLabel>
                      <FormControl>
                        <CurrencyInput
                          {...rest}
                          value={value || ''}
                          onValueChange={value => onChange(value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>

            <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
              <Button
                type='button'
                size='sm'
                disabled={isLoading}
                className='flex-1 max-w-[262px]'
                onClick={() => toggleOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                loading={isLoading}
                size='sm'
                className='flex-1 max-w-[262px] bg-success'
              >
                Save
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
