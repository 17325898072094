import React, { ChangeEvent } from 'react'
import { Textarea as UiTextArea, Variant, Size } from '../ui/Textarea'
import { Label } from '../ui/Label'

interface TextareaProps {
  onChange: (value: string) => void
  label?: string
  className?: string
  value?: string
  variant?: Variant
  size?: Size
}

const Textarea: React.FC<TextareaProps> = ({ onChange, label, className = '', value, variant = 'default', size = 'default' }) => {
  return (
    <div>
      {label && <Label className="form-label">{label}</Label>}
      <UiTextArea
        value={value}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
        className={className}
        variant={variant}
        size={size}
      />
    </div>
  )
}

export default Textarea
