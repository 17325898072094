import { useState } from 'react'
import { useParams } from 'react-router-dom'
import HeaderNew from 'components/core/HeaderNew'
import ScrollContainer from 'components/core/ScrollContainer'
import { useGetDealType } from 'hooks/api/useQuery.hooks'
import { OPPORTUNITY_TYPES } from 'services/opportunities'
import { CoInvestLayout } from 'components/core/v2/deal/layouts/CoInvest'
import { ChampionFundLayout } from 'components/core/v2/deal/layouts/ChampionFund'
import { MVPFundLayout } from 'components/core/v2/deal/layouts/MVPFund'
import { DealHeader } from 'components/core/v2/deal/DealHeader'

export default function Deal() {
  const params: { id: string } = useParams()

  const [isScrolled, setIsScrolled] = useState<boolean>(false)

  const { data: opportunity } = useGetDealType(params.id)

  return (
    <ScrollContainer
      hasHeader
      className=''
      goToTop={undefined}
      setGoToTop={undefined}
      isScrolled={isScrolled}
      setIsScrolled={setIsScrolled}
    >
      <HeaderNew />

      <DealHeader id={params.id} />

      {opportunity?.opportunity_type === OPPORTUNITY_TYPES.DEAL_SHARE && (
        <CoInvestLayout id={params.id} />
      )}
      {opportunity?.opportunity_type === OPPORTUNITY_TYPES.VINTAGE && (
        <MVPFundLayout id={params.id} />
      )}
      {opportunity?.opportunity_type === OPPORTUNITY_TYPES.COLLECTION && (
        <ChampionFundLayout id={params.id} />
      )}
    </ScrollContainer>
  )
}
