import { Button } from 'components/ui/Button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/Card'

interface Props {
  title: string
}

export function FollowOnDeals(props: Props) {
  const { title } = props

  return (
    <Card className='w-full'>
      <CardHeader className='flex items-center flex-row space-y-0'>
        <CardTitle>{title}</CardTitle>
        <Button size='sm' variant='ghost' className='opacity-0'>
          -
        </Button>
      </CardHeader>
      <CardContent>
        <p className='text-base text-slate-700 text-center'>Not available for Follow-on Deals</p>
      </CardContent>
    </Card>
  )
}
