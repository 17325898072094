import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogOverlay
} from 'components/ui/AlertDialog'
import { Button } from 'components/ui/Button'

// TODO: temporarily accepting props. Each alert modal should do its own thing
interface Props {
  open: boolean
  toggleOpen: (open: boolean) => void
  onConfirm: () => void
  onCancel: () => void
}

export default function ConfirmationAlert({ open, toggleOpen, onConfirm, onCancel }: Props) {
  const close = () => {
    toggleOpen(false)
    onCancel()
  }

  return (
    <AlertDialog open={open}>
      <AlertDialogOverlay className='z-10' />
      <AlertDialogContent className='rounded-md bg-white max-w-3xl z-[1000]'>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure you want to close without saving?</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>Any unsaved changes will be lost.</AlertDialogDescription>

        <AlertDialogFooter className='mt-4 flex-row space-x-2 sm:justify-start'>
          <Button
            variant='outline'
            type='button'
            size='sm'
            className='flex-1 max-w-[262px]'
            onClick={close}
          >
            Cancel
          </Button>

          <Button type='button' size='sm' className='flex-1 max-w-[262px]' onClick={onConfirm}>
            Confirm
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
