import { forwardRef, type InputHTMLAttributes } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'services/utils'

const inputVariants = cva(
  'form-input flex file:border-0 file:bg-transparent file:text-sm file:font-medium',
  {
    variants: {
      variant: {
        default: ''
      },
      inputSize: {
        default: 'h-12 px-4 !rounded-lg',
        sm: 'h-10 px-2 !rounded-md text-sm',
        lg: 'h-16 px-6 !rounded-xl'
      }
    },
    defaultVariants: {
      inputSize: 'default',
      variant: 'default'
    }
  }
)

export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

/**
 * Displays a form input field or a component that looks like an input field.
 *
 * How to use: https://ui.shadcn.com/docs/components/input
 */
const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ inputSize, className = '', type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ inputSize, className }), className)}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = 'Input'

export { Input }
