import { ChangeEvent, useRef, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { zodResolver } from '@hookform/resolvers/zod'
import z from 'zod'
import { Button } from 'components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/Dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/Form'
import { LoadingButton } from 'components/ui/LoadingButton'
import { OpportunityResponse } from 'types/api-types'
import { useUpdateCompanyUpdatesMutate } from 'hooks/api/useMutation.hooks'
import { useToast } from 'hooks/userToast.hooks'
import { opportunityKeyFactory } from 'helpers/api/factories/userKey'
import { dataTypes } from 'services/utils'
import { Input } from 'components/ui/Input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/Select'
import { Checkbox } from 'components/ui/Checkbox'
import { DUE_DILIGENCE_ICONS } from '../DueDiligence'

interface Props {
  opportunity: OpportunityResponse
  selectedLink: OpportunityResponse['links'][number] | null
  open: boolean
  onClose: () => void
}

const MAX_SIZE = 200 * 1024 * 1024 // 200MB

const TYPE_OPTIONS = [
  { value: 'text', label: 'Text' },
  { value: 'calendar', label: 'Calendar' },
  { value: 'video', label: 'Video' },
  { value: 'image', label: 'Image' }
]

const companyUpdatesSchema = z.object({
  url: z.string(),
  resource_type: z.string().min(1, { message: 'Type is required.' }),
  order: z.number(),
  document: z
    .object({
      url: z.string().nullable()
    })
    .nullable(),
  label_html: z.string().min(1, { message: 'Label is required.' }),
  group: z.string(),
  mail_investors: z.boolean().optional()
})
type CompanyUpdatesSchema = z.infer<typeof companyUpdatesSchema>

export function CompanyUpdatesDialog({ opportunity, selectedLink, open, onClose }: Props) {
  const fileInput = useRef<HTMLInputElement>(null)

  const queryClient = useQueryClient()
  const { toast } = useToast()

  const [document, setDocument] = useState<{
    document_filename?: string
    document_data_url?: string | ArrayBuffer | null
  }>({
    document_filename: selectedLink?.document_filename,
    document_data_url: selectedLink?.dcoument_data_url
  })

  const form = useForm<CompanyUpdatesSchema>({
    resolver: zodResolver(companyUpdatesSchema),
    defaultValues: {
      url: selectedLink?.url ?? '',
      resource_type: selectedLink?.resource_type ?? '',
      order: selectedLink?.order ?? 0,
      document: selectedLink?.document ?? { url: null },
      label_html: selectedLink?.label_html ?? '',
      group: selectedLink?.group ?? dataTypes.company_updates,
      mail_investors: selectedLink?.mail_investors
    }
  })

  const { mutate, isLoading } = useUpdateCompanyUpdatesMutate()

  const handleFileClick = () => {
    fileInput.current?.click()
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return

    if (file.size > MAX_SIZE) {
      alert(`File size should be less than ${MAX_SIZE / (1024 * 1024)}MB`)
      return
    }

    if (!(file instanceof Blob)) {
      alert('Invalid file')
      return
    }

    const reader = new FileReader()
    reader.onload = event => {
      setDocument({
        document_filename: file.name,
        document_data_url: event.target?.result
      })
      form.setValue('url', '')
    }

    reader.onerror = () => {
      alert('Could not read file')
    }

    reader.readAsDataURL(file)
  }

  const handleSubmit: SubmitHandler<CompanyUpdatesSchema> = values => {
    const data = {
      ...values,
      id: selectedLink?.id,
      ...document
    }

    let links = []

    // editing
    if (selectedLink) {
      links = opportunity.links.map(link => {
        if (link.id === selectedLink.id) return { ...selectedLink, ...data }
        return link
      })
    } else {
      // creating new
      const shiftedLinks = opportunity.links.map(link => ({
        ...link,
        order: link.order + 1
      }))
      links = [data, ...shiftedLinks]
    }

    mutate(
      { id: opportunity.id, links },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: opportunityKeyFactory.opportunityByDeal(`${opportunity.id}`)
          })
          toast({
            variant: 'success',
            description: `${selectedLink ? 'Edited' : 'Created'} company updates`
          })
          onClose()
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className='max-w-[624px]'>
        <DialogHeader>
          <DialogTitle className='text-2xl'>
            {selectedLink ? 'Edit' : 'New'} Company Updates
          </DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <div className='space-y-4'>
              <FormField
                control={form.control}
                name='resource_type'
                render={({ field }) => {
                  return (
                    <FormItem className='flex-1'>
                      <FormLabel>Type</FormLabel>
                      <FormControl>
                        <Select
                          defaultValue={field.value}
                          onValueChange={val => {
                            field.onChange(val)
                            // reset fields depending on the resource_type
                            if (['video', 'image'].includes(val)) {
                              form.resetField('url')
                            } else {
                              setDocument({
                                document_filename: selectedLink?.document_filename,
                                document_data_url: selectedLink?.dcoument_data_url
                              })
                            }
                          }}
                        >
                          <FormControl>
                            <SelectTrigger size='lg' className='border-[#BCCDE5] px-4'>
                              <SelectValue defaultValue={field.value} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {TYPE_OPTIONS.map(item => {
                              return (
                                <SelectItem key={item.value} value={item.value}>
                                  <span className='flex items-center space-x-3'>
                                    {DUE_DILIGENCE_ICONS[item.value]} <span>{item.label}</span>
                                  </span>
                                </SelectItem>
                              )
                            })}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
              <FormField
                control={form.control}
                name='label_html'
                render={({ field }) => {
                  return (
                    <FormItem className='flex-1'>
                      <FormLabel>Label</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />

              <FormField
                control={form.control}
                name='url'
                render={({ field }) => {
                  return (
                    <FormItem className='flex-1'>
                      <FormLabel>URL</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />

              <div>
                <p className='m-0 mb-4 text-sm text-center'>Or</p>
                <input type='file' className='hidden' onChange={handleFileChange} ref={fileInput} />
                <Button
                  variant='success'
                  type='button'
                  className='w-full bg-[#818487]'
                  onClick={handleFileClick}
                >
                  Upload file
                </Button>
              </div>

              <FormField
                control={form.control}
                name='mail_investors'
                render={({ field }) => {
                  return (
                    <FormItem className='flex-1 flex items-center space-y-0 space-x-2'>
                      <FormControl>
                        <Checkbox
                          checked={field.value}
                          className='w-5 h-5'
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel className='text-base'>Email investors</FormLabel>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>

            <DialogFooter className='mt-8 flex-row space-x-2 sm:justify-start'>
              <Button
                type='button'
                size='sm'
                disabled={isLoading}
                className='flex-1'
                onClick={() => {
                  if (isLoading) return
                  onClose()
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                loading={isLoading}
                size='sm'
                className='flex-1 bg-success'
              >
                Save
              </LoadingButton>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
