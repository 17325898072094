import { useState } from 'react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from 'components/ui/Select'
import { Skeleton } from 'components/ui/Skeleton'
import { useUpdateOpportunityMutate } from 'hooks/api/useMutation.hooks'
import { cn, dealStatus } from 'services/utils'
import { OpportunityResponse } from 'types/api-types'
import { useToast } from 'hooks/userToast.hooks'

interface Props {
  opportunity: OpportunityResponse
}

const statusOptions = [
  { value: 'active', label: 'Live' },
  { value: 'draft', label: 'Draft' },
  { value: 'archived', label: 'Archived' }
]
const statusColors: Record<string, string> = {
  active: 'bg-success border-success',
  draft: 'bg-warn border-warn',
  archived: 'bg-[#A1A1A1] border-[#A1A1A1]'
}

export function EditOpportunityStatus({ opportunity }: Props) {
  const { toast } = useToast()

  const [status, setStatus] = useState<string>(() => dealStatus(opportunity.status))

  const { mutate, isLoading } = useUpdateOpportunityMutate()

  const updateStatus = (val: string) => {
    mutate(
      { id: opportunity.id, status: val },
      {
        onSuccess: () => {
          setStatus(val)
          toast({
            variant: 'success',
            description: 'Opportunity status updated',
            duration: 2000
          })
        },
        onError: error => {
          toast({
            variant: 'destructive',
            description: error.message ?? 'Something went wrong',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <Select onValueChange={updateStatus} defaultValue={status}>
      {isLoading ? (
        <Skeleton className='w-[200px] h-10 bg-gray-300' />
      ) : (
        <SelectTrigger
          className={cn(
            'max-w-[200px] text-white [&>svg]:text-white',
            statusColors[status || opportunity.status]
          )}
        >
          <SelectValue placeholder='status' />
        </SelectTrigger>
      )}
      <SelectContent>
        <SelectGroup>
          {statusOptions.map(option => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
